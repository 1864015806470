import React from 'react'
import SplitPane from '../generic/SplitPane'
import GroupList from './GroupList'
import GroupEditor from './GroupEditor'

export default class GroupPane extends SplitPane {

    constructor(props) {
        super(props)
        this.renderList = this.renderList.bind(this)
        this.renderEdit = this.renderEdit.bind(this)
    }


    // SplitPane functions

    renderList() {
        return <GroupList onItemClick={ this.onItemClick } updateCount={ this.state.updateCount }/>
    }

    renderEdit() {
        return <GroupEditor onEditorChange={ this.onEditorChange } item={ this.state.editing } selectCount={ this.state.selectCount } />
    }
}
