exports.treatmentIsFlagged = function (treatment) {
    if (!treatment.program_data || !treatment.program_data.waveforms || (treatment.program_data.waveforms.length < 1))
        return "This treatment has no program data.  Upload a waveform file."
    if (treatment.program_data.number < 1 || treatment.program_data.number > 99)
        return "The program ID for the this program must be between 1 and 99."
    if (!treatment.videos || !treatment.videos.instructions)
        return "This treatment has no instructions video.  Select a video under 'instruction video'."

    return false
}
