import React, { Component } from 'react'
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Button from "react-bootstrap/Button"
import Alert from "react-bootstrap/Alert"
import './css/App.css'
import './css/Login.css'

import ZIIPLogo from './images/ziip-logo-black.svg'

export default class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {}
    this.handleSubmit = this.handleSubmit.bind(this)
    this.closeAlert = this.closeAlert.bind(this)
  }

  handleSubmit(e) {
    e.preventDefault()
    // Set the global values we'll need to complete a call if the credentials are valid
    global.config.username = document.getElementById('username').value;
    global.config.password = document.getElementById('password').value;
    // Try to fetch the treatment list to determine whether the given credentials actually work
    global.api.fetchTreatments().then(response => {
      // Save credentials locally so we stay logged in
      localStorage.setItem('username', global.config.username)
      localStorage.setItem('password', global.config.password)
      this.props.rerender();
    }).catch(error => {
      // Remove invalid credentials
      global.config.username = ''
      global.config.password = ''
      if (error.response) {
        console.log('error: ' + JSON.stringify(error))
        if (error.response.status === 401) {
          this.setState({ error: "Invalid username or password." });
        } else {
          this.setState({ error: error.message })
        }
      } else {
        this.setState({ error: "An unknown error occurred." })
      }
    })
  }

  closeAlert() {
    this.setState({ error: undefined })
  }

  render() {
    const error = this.state.error
        ? <Alert variant="danger" dismissible={true} onClose={this.closeAlert}>{ this.state.error }</Alert>
        : null
    return (
        <div className="login">
          <Row>
            <Col className="logoContainer">
              <img className="logo" src={ZIIPLogo} alt="ZIIP Logo" />
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="loginText">
                Welcome to the ZIIP Dashboard.  Please log in.
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={{ span: 4, offset: 4}}>
              <div className="loginBox">
                <form>
                  <Row>
                    <Col md={4} className="label">Username:</Col>
                    <Col md={8} className="value"><input id="username" className="loginInput" type="text" /></Col>
                  </Row>
                  <Row>
                    <Col md={4} className="label">Password:</Col>
                    <Col md={8} className="value"><input id="password" className="loginInput" type="password" /></Col>
                  </Row>
                  <Row>
                    <Col md={{ span: 12 }} className="loginSubmitContainer">
                      <Button variant="primary" id="submitButton" onClick={this.handleSubmit}>Login</Button>
                    </Col>
                  </Row>
                </form>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={{ span: 4, offset: 4 }}>
              { error }
            </Col>
          </Row>
        </div>
    );
  }
}
