import React from 'react'
import SplitList from '../generic/SplitList'

export default class UserList extends SplitList {

    constructor(props) {
        super(props)
        this.setState({ sort: 'last_name', searchFirstName: '', searchLastName: '', searchEmail: '' })

        this.updateSearch = this.updateSearch.bind(this)
        this.onKeyPress = this.onKeyPress.bind(this)
        this.renderSearchButton = this.renderSearchButton.bind(this)
    }


    // SplitList functions

    getItems() {
        if (!this.state.searchFirstName && !this.state.searchLastName && !this.state.searchEmail) {
            this.setState({ items: [] })
            return null
        }
        const search = {
            email: this.state.searchEmail,
            lastName: this.state.searchLastName,
            firstName: this.state.searchFirstName
        }
        return global.api.searchUsers(search)
    }

    itemId(item) {
        return item.user_id
    }

    itemSort(item) {
        if (this.state.sort === 'email') {
            return item.email
        } else {
            return item.last_name + item.first_name
        }
    }


    // Internal functions

    updateSearch(field, value) {
        let s = this.state
        s[field] = value
        this.setState(s)
    }

    onKeyPress(event) {
        if (event.key === 'Enter') {
            this.fetchItems()
        }
    }

    // Render functions

    renderSearchButton() {
        if (this.state.searchFirstName || this.state.searchLastName || this.state.searchEmail) {
            return (
                <button onClick={ (e)=>this.fetchItems() } className="btn btn-sm btn-primary searchButton">
                    <i className="mdi mdi-search"/> Search
                </button>
            )
        }
    }

    renderHeader() {
        return [
            <tr><th style={{"width":"100px"}} /><th /></tr>,
            <tr>
                <td style={{paddingLeft: '30px'}} className="inputLabel">first </td>
                <td>
                    <input type="text" style={{width:"170px"}} className="form-control form-control-sm"
                           value={ this.state.searchFirstName } spellCheck="false" autoComplete="new-password"
                           onChange={(e)=>this.updateSearch('searchFirstName', e.target.value)}
                           onKeyDown={ this.onKeyPress }
                    />
                </td>
            </tr>,
            <tr>
                <td style={{paddingLeft: '30px'}} className="inputLabel">last </td>
                <td>
                    <input type="text" style={{width:"170px"}} className="form-control form-control-sm"
                           value={ this.state.searchLastName } spellCheck="false" autoComplete="new-password"
                           onChange={(e)=>this.updateSearch('searchLastName', e.target.value)}
                           onKeyDown={ this.onKeyPress }
                    />
                </td>
            </tr>,
            <tr>
                <td style={{paddingLeft: '30px'}} className="inputLabel">email </td>
                <td>
                    <input type="text" style={{width:"170px"}} className="form-control form-control-sm"
                           value={ this.state.searchEmail } spellCheck="false" autoComplete="new-password"
                           onChange={(e)=>this.updateSearch('searchEmail', e.target.value)}
                           onKeyDown={ this.onKeyPress }
                    />
                </td>
            </tr>,
            <tr>
                <td />
                <td>
                    { this.renderSearchButton() }
                </td>
            </tr>,
            <tr className="listHeaderRow">
                <td style={{paddingLeft: '30px'}} className="listHeader">results</td>
                <td />
            </tr>
        ]
    }

    renderItem(item) {
        return (
            <td colSpan={2} style={{paddingLeft: '30px'}} key={ item.user_id } className="userList">
                <span className="userListName">{ item.last_name }, { item.first_name }</span>
                <br />
                <span className="userListEmail">&nbsp;&nbsp;&nbsp; { item.email }</span>
            </td>
        )
    }

    renderAddButton() { }
}
