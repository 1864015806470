exports.treatplanIsFlagged = function (treatplan) {
    if (treatplan.days.length < 1) {
        return "This treatment plan has no days.  Add a day with some treatments."
    }
    if (treatplan.image_url === "") {
        return "This treatment plan has no image.  Upload an image for the plan."
    }
    if (treatplan.summary === "") {
        return "This treatment plan has no summary.  Enter a short text summary."
    }
    if (treatplan.description === "") {
        return "This treatment plan has no description.  Enter a text description of the plan."
    }
}
