import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import Api from './Api'
import LocaleManager from './LocaleManager'

const env = process.env.REACT_APP_ENVIRONMENT
const config = {
    env,
    ...require("./config/config.json"),
    ...require("./config/config." + env + ".json")
}

global.config = config
global.languages = require('./data/languages.json')
global.countries = require('./data/countries.json')
global.api = new Api()
global.localeManager = new LocaleManager()
global.Templates = {
    "group": require('./templates/group.json'),
    "plangroup": require('./templates/plangroup.json'),
    "locale": require('./templates/locale.json'),
    "string": require('./templates/string.json'),
    "treatment": require('./templates/treatment.json'),
    "treatplan": require('./templates/treatplan.json'),
    "user": require('./templates/user.json'),
    "order": require('./templates/order.json'),
    "filter": require('./templates/filter.json'),
    "event": require('./templates/event.json'),
    "rawevent": require('./templates/rawevent.json'),
    "video": require('./templates/video.json'),
    "firmware": require('./templates/firmware.json')
}

ReactDOM.render(
    <App/>,
    document.getElementById('root')
)
