import React from 'react'
import SplitList from '../generic/SplitList'
import {numID} from '../common'
import {videoIsFlagged} from './VideoIsFlagged'

export default class VideoList extends SplitList {

    constructor(props) {
        super(props)
        this.state = {...this.state, sort: 'id' }
        this.renderIDHeader = this.renderIDHeader.bind(this)
        this.renderNameHeader = this.renderNameHeader.bind(this)
        this.renderStatusHeader = this.renderStatusHeader.bind(this)
    }


    // SplitList functions

    getItems() {
        return global.api.fetchVideos()
    }

    itemId(item) {
        return item.video_id
    }

    itemSort(item) {
        if (this.state.sort === 'name') {
            return item.name || ''
        } else if (this.state.sort === 'status') {
            if (item.status === 'ingesting') return 1
            else if (item.status === 'processing') return 2
            else if (item.status === 'deploying') return 3
            else if (item.status === 'live') return 4
            else return 0
        } else {
            return parseInt(numID(item.video_id))
        }
    }

    itemIsFlagged(video) {
        return videoIsFlagged(video)
    }

    renderHeader() {
        return [
            <tr key="head1"><th style={{"width":"12px"}} /><th style={{"width":"60px"}} /><th /><th style={{"width":"60px"}} /></tr>,
            <tr className="listHeaderRow" key="head2">
                <td />
                <td className="listHeader" onClick={ (e)=>this.setState({ sort: 'id' })} style={{ 'paddingLeft': '30px' }}>{ this.renderIDHeader() }</td>
                <td className="listHeader" onClick={ (e)=>this.setState({ sort: 'name' })}>{ this.renderNameHeader() }</td>
                <td className="listHeader" onClick={ (e)=>this.setState({ sort: 'status' })}>{ this.renderStatusHeader() }</td>
            </tr>
        ]
    }

    renderIDHeader() {
        if (this.state.sort === 'id') {
            return [ "id", <i className="mdi mdi-keyboard-arrow-down sortArrow" key="head" /> ]
        } else {
            return "id"
        }
    }

    renderNameHeader() {
        if (this.state.sort === 'name') {
            return [ "name", <i className="mdi mdi-keyboard-arrow-down sortArrow" key="name" /> ]
        } else {
            return "name"
        }
    }

    renderStatusHeader() {
        if (this.state.sort === 'status') {
            return [ "status", <i className="mdi mdi-keyboard-arrow-down sortArrow" key="status" /> ]
        } else {
            return "status"
        }
    }

    renderStatusIcon(status) {
        if (status === 'ingesting') {
            return <i className="mdi mdi-forward statusIcon statusIconYellow" key="statusIcon1" />
        } else if (status === 'processing') {
            return [ <i className="mdi mdi-forward statusIcon statusIconYellow" key="statusIcon1" />, <i className="mdi mdi-forward statusIcon statusIconYellow" key="statusIcon2" /> ]
        } else if (status === 'deploying') {
            return [ <i className="mdi mdi-forward statusIcon statusIconYellow" key="statusIcon1" />, <i className="mdi mdi-forward statusIcon statusIconYellow" key="statusIcon2" />, <i className="mdi mdi-forward statusIcon statusIconYellow" key="statusIcon3" /> ]
        } else if (status === 'live') {
            return <i className="mdi mdi-check-circle statusIcon statusIconGreen" key="statusIcon1" />
        } else if (status === 'failed') {
            return <i className="mdi mdi-error statusIcon statusIconRed" key="statusIcon1" />
        } else {
            return <span key="statusIcon1">?</span>
        }
    }

    renderItem(item) {
        return [
            <td key={'f'+item.video_id}>{ this.renderFlag(item) }</td>,
            <td key={item.video_id} className="userData" style={{ 'paddingLeft': '30px'}}><b>{ numID(item.video_id) }</b></td>,
            <td key={item.name+item.video_id} className="userData">{item.name} </td>,
            <td key={item.status+item.video_id}>{ this.renderStatusIcon(item.status) }</td>
        ]
    }

    labelAddButton() {
        return "Add video"
    }

}
