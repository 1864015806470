import React from 'react'
import '../css/App.css'
import {Row, Col, FormCheck, Button} from 'react-bootstrap'
import Popover from 'react-bootstrap/Popover'
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import SplitEditor from '../generic/SplitEditor'

export default class LocaleEditor extends SplitEditor {

    constructor(props) {
        super(props)
        this.state = { ...this.state, newAlias: "", allLocales: [], reference: '' }
        this.getItemFromStore = this.getItemFromStore.bind(this)
        this.deleteItemFromStore = this.deleteItemFromStore.bind(this)
        this.onLoadItem = this.onLoadItem.bind(this)
        this.renderEditor = this.renderEditor.bind(this)
        this.itemNotOKToSave = this.itemNotOKToSave.bind(this)
        this.createNewItemInStore = this.createNewItemInStore.bind(this)
        this.updateItemInStore = this.updateItemInStore.bind(this)

        this.renderAliases = this.renderAliases.bind(this)
        this.addAlias = this.addAlias.bind(this)
        this.changeNewAlias = this.changeNewAlias.bind(this)
        this.changeLocale = this.changeLocale.bind(this)
        this.changeName = this.changeName.bind(this)
        this.renderLocaleField = this.renderLocaleField.bind(this)
        this.renderAddAliasButton = this.renderAddAliasButton.bind(this)
        this.removeAlias = this.removeAlias.bind(this)
        this.onNewAliasKeypress = this.onNewAliasKeypress.bind(this)
        this.renderDefaultSwitch = this.renderDefaultSwitch.bind(this)
        this.tryToSetDefault = this.tryToSetDefault.bind(this)
        this.fetchAllLocales = this.fetchAllLocales.bind(this)
        this.getLanguageName = this.getLanguageName.bind(this)
        this.getLangFromLocale = this.getLangFromLocale.bind(this)
        this.getCountryName = this.getCountryName.bind(this)
        this.getCountryFromLocale = this.getCountryFromLocale.bind(this)
        this.renderLocaleFieldLabel = this.renderLocaleFieldLabel.bind(this)
        this.renderLocaleExplanation = this.renderLocaleExplanation.bind(this)
    }

    componentDidMount() {
        super.componentDidMount()
        this.fetchAllLocales()
    }


    // SplitEditor functions

    getBlankItem() {
        this.fetchAllLocales()
        return JSON.parse(JSON.stringify(global.Templates.locale))
    }

    getItemFromStore(id) {
        this.fetchAllLocales()
        return global.api.fetchLocale(id)
    }

    deleteItemFromStore(id) {
        return global.api.deleteLocale(id)
    }

    getNameFromItem(item) {
        return item.name + " (" + item.locale + ")"
    }

    getIDFromItem(item) {
        return item.locale
    }

    onLoadItem(item) {
        this.setState({ newAlias: "" })
    }

    homeIcon() {
        return <i className="mdi mdi-local-airport homeIcon" />
    }

    homeText() {
        return "Pick a locale to edit, or Add Locale to create a new one."
    }

    homeSubtext() {
        return "Create locales to control which text users will see in different language settings.  Add aliases to a locale to provide the same text to other language codes."
    }

    itemsAreEqual(a, b) {
        if (!a && !b) return true
        if (!a || !b) return false
        if (a.locale === b.locale) {
            if (a.name === b.name) {
                if (a.isDefault === b.isDefault) {
                    if (!a.aliases && !b.aliases) return true
                    if (!a.aliases || !b.aliases) return false
                    a.aliases.forEach((alias) => {
                        if (!b.aliases.includes(alias)) return false
                    })
                    if (b.aliases.length !== a.aliases.length) return false
                    return true
                }
            }
        }
        return false
    }

    noErrors(errorList) {
        return true
    }

    itemNotOKToSave(locale) {
        const localeId = locale.locale
        if (!this.aliasIsAcceptable(localeId)) {
            return 'Enter a valid locale ID (example: en_US).'
        }
        if (!locale.name) {
            return 'Enter a descriptive name (example: UK English).'
        }
        const language = this.getLangFromLocale(localeId)
        const languageName = language ? this.getLanguageName(language) : null
        if (!languageName) {
            return "'" + language + "' is not a valid language code.  Please enter a locale ID with a valid language code."
        }
        const country = this.getCountryFromLocale(localeId)
        const countryName = country ? this.getCountryName(country) : null
        if (!countryName) {
            return "'" + country + "' is not a valid country code.  Please enter a locale ID with a valid country code."
        }
        return false
    }

    itemNotOKToDelete(locale) {
        if (locale.locale === global.config.defaultLocale) return "This is the default locale."
        return false
    }

    createNewItemInStore(locale) {
        global.api.addLocale(locale).then(response => {
            this.saveSucceeded("New locale created.")
        }).catch(e => {
            this.saveFailed("Error creating locale: " + e.response.text)
        })
    }

    updateItemInStore(locale) {
        global.api.updateLocale(locale).then(response => {
            this.saveSucceeded("Changes saved.")
        }).catch(e => {
            this.saveFailed("Error saving locale: " + e.response.text)
        })
    }


    // Internal functions

    fetchAllLocales() {
        global.api.fetchLocales().then((response) => {
            const allLocales = response.body
            this.setState({ allLocales: allLocales })
        }).catch((e) => {
            this.showAlert("danger", "Error fetching locale list!")
        })
    }

    changeNewAlias(event) {
        this.setState({ newAlias: event.target.value, alertType: "", alertText: "" })
    }
    changeLocale(event) { if (this.state.isNew) this.updateItem('locale', event.target.value) }
    changeName(event) { this.updateItem('name', event.target.value) }

    addAlias() {
        let newAlias = this.state.newAlias
        if (this.state.item.aliases.includes(newAlias)) {
            this.showAlert("warning", newAlias + " is already an alias ID for " + this.state.item.locale + ".")
        } else if (!this.aliasIsAcceptable(newAlias)) {
            this.showAlert("warning", newAlias + " is not of the form xx_YY.  Enter a valid locale code.")
        } else {
            let locale = this.state.item
            locale.aliases.push(newAlias)
            this.setState({ item: locale, newAlias: "" })
        }
    }

    aliasIsAcceptable(alias) {
        if (alias.length !== 5) {
            return false
        } else if (alias.charAt(2) !== '_') {
            return false
        } else if (alias.search(' ') >= 0) {
            return false
        }
        return true
    }

    removeAlias(alias) {
        let i = this.state.item.aliases.indexOf(alias)
        if (i >= 0) {
            let locale = this.state.item
            locale.aliases.splice(i, 1)
            this.setState({ item: locale, alertType: "", alertText: "" })
        }
    }

    onNewAliasKeypress(event) {
        if (event.key === 'Enter') {
            this.addAlias()
        }
    }

    tryToSetDefault(locale, event) {
        const value = event.target.checked
        let alreadyHasDefault = false
        if (value === true) {
            const myLang = this.getLangFromLocale(locale.locale)
            const myLangName = myLang + " (" + this.getLanguageName(myLang) + ")"
            // Check whether we already have a default for this language
            this.state.allLocales.forEach((l) => {
                if (l.isDefault && (l.locale !== locale.locale)) {
                    if (this.getLangFromLocale(l.locale) === myLang) {
                        this.updateItem('isDefault', false)
                        this.showAlert("warning", l.locale + " (" + l.name + ") is already a default locale for language " + myLangName + ".  Un-set this setting for that locale before you make this one the default.")
                        alreadyHasDefault = true
                        event.stopPropagation()
                        event.preventDefault()
                    }
                }
            })
        }
        alreadyHasDefault || this.updateItem('isDefault', value)
    }

    getLangFromLocale(localeId) {
        const localePattern = /^(\w\w)/
        const match = localePattern.exec(localeId)
        if (match) return match[1]
        return null
    }

    getLanguageName(code) {
        if (code === null) return null
        const match = global.languages.find(l => l.alpha2.toUpperCase() === code.toUpperCase())
        return match ? match.English : null
    }

    getCountryFromLocale(localeId) {
        const localePattern = /^(\w\w)_(\w\w)/
        const match = localePattern.exec(localeId)
        if (match) return match[2]
        return null
    }

    getCountryName(code) {
        if (code === null) return null
        const match = global.countries.find(c => c.Code.toUpperCase() === code.toUpperCase())
        return match ? match.Name : null
    }

    onClickReference(type) {
        if (this.state.reference === type) {
            this.setState({ reference: '' })
        } else {
            this.setState({reference: type})
        }
    }


    // Render functions

    renderAliases(aliases) {
        return aliases.map((alias) =>
            <li key={alias} className="userInput" >
                {alias}
                <i className="mdi mdi-cancel glyphIcon removeIcon" onClick={ () => this.removeAlias(alias) } />
                <span className="inputLabel">&nbsp;&nbsp;&nbsp;&nbsp;{ this.renderLocaleExplanation(alias) }</span>
            </li>
        )
    }

    renderAddAliasButton() {
        if (this.state.newAlias) {
            return <i className="mdi mdi-add-box glyphIcon addIcon" style={{"margin-left":"0px"}} onClick={ this.addAlias }/>
        }
    }

    renderLocaleFieldLabel() {
        if (this.state.isNew) {
            return "primary ID"
        }
    }

    renderLocaleField(value) {
        if (this.state.isNew) {
            return (
                <input type="text" className="form-control form-control-sm" style={{ width: "150px" }}
                          placeholder='??_??' value={value} onChange={ this.changeLocale }/>
            )
        } else {
            return (
                <span className="itemTitle">{ value }</span>
            )
        }
    }

    renderLocaleExplanation(id) {
        const langId = this.getLangFromLocale(id)
        const countryId = this.getCountryFromLocale(id)
        if (langId === null && countryId === null) return null
        let e = ""
        if (langId) {
            const language = this.getLanguageName(langId)
            e = language ? language : "(?? unknown language)"
        } else {
            e = "(??)"
        }
        e = e + " in "
        if (countryId) {
            const country = this.getCountryName(countryId)
            e = e + (country ? country : "(?? unknown country)")
        } else {
            e = e + "(??)"
        }
        return e
    }

    renderDefaultSwitch(locale) {
        if (!locale.locale || (locale.locale.length < 2)) return null
        const lang = locale.locale.substring(0,2)
        return (
            <FormCheck type="switch" label={ this.renderDefaultLabel(locale, lang) } checked={ locale.isDefault } id='isDefault'
                       onChange={ e=>this.tryToSetDefault(locale, e)} />
        )
    }

    renderDefaultLabel(locale, lang) {
        if (locale.isDefault) {
            return <span className="switchOnLabel">Default locale for language '{lang}'</span>
        } else {
            return <span className="switchOffLabel">Make this the default locale for language '{lang}'</span>
        }
    }

    renderReference() {
        if (this.state.reference === 'languages') {
            return global.languages.map((l) =>
                <tr className="referenceList">
                    <td><b>{l.alpha2}</b></td>
                    <td>{l.English}</td>
                </tr>
            )
        } else if (this.state.reference === 'countries') {
            return global.countries.map((c) =>
                <tr className="referenceList">
                    <td><b>{c.Code}</b></td>
                    <td>{c.Name}</td>
                </tr>
            )
        }
    }

    referenceButtonType(type) {
        return (type === this.state.reference) ? "info" : "outline-info"
    }

    renderEditor(locale) {

        const popoverDefault = (
            <Popover id="defaultHelp" className="helpPopover" >
                <Popover.Content>
                    Setting this locale as a Default Locale will apply it to any user whose locale isn't exactly matched by the ID or alias of any other locale, if their language matches this locale's language.

                    Example: if you set 'zh_CN' as a Default Locale, anyone with a locale like 'zh_XX' will get the zh_CN language strings, if no other locale matches zh_XX.
                </Popover.Content>
            </Popover>
        )

        return (
            <Row>
            <Col className="col-9">
                <table cellPadding={8} style={{"marginTop":"24px", "tableLayout":"fixed"}} className="col-12"><tbody>
                <tr><th style={{"width":"150px"}} /><th /></tr>
                    <tr>
                        <td className="inputLabel">{ this.renderLocaleFieldLabel() }</td>
                        <td>
                            { this.renderLocaleField(locale.locale) }
                        </td>
                    </tr>
                    <tr>
                        <td/>
                        <td className="inputLabel">{ this.renderLocaleExplanation(locale.locale) }</td>
                    </tr>

                    <tr>
                        <td className="inputLabel">name</td>
                        <td colSpan={2}><input type="text"  autoFocus style={{ width: "400px" }}
                                               placeholder='A descriptive title.'
                                               className="userInput form-control form-control-sm"
                                               value={locale.name} onChange={ this.changeName }/></td>
                    </tr>

                    <tr>
                        <td className="inputLabel">aliases</td>
                        <td><ul style={{ "listStyleType": "none", padding: "0px", width: "500px" }}>
                            {!locale.aliases || this.renderAliases(locale.aliases)}
                            <li>
                                <Row style={{"marginTop": "12px"}}>
                                    <Col className="col-4">
                                        <input type='text' className="userInput form-control form-control-sm"
                                               value={ this.state.newAlias } style={{width: "150px"}}
                                               placeholder='??_??'
                                               onChange={ this.changeNewAlias }
                                               onKeyDown={ this.onNewAliasKeypress }
                                        />
                                    </Col>
                                    <Col className="col-1 align-content-start" style={{textAlign:"start"}}>{ this.renderAddAliasButton() }</Col>
                                </Row>
                                <Row style={{"marginTop": "0px"}}>
                                    <Col className="col-7 inputLabel" style={{textAlign:"start"}}>{ this.renderLocaleExplanation(this.state.newAlias) }</Col>
                                </Row>
                            </li></ul></td>
                    </tr>

                    <tr>
                        <td />
                        <td>
                            { this.renderDefaultSwitch(locale) }
                        </td>
                        <td>
                            <OverlayTrigger trigger="click" placement="bottom" overlay={popoverDefault}>
                                <i className="mdi mdi-help glyphIcon" />
                            </OverlayTrigger>
                        </td>
                    </tr>

                    <tr><td /><td><hr/></td></tr>
                    { this.renderButtons() }
                    </tbody>
                </table>
            </Col>
            <Col className="col-3 referencePane">
                <table style={{marginTop: "50px"}}>
                    <tr><td>
                        <Button variant={ this.referenceButtonType('languages') } className="btn-sm" onClick={ ()=>this.onClickReference('languages')}>languages</Button>
                    </td><td>
                        <Button variant={ this.referenceButtonType('countries') } className="btn-sm" onClick={ ()=>this.onClickReference('countries')}>countries</Button>
                    </td></tr>
                </table>
                <div className="referenceTable">
                    <table style={{margin: "10px"}}>
                    { this.renderReference() }
                    </table>
                </div>
            </Col>
            </Row>
        )
    }
}
