import React, { Component } from 'react'
import { Button } from 'react-bootstrap'
import FileSaver from 'file-saver'
import './css/App.css'

export default class ProgramPicker extends Component {

  constructor(props) {
    super(props)
    this.program = []
    this.state = { program: this.fixProgram(this.props.program) }

    this.updateSummary = this.updateSummary.bind(this)
    this.updateProgramNumber = this.updateProgramNumber.bind(this)
    this.handleFileChange = this.handleFileChange.bind(this)
    this.parseFile = this.parseFile.bind(this)
    this.saveProgram = this.saveProgram.bind(this)
  }

  getProgram() {
    return this.state.program
  }

  fixProgram(p) {
    let fp = p
    if (!fp) fp = { waveforms: [] }
    if (!fp.waveforms) { fp.waveforms = [] }
    return fp
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.program !== prevProps.program) {
      this.setState({ program: this.fixProgram(this.props.program) })
    }
  }

  handleFileChange(event) {
    const reader = new FileReader()
    const file = event.target.files[0];
    reader.onload = (loadEvent) => {
      this.parseFile(loadEvent.target.result)
    }
    reader.readAsText(file)
  }
 
  parseFile(contents) {
    // Remove the carriage returns
    contents = contents.replace(/\r/g, '')
    // Split on the remaining linefeeds
    var lines = contents.split('\n')
    // Valid treatment data files should have 34 lines.
    if (lines.length !== 34) {
      alert('Invalid data file.  Please try again.');
      return;
    }
    var program = this.state.program;
    // The first line will be the treatment summary
    program.summary = lines.splice(0, 1)[0];
    program.waveforms = [];
    // Remaining lines will be waveform data.
    for (var x=0; x<32; x++) {
      // When we see a line of all zeros we can stop adding data
      if (lines[x].match(/^Waveform\d+(\s+00){16}/)) { break }
      // Make sure the line we're looking at is valid
      if (lines[x].match(/^Waveform\d+(\s+\d\d){16}$/)) {
        program.waveforms.push(lines[x].replace(/^Waveform\d+\s+/, ''))
      } else {
        alert('Invalid line in data file: ' + lines[x])
        return
      }
    }
    this.setState({ program: program })
    this.props.host.onProgramLoaded()
  }

  saveProgram() { 
    var program = this.state.program
    var output = program.summary + "\r\n"
    for (var x=0; x<32; x++) {
      output += "Waveform" + (x<10 ? "0" : "") + x + " "
      if (x < program.waveforms.length) {
        output += program.waveforms[x] + "\r\n"
      } else {
        output += "00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00\r\n"
      }
    }
    var blob = new Blob([output], {type: "text/plain;charset=utf-8"})
    FileSaver.saveAs(blob, "treatment" + program.number + ".dat")
  }

  updateProgramNumber(event) {
    let program = this.state.program
    var intValue = parseInt(event.target.value, 10)
    if (isNaN(intValue)) intValue = 0
    program.number = intValue
    this.setState({ program: program }, () => { this.props.host.updateItem("program_data", program) })
  }

  updateSummary(event) {
    var program = this.state.program
    program.summary = event.target.value
    this.setState({ program: program })
  }

  mapWaveforms(waveforms) {
    return waveforms.map((waveform, index) =>
      <div className="programWaveform" key={index}>{ waveform }</div>
    )
  }

  render() {
    let p = this.state.program
    return (
        <div className="programDataTableDiv">
          <table className="programDataTable">
            <tbody>
            <tr><td className="inputLabel">summary</td><td><input className="form-control form-control-sm" onChange={ this.updateSummary } type="text" value={this.state.program.summary} /></td></tr>
            <tr><td className="inputLabel">program id</td><td><input className="form-control form-control-sm" onChange={ this.updateProgramNumber } type="text" value={this.state.program.number} /></td></tr>
            <tr><td/><td className="helpText">Set the program ID to zero to assign the treatment ID as the program ID.</td></tr>
            <tr><td className="inputLabel">waveforms</td><td>
              <input type="file" onChange={ this.handleFileChange } /> <Button className="btn btn-sm" onClick={ this.saveProgram }>Export program</Button>
              <span className="programWaveforms">
              { this.mapWaveforms(p.waveforms) }
              </span>
            </td></tr>
            </tbody>
          </table>
        </div>
    )
  }

}
