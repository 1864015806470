import React from 'react'
import SplitList from '../generic/SplitList'
import { numID } from '../common'
import {treatmentIsFlagged} from './TreatmentIsFlagged'

export default class TreatmentList extends SplitList {

    constructor(props) {
        super(props)
        this.state = { ...this.state, sort: 'treatment_id' }
        this.getItems = this.getItems.bind(this)
    }


    // SplitList functions

    getItems() {
        return global.api.fetchTreatmentsDefaultLocale()
    }

    itemId(item) {
        return item.treatment_id
    }

    itemSort(item) {
        if (this.state.sort === 'name') {
            return item.name
        } else {
            return parseInt(numID(item.treatment_id))
        }
    }

    itemIsFlagged(treatment) {
        return treatmentIsFlagged(treatment)
    }


    // Render functions

    renderHeader() {
        return [
            <tr key={0}><th style={{"width":"12px"}}/><th style={{"width":"60px"}} /><th /></tr>,
            <tr className="listHeaderRow" key={1}>
                <td />
                <td className="listHeader" onClick={ (e)=>this.setState({ sort: 'treatment_id' })} style={{ 'paddingLeft': '30px' }}>{ this.renderIDHeader() }</td>
                <td className="listHeader" onClick={ (e)=>this.setState({ sort: 'name' })}>{ this.renderNameHeader() }</td>
            </tr>
        ]
    }

    renderIDHeader() {
        if (this.state.sort === 'name') {
            return "id"
        } else {
            return [ "id", <i key="id" className="mdi mdi-keyboard-arrow-down sortArrow" /> ]
        }
    }

    renderNameHeader() {
        if (this.state.sort === 'name') {
            return [ "name", <i key="name" className="mdi mdi-keyboard-arrow-down sortArrow" /> ]
        } else {
            return "name"
        }
    }

    renderItem(item) {
        let idNum = numID(item.treatment_id)
        return [
            <td key={'f'+idNum}>{ this.renderFlag(item) }</td>,
            <td key={ idNum } style={{ 'paddingLeft': '30px', 'width': '70px' }}>
                <b>{ idNum }</b>
            </td>,
            <td key={ `${idNum}-${item.name}` }>
                { item.name }
            </td>
        ]
    }

    labelAddButton() {
        return "Add treatment"
    }
}
