import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'

export default class SplitPane extends Component {

    constructor(props) {
        super(props)

        let editingItem = ""
        if (props.editingItem) editingItem = props.editingItem
        this.state = { editing: editingItem, updateCount: 0, selectCount: 0 }

        this.onItemClick = this.onItemClick.bind(this)
        this.onEditorChange = this.onEditorChange.bind(this)

    }

    onItemClick(item) {
        this.setState({ editing: item, selectCount: this.state.selectCount + 1 })
    }

    onEditorChange() {
        this.setState({ updateCount: this.state.updateCount + 1 })
    }

    render() {
        return (
            <Row className="splitRow min-vh-100">
                <Col style={{ margin: 0, padding: 0}} className="col-3 selectionBar">
                    { this.renderList() }
                </Col>
                <Col style={{ margin: 0, padding: 0}} className="col-9 editorPane">
                    { this.renderEdit() }
                </Col>
            </Row>
        )
    }
}
