import React from 'react'
import { numID } from '../common'
import { treatplanIsFlagged } from './TreatplanIsFlagged'
import SplitList from '../generic/SplitList'

export default class TreatplanList extends SplitList {

    // SplitList functions

    getItems() {
        return global.api.fetchTreatplans()
    }

    deviceList(plan) {
        console.log(`plan => ${JSON.stringify(plan)}`)
        return plan.supported_devices?.join(" ") || ""
    }

    itemId(item) {
        return item.treatplan_id
    }

    itemSort(item) {
        if (this.state.sort === 'name') {
            return item.name
        } else {
            return parseInt(numID(item.treatplan_id))
        }
    }

    itemIsFlagged(treatplan) {
        return treatplanIsFlagged(treatplan)
    }

    renderHeader() {
        return [
            <tr key={0}><th style={{"width":"12px"}}/><th style={{"width":"60px"}} /><th /></tr>,
            <tr className="listHeaderRow" key={1}>
                <td />
                <td className="listHeader" onClick={ (e)=>this.setState({ sort: 'treatplan_id' })} style={{ 'paddingLeft': '30px' }}>{ this.renderIDHeader() }</td>
                <td className="listHeader" onClick={ (e)=>this.setState({ sort: 'name' })}>{ this.renderNameHeader() }</td>
            </tr>
        ]
    }

    renderIDHeader() {
        if (this.state.sort === 'name') {
            return "id"
        } else {
            return ["id", <i key={"id0"} className="mdi mdi-keyboard-arrow-down sortArrow"/>]
        }
    }

    renderNameHeader() {
        if (this.state.sort === 'name') {
            return ["name", <i key={"name0"} className="mdi mdi-keyboard-arrow-down sortArrow"/>]
        } else {
            return "name"
        }
    }

    renderItem(item) {
        let idNum = numID(item.treatplan_id)
        return [
            <td key={'f'+idNum}>{ this.renderFlag(item) }</td>,
            <td key={ idNum } style={{ 'paddingLeft': '30px', 'width': '70px' }}>
                <b>{ idNum }</b>
            </td>,
            <td key={ `${idNum}-${item.name}` }>
                { item.name } [{ this.deviceList(item) }]
            </td>
        ]
    }

    labelAddButton() {
        return "Add treatment plan"
    }
}
