import React from 'react'
import '../css/App.css'
import '../css/User.css'
import { FormCheck, Row, Col, Dropdown, DropdownButton, Button } from 'react-bootstrap'
import Popover from 'react-bootstrap/Popover'
import SplitEditor from '../generic/SplitEditor'
import uuid from 'uuid/v4'
import dateFormat from "dateformat"
import GroupOrTreatmentList from "../GroupOrTreatmentList"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import { numID, UserTags, TreatmentTiers, PromoPeriods } from '../common'
import GroupOrPlanList from '../GroupOrPlanList'

export default class UserEditor extends SplitEditor {

    constructor(props) {
        super(props)
        this.treatmentList = null
        this.planList = null
        this.setState({
            groups: [],
            planGroups: [],
            treatments: [],
            plans: [],
            userTreatments: [],
            userPlans: [],
            selectedPromo: ""
        })

        this.onListItemsChanged = this.onListItemsChanged.bind(this)
        this.onPlanListItemsChanged = this.onPlanListItemsChanged.bind(this)
        this.updatePhoneImage = this.updatePhoneImage.bind(this)
        this.updateTabletImage = this.updateTabletImage.bind(this)
        this.getItemsForList = this.getItemsForList.bind(this)
        this.getItemsForPlanList = this.getItemsForPlanList.bind(this)
        this.fetchUserTreatments = this.fetchUserTreatments.bind(this)
        this.fetchUserPlans = this.fetchUserPlans.bind(this)
        this.groupSelected = this.groupSelected.bind(this)
        this.planGroupSelected = this.planGroupSelected.bind(this)
        this.treatmentSelected = this.treatmentSelected.bind(this)
        this.planSelected = this.planSelected.bind(this)
        this.renderUserTreatments = this.renderUserTreatments.bind(this)
        this.renderUserPlans = this.renderUserPlans.bind(this)
        this.renderTags = this.renderTags.bind(this)
        this.groupTreatmentIsFrom = this.groupTreatmentIsFrom.bind(this)
        this.groupPlanIsFrom = this.groupPlanIsFrom.bind(this)
        this.tagSelected = this.tagSelected.bind(this)
        this.removeTag = this.removeTag.bind(this)
        this.renderSubscription = this.renderSubscription.bind(this)
    }

    componentDidMount() {
        super.componentDidMount()
        this.setState({ loading: true })
        const promises = [
            global.api.fetchGroups(),
            global.api.fetchTreatments(),
            global.api.fetchPlanGroups(),
            global.api.fetchTreatplans()
        ]
        Promise.all(promises).then( results => {
            this.setState({
                loading: false ,
                groups: results[0].body,
                treatments: results[1].body,
                planGroups: results[2].body,
                plans: results[3].body
            })
        }).catch( error => {
            this.setState({ loading: false })
            this.showAlert("danger", "FATAL: error fetching data: " + error.message)
        })
    }


    // SplitEditor functions

    getBlankItem() {
        return JSON.parse(JSON.stringify(global.Templates.user))
    }

    async getItemFromStore(id) {
        this.setState({ userTreatments: [], userPlans: [] })
        const result = await global.api.fetchUser(id)
        result.body.plans = (result.body.plans && result.body.plans.length) ? result.body.plans : [ global.config.defaultPlanCollection ]
        return result
    }

    deleteItemFromStore(id) {
        return global.api.deleteUserId(id)
    }

    getNameFromItem(item) {
        return item.first_name + " " + item.last_name
    }

    getIDFromItem(item) {
        return item.user_id
    }

    onLoadItem(item) {
        this.fetchUserTreatments(item.user_id)
        this.fetchUserPlans(item.user_id)
    }

    homeIcon() {
        return <i className="mdi mdi-wc homeIcon" />
    }

    homeText() {
        return "Find users and adjust their data, set and view their treatment or plan collections, and more."
    }

    homeSubtext() {
        return "Enter a first name, last name, and/or email address into the fields on the left and click 'Search' to get a list of matching users.  Click a user in the list to edit their information."
    }

    itemsAreEqual(a, b) {
        if (!a && !b) return true
        if (!a || !b) return false
        return JSON.stringify(a) === JSON.stringify(b)

    }

    noErrors(errorList) {
        return true
    }

    itemNotOKToSave(user) {
        if (!user.last_name) {
            return "Can't save user: need a last name."
        }
        if (!user.first_name) {
            return "Can't save user: need a first name."
        }
        if (!user.email) {
            return "Can't save user: need an email address."
        }
        return false
    }

    createNewItemInStore(user) {
        user.user_id = uuid()
        global.api.addUser(user).then(response => {
            this.saveSucceeded("New user " + user.first_name + " " + user.last_name + " created.")
            this.reloadListPane()
            this.fetchUserTreatments(user.user_id)
            this.fetchUserPlans(user.user_id)
        }).catch(e => {
            this.saveFailed("Error creating user: " + e.response.text)
        })
    }

    updateItemInStore(user) {
        global.api.updateUser(user).then(response => {
            this.saveSucceeded("Changes saved.")
            this.reloadListPane()
            this.fetchUserTreatments(user.user_id)
            this.fetchUserPlans(user.user_id)
        }).catch(e => {
            this.saveFailed("Error updating user: " + e.response.text)
        })
    }


    // Internal functions

    getItemsForList(user) {
        const items = user.treatments || []
        let objs = []
        items && items.forEach((i) => {
            if (i.startsWith('treatment')) {
                const tobj = this.state.treatments.find((x) => x.treatment_id === i)
                objs.push({
                    id: tobj.treatment_id,
                    name: tobj.name,
                    kind: 'treatment'
                })
            }
            if (i.startsWith('group')) {
                const gobj = this.state.groups.find((x) => x.group_id === i)
                objs.push({
                    id: gobj.group_id,
                    name: gobj.name,
                    kind: 'group'
                })
            }
        })
        return objs
    }

    getItemsForPlanList(user) {
        const items = user.plans || []
        let objs = []
        items && items.forEach((i) => {
            if (i.startsWith('treatplan')) {
                const pobj = this.state.plans.find((x) => x.treatplan_id === i)
                objs.push({
                    id: pobj.treatplan_id,
                    name: pobj.name,
                    kind: 'plan'
                })
            }
            if (i.startsWith('plan_group')) {
                const gobj = this.state.planGroups.find((x) => x.plan_group_id === i)
                objs.push({
                    id: gobj.plan_group_id,
                    name: gobj.name,
                    kind: 'plan group'
                })
            }
        })
        return objs
    }

    fetchUserTreatments(userId) {
        if (userId) {
            this.setState({ userTreatments: [] })
            global.api.fetchUserTreatments(userId).then(response => {
                this.setState({ userTreatments: response.body })
            }).catch(e => {
                this.showAlert("warning", "Error fetching user treatments: " + e)
            })
        }
    }

    fetchUserPlans(userId) {
        if (userId) {
            this.setState({ userPlans: [] })
            global.api.fetchUserPlans(userId).then(response => {
                this.setState({ userPlans: response.body })
            }).catch(e => {
                this.showAlert("warning", "Error fetching user plans: " + e)
            })
        }
    }

    onListItemsChanged(items) {
        this.clearAlert()
        this.updateItem('treatments', items.map((i) => i.id))
    }

    onPlanListItemsChanged(items) {
        this.clearAlert()
        this.updateItem('plans', items.map((i) => i.id))
    }

    groupSelected(e) {
        let group = this.state.groups.find((g) => this.nameAndIdForGroup(g) === e.target.value)
        if (group) {
            if (!this.state.item.treatments || !this.state.item.treatments.includes(group.group_id)) {
                this.treatmentList.addItem({
                    id: group.group_id,
                    name: group.name,
                    kind: 'group'
                })
            } else {
                this.showAlert("warning", group.name + " is already assigned to this user.")
            }
        }
    }

    planGroupSelected(e) {
        let group = this.state.planGroups.find((g) => this.nameAndIdForPlanGroup(g) === e.target.value)
        if (group) {
            if (!this.state.item.plans || !this.state.item.plans.includes(group.plan_group_id)) {
                this.planList.addItem({
                    id: group.plan_group_id,
                    name: group.name,
                    kind: 'plan group'
                })
            } else {
                this.showAlert("warning", group.name + " is already assigned to this user.")
            }
        }
    }

    treatmentSelected(e) {
        let treatment = this.state.treatments.find((t) => this.nameAndIdForTreatment(t) === e.target.value)
        if (treatment) {
            if (!this.state.item.treatments || !this.state.item.treatments.includes(treatment.treatment_id)) {
                this.treatmentList.addItem({
                    id: treatment.treatment_id,
                    name: treatment.name,
                    kind: 'treatment'
                })
            } else {
                this.showAlert("warning", treatment.name + " is already assigned to this user.")
            }
        }
    }

    planSelected(e) {
        let plan = this.state.plans.find((p) => this.nameAndIdForPlan(p) === e.target.value)
        if (plan) {
            if (!this.state.item.plans || !this.state.item.plans.includes(plan.treatplan_id)) {
                this.planList.addItem({
                    id: plan.treatplan_id,
                    name: plan.name,
                    kind: 'plan'
                })
            } else {
                this.showAlert("warning", plan.name + " is already assigned to this user.")
            }
        }
    }

    updatePhoneImage(e) {
        this.showAlert("success", "Uploading image...")
        this.uploadImage(e, 'treatment_plan_image_url_phone')
    }

    updateTabletImage(e) {
        this.showAlert("success", "Uploading image...")
        this.uploadImage(e, 'treatment_plan_image_url_tablet')
    }

    groupTreatmentIsFrom(tid) {
        let userGroups = this.state.groups.filter(g => this.state.item.treatments.includes(g.group_id))
        let group = userGroups.find(g => g.treatments.includes(tid))
        if (group) return group.name
        return ''
    }

    groupPlanIsFrom(pid) {
        let userPlanGroups = this.state.planGroups.filter(g => this.state.item.plans.includes(g.plan_group_id))
        let group = userPlanGroups.find(g => g.plans.includes(pid))
        if (group) return group.name
        return ''
    }

    tagSelected(tagKey) {
        this.clearAlert()
        const tagValue = UserTags[tagKey]
        let newTags = this.state.item.tags || []
        if (!newTags.includes(tagValue)) {
            newTags.push(tagValue)
            this.updateItem('tags', newTags)
        } else {
            this.showAlert("warning", "That tag is already applied.")
        }
    }

    removeTag(tagValue) {
        const newTags = this.state.item.tags.filter(t => t !== tagValue)
        this.updateItem('tags', newTags)
    }

    // Render functions

    renderTags(user) {
        let elements = []
        if (user.tags && user.tags.length > 0) {
            user.tags.forEach((userTag, i) => {
                    elements.push(<td index={i} className="tagItem" >
                        &nbsp;&nbsp;{ userTag } <i className="mdi mdi-cancel glyphIcon removeIcon" onClick={ ()=>this.removeTag(userTag) } />
                    </td>)
            })
        }
        elements.push(<td style={{"width": "70px"}} key={0}>
            <DropdownButton id="tag-selector" variant="success-outline" size="sm" title={ <i className="mdi mdi-add-box addIcon" /> }>
                { Object.keys(UserTags).map((tagKey, i) => {
                    return <Dropdown.Item key={i+1} onSelect={ ()=>this.tagSelected(tagKey) }>
                        <b>{ UserTags[tagKey] }</b>
                    </Dropdown.Item>
                })}
            </DropdownButton>
        </td>)

        return elements
    }

    renderGelLabel(user) {
        return (
            <span className="inputLabel">{
                user.gel_subscriber ? <b>Gel Subscriber</b> : 'not a Gel Subscriber'
            }</span>
        )
    }

    renderPlanLabel(user) {
        return (
            <span className="inputLabel">{
                user.has_treatment_plan ? <b>Has a Treatment Plan</b>: 'has no Treatment Plan'
            }</span>
        )
    }

    renderPlanImages(user) {
        if (!user.has_treatment_plan) return null

        return (
            <tr>
                <td className="inputLabel">plan images</td>
                <td><table><tbody><tr>
                    <td><img width="300" height="180" src={ user.treatment_plan_image_url_phone }
                             alt="Treatment Plan Phone"
                             className="editorImage" /><br />
                        <input type="file" onChange={ this.updatePhoneImage } /></td>
                    <td><img width="300" height="180" src={ user.treatment_plan_image_url_tablet }
                             alt="Treatment Plan Tablet"
                             className="editorImage" /><br />
                        <input type="file" onChange={ this.updateTabletImage } /></td>
                </tr></tbody></table>
                </td>
            </tr>
        )
    }

    renderUserTreatments(user) {
        let elements = []
        if (this.state.userTreatments) {
            let lastGroup = ''
            this.state.userTreatments.forEach((t,i) => {
                const label = this.nameAndIdForTreatment(t)
                let group = this.groupTreatmentIsFrom(t.treatment_id)
                if (group && !user.treatments.includes(t.treatment_id)) {
                    if (group !== lastGroup) {
                        lastGroup = group
                        elements.push(<tr key={'group'+i.toString()}><td className="treatmentListGroup">from {group}:</td></tr>)
                    }
                    elements.push(<tr key={i}><td className="treatmentListTreatment">&nbsp;&nbsp;{label}</td></tr>)
                } else {
                    elements.push(<tr key={i}><td className="treatmentListTreatment">{label}</td></tr>)
                }
            })
        }
        return elements
    }

    renderUserPlans(user) {
        let elements = []
        const userPlans = this.state.userPlans
        let lastGroup = ''
        userPlans.forEach((p,i) => {
            const label = this.nameAndIdForPlan(p)
            let group = this.groupPlanIsFrom(p.treatplan_id)
            if (group && !user.plans.includes(p.treatplan_id)) {
                if (group !== lastGroup) {
                    lastGroup = group
                    elements.push(<tr key={'group'+i.toString()}><td className="treatmentListGroup">from {group}:</td></tr>)
                }
                elements.push(<tr key={i}><td className="treatmentListTreatment">&nbsp;&nbsp;{label}</td></tr>)
            } else {
                elements.push(<tr key={i}><td className="treatmentListTreatment">{label}</td></tr>)
            }
        })
        return elements
    }

    renderPromoDropdown() {
        if (!this.state.selectedPromo) return "Select a subscription period to grant:"
        else return PromoPeriods[this.state.selectedPromo]
    }

    grantPromo(user) {
        if (!this.state.selectedPromo) {
            this.showAlert("warning", "Select a time period for the promotional subscription, then click Grant.")
            return
        }
        this.setState({ loading: true })
        const entitlementID = global.config.revenueCatEntitlementId
        global.api.grantPromoSub(user.user_id, this.state.selectedPromo, entitlementID).then((result) => {
            user.subscriptions = result.body.subscriber.subscriptions
            this.updateItem("subscriptions", user.subscriptions)
            this.setState({ loading: false })
            this.showAlert("success", this.state.selectedPromo + " promo subscription granted.")
        }).catch(e => {
            this.setState({ loading: false })
            this.showAlert("danger", "Error granting promo sub: " + e)
        })
    }

    revokePromo(user) {
        this.showConfirm("Are you sure you want to revoke this user's promo subscription?", "Cancel", "Revoke", (actually) => {
            this.actuallyRevokePromo(actually, user)
        })
    }

    actuallyRevokePromo(actually, user) {
        this.setState({ confirmText: "", confirmYes: "" })
        if (!actually) return
        this.setState({ loading: true })
        const entitlementID = global.config.revenueCatEntitlementId
        global.api.revokePromoSub(user.user_id, entitlementID).then((result) => {
            user.subscriptions = result.body.subscriber.subscriptions
            this.updateItem("subscriptions", user.subscriptions)
            this.setState({ loading: false })
            this.showAlert("success", "Promo subscription revoked.")
        }).catch(e => {
            this.setState({ loading: false })
            this.showAlert("danger", "Error revoking promo sub: " + e)
        })
    }

    renderSubscription(user) {
        if (!user.subscriptions || Object.getOwnPropertyNames(user.subscriptions).length === 0) {
            return (
                <table><tbody>
                    <tr><td className="tagItem">&nbsp;{ TreatmentTiers.FREE }&nbsp;</td><td>No subscription.</td></tr>
                    <tr><td/><td>
                        <Button variant="success" size="sm" style={{"display":"inline-block"}} onClick={()=>this.grantPromo(user)}>Grant promo sub</Button>&nbsp;
                        <DropdownButton id="promo-selector" variant="warning" size="sm" style={{"display":"inline-block"}}
                                        title={ this.renderPromoDropdown() }>
                            {
                                Object.getOwnPropertyNames(PromoPeriods).map(p => {
                                    return <Dropdown.Item onSelect={()=>this.setState({selectedPromo: p})}>
                                        <b>{ PromoPeriods[p] }</b>
                                    </Dropdown.Item>
                                })
                            }
                        </DropdownButton>
                    </td></tr>
                </tbody></table>
                )
        } else {
            // Show subscriptions
            let subInfo = "???"
            let isPromo = false
            let expiresDate = ""
            Object.getOwnPropertyNames(user.subscriptions).forEach(subtype => {
                const subdata = user.subscriptions[subtype]
                if (subdata.store === "promotional") isPromo = true
                if (subdata.expires_date) {
                    let match = /(\d+-\d+-\d+)/.exec(subdata.expires_date)
                    expiresDate = match[1]
                }
            })
            if (isPromo) {
                subInfo = "Promotional sub, expires on: " + expiresDate
            } else {
                subInfo = "Paid subscription, expires on: " + expiresDate
            }
            return (
                <table><tbody>
                    <tr><td className="tagItem">&nbsp;<b>{ TreatmentTiers.PREMIUM }</b>&nbsp;</td><td>{ subInfo }</td></tr>
                    <tr><td /><td>
                        { isPromo ? <Button variant="danger" size="sm" onClick={()=>this.revokePromo(user)}>Revoke promo sub</Button> : null }
                    </td></tr>
                </tbody></table>
            )
        }
    }

    renderEditor(user) {

        const popoverGroups = (
            <Popover id="groupHelp" className="helpPopover" >
                <Popover.Content>
                    Drag collections around to change their order.  Pick a collection from the dropdown to add it to the end of the user's collections.
                    Drag a collection to the trash to remove it from the user.
                </Popover.Content>
            </Popover>
        )

        let birthdate = user.birthday ? new Date(user.birthday) : null
        if (birthdate) {
            // convert from utc to local time
            birthdate = new Date(birthdate.getTime() + birthdate.getTimezoneOffset() * 60000)
        }
        const birthday = birthdate ? dateFormat(birthdate, "yyyy-mm-dd") : ""

        return (
            <Row>
                <Col className="col-9">
                    <table cellPadding={8} style={{"marginTop":"24px", "tableLayout":"fixed"}} className="col-12"><tbody>
                    <tr><th style={{"width":"150px"}} /><th /></tr>
                        <tr>
                            <td className="inputLabel" style={{width:"120px"}}>first name</td>
                            <td><table width="100%"><tbody><tr>
                                <td><input type="text" className="form-control" spellCheck="false" autoComplete="new-password"
                                        style={{ width: "160px" }} value={ user.first_name } onChange={ e=>this.updateItem('first_name', e.target.value)} />
                                </td>
                                <td><span className="inputLabel" style={{ marginLeft: "20px" }}> last name</span></td>
                                <td><input type="text" className="form-control" spellCheck="false"
                                        style={{ width: "300px" }} value={ user.last_name } onChange={ e=>this.updateItem('last_name', e.target.value)} />
                                </td>
                            </tr></tbody></table></td>
                        </tr>

                        <tr>
                            <td className="inputLabel">email</td>
                            <td><table width="100%"><tbody><tr>
                                <td><input type="text" className="form-control form-control-sm" spellCheck="false" autoComplete="new-password"
                                           style={{ width: "300px" }} value={ user.email } onChange={ e=>this.updateItem('email', e.target.value)} />
                                </td>
                                
                            </tr></tbody></table></td>
                        </tr>

                        <tr>
                            <td className="inputLabel">address</td>
                            <td>
                                <input type="text" className="form-control form-control-sm" spellCheck="false" autoComplete="new-password"
                                       style={{ width: "600px", marginBottom: "4px"}} value={ user.address_1 }
                                       onChange={ e=>this.updateItem('address_1', e.target.value)} />
                                <input type="text" className="form-control form-control-sm" spellCheck="false" autoComplete="new-password"
                                       style={{ width: "600px" }} value={ user.address_2 }
                                       onChange={ e=>this.updateItem('address_2', e.target.value)} />
                            </td>
                        </tr>

                        <tr>
                            <td className="inputLabel">city</td>
                            <td><table width="100%"><tbody><tr>
                                <td><input type="text" className="form-control form-control-sm" spellCheck="false" autoComplete="new-password"
                                           style={{ width: "200px" }} value={ user.city } onChange={ e=>this.updateItem('city', e.target.value)} />
                                </td>
                                <td><span className="inputLabel" style={{ marginLeft: "10px" }}> state</span></td>
                                <td><input type="text" className="form-control form-control-sm" spellCheck="false" autoComplete="new-password"
                                           style={{ width: "50px" }} value={ user.state } onChange={ e=>this.updateItem('state', e.target.value)} />
                                </td>
                                <td><span className="inputLabel" style={{ marginLeft: "10px" }}> ZIP</span></td>
                                <td><input type="text" className="form-control form-control-sm" spellCheck="false" autoComplete="new-password"
                                           style={{ width: "90px" }} value={ user.postal_code } onChange={ e=>this.updateItem('postal_code', e.target.value)} />
                                </td>
                                {/* <td><span className="inputLabel" style={{ marginLeft: "10px" }}> country</span></td>
                                <td><input type="text" className="form-control form-control-sm" spellCheck="false" autoComplete="new-password"
                                           style={{ width: "90px" }} value={ user.country } onChange={ e=>this.updateItem('country', e.target.value)} />
                                </td> */}
                            </tr></tbody></table></td>
                        </tr>


                        <tr>
                        <td className="inputLabel"> Tax Rate</td>
                                <td><input type="text" className="form-control form-control-sm" spellCheck="false" readOnly
                                           style={{ width: "100px" }} value={ user.tax_rate }  />
                                </td>
                        </tr>

                        <tr>
                        <td className="inputLabel"> phone</td>
                                <td><input type="text" className="form-control form-control-sm" spellCheck="false" autoComplete="new-password"
                                           style={{ width: "160px" }} value={ user.phone } onChange={ e=>this.updateItem('phone', e.target.value)} />
                                </td>
                        </tr>


                        <tr>
                            <td className="inputLabel">birthday</td>
                            <td><table width="100%"><tbody><tr>
                                <td><input type="date" className="form-control form-control-sm" spellCheck="false" autoComplete="new-password"
                                           style={{ width: "160px" }} value={ birthday } onChange={
                                               e=>this.updateItem('birthday', e.target.value ? dateFormat(e.target.value, "isoUtcDateTime") : null)}
                                /></td>
                                <td><span className="inputLabel" style={{ marginLeft: "20px" }}> gender</span></td>
                                <td><input type="text" className="form-control form-control-sm" spellCheck="false" autoComplete="new-password"
                                           style={{ width: "160px" }} value={ user.gender } onChange={ e=>this.updateItem('gender', e.target.value)} />
                                </td>
                            </tr></tbody></table></td>
                        </tr>

                        <tr><td /><td><hr/></td></tr>

                        <tr>
                            <td className="inputLabel">subscription</td>
                            <td>{ this.renderSubscription(user) }</td>
                        </tr>

                        <tr><td /><td><hr/></td></tr>

                        <tr>
                            <td className="inputLabel">tags</td>
                            <td><table className="tagListTable"><tbody><tr>
                                { this.renderTags(user) }
                            </tr></tbody></table></td>
                        </tr>

                        <tr>
                            <td />
                                <td>
                                    <FormCheck type="switch" label={ this.renderGelLabel(user) } checked={ user.gel_subscriber } id='gelSubscriber'
                                               onChange={ e=>this.updateItem('gel_subscriber', e.target.checked )}
                                    />
                                </td>
                        </tr>

                        <tr>
                            <td />
                            <td>
                                <FormCheck type="switch" label={ this.renderPlanLabel(user) } checked={ user.has_treatment_plan } id='hasPlan'
                                           onChange={ e=>this.updateItem('has_treatment_plan', e.target.checked )}
                                />
                            </td>
                        </tr>

                        { this.renderPlanImages(user) }

                        <tr><td /><td><hr/></td></tr>

                        <tr>
                            <td className="inputLabel">treatments</td>
                            <td>
                                <table><tbody>
                                <tr>
                                    <td className="col-8">
                                        <GroupOrTreatmentList onChange={ (items)=>this.onListItemsChanged(items) }
                                                              items={ this.getItemsForList(user) } ref={r=>{ this.treatmentList = r }} />
                                        <select className="groupSelector" onChange={ this.groupSelected } value={ 'Choose a collection to add to this user'}>
                                            <option key={0}>Choose a collection to add to this user</option>
                                            { this.state.groups
                                                .sort( (a, b) => parseInt(numID(a.group_id)) > parseInt(numID(b.group_id)) ? 1 : -1 )
                                                .map( (g, i)=> <option key={i+1}>{ this.nameAndIdForGroup(g) }</option> )}
                                        </select>
                                        <select className="treatmentSelector" onChange={ this.treatmentSelected } value={ 'Choose a treatment to add to this user'}>
                                            <option key={0}>Choose a treatment to add to this user</option>
                                            { this.state.treatments
                                                .sort( (a, b) => parseInt(numID(a.treatment_id ))> parseInt(numID(b.treatment_id)) ? 1 : -1 )
                                                .map( (t, i) => <option key={i+1}>{ this.nameAndIdForTreatment(t) }</option> )}
                                        </select>
                                    </td>
                                    <td className="col-4">
                                        <OverlayTrigger trigger="click" placement="bottom" overlay={popoverGroups}>
                                            <i className="mdi mdi-help glyphIcon" />
                                        </OverlayTrigger>
                                        <br />
                                        <i className="mdi mdi-delete-forever trashIcon" />
                                    </td>
                                </tr>
                                </tbody></table>

                            </td>
                        </tr>

                    <tr>
                        <td className="inputLabel">plans</td>
                        <td>
                            <table><tbody>
                            <tr>
                                <td className="col-8">
                                    <GroupOrPlanList onChange={ (items)=>this.onPlanListItemsChanged(items) }
                                                          items={ this.getItemsForPlanList(user) } ref={r=>{ this.planList = r }} />
                                    <select className="groupSelector" onChange={ this.planGroupSelected } value={ 'Choose a plan collection to add to this user'}>
                                        <option key={0}>Choose a plan collection to add to this user</option>
                                        { this.state.planGroups
                                            .sort( (a, b) => parseInt(numID(a.plan_group_id)) > parseInt(numID(b.plan_group_id)) ? 1 : -1 )
                                            .map( (g, i)=> <option key={i+1}>{ this.nameAndIdForPlanGroup(g) }</option> )}
                                    </select>
                                    <select className="treatmentSelector" onChange={ this.planSelected } value={ 'Choose a plan to add to this user'}>
                                        <option key={0}>Choose a plan to add to this user</option>
                                        { this.state.plans
                                            .sort( (a, b) => parseInt(numID(a.treatplan_id ))> parseInt(numID(b.treatplan_id)) ? 1 : -1 )
                                            .map( (t, i) => <option key={i+1}>{ this.nameAndIdForPlan(t) }</option> )}
                                    </select>
                                </td>
                                <td className="col-4">
                                    <OverlayTrigger trigger="click" placement="bottom" overlay={popoverGroups}>
                                        <i className="mdi mdi-help glyphIcon" />
                                    </OverlayTrigger>
                                    <br />
                                    <i className="mdi mdi-delete-forever trashIcon" />
                                </td>
                            </tr>
                            </tbody></table>

                        </td>
                    </tr>

                    <tr><td /><td><hr/></td></tr>
                        { this.renderButtons() }
                        </tbody>
                    </table>
                </Col>
                <Col className="col-3 referencePane">
                    <table cellPadding={4} style={{marginTop: "200px"}}><tbody>
                    <tr className="treatmentListHeader" key='header'><td>{user.first_name}'s treatments:</td></tr>
                        { this.renderUserTreatments(user) }
                    <tr className="treatmentListHeader" key='header'><td>{user.first_name}'s plans:</td></tr>
                        { this.renderUserPlans(user) }
                    </tbody></table>
                </Col>
            </Row>
        )
    }
}
