import React from 'react'
import SplitPane from '../generic/SplitPane'
import VideoList from './VideoList'
import VideoEditor from './VideoEditor'

export default class VideoPane extends SplitPane {

    constructor(props) {
        super(props)
        this.renderList = this.renderList.bind(this)
        this.renderEdit = this.renderEdit.bind(this)
    }


    // SplitPane functions

    renderList() {
        return <VideoList onItemClick={ this.onItemClick } updateCount = { this.state.updateCount }/>
    }

    renderEdit() {
        return <VideoEditor onEditorChange={ this.onEditorChange } item={ this.state.editing } selectCount={ this.state.selectCount } />
    }
}
