import React, {Component} from 'react'
import {
    BrowserRouter as Router,
    Route,
    Redirect
} from 'react-router-dom'
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import Masthead from './Masthead.js'
import Home from './Home.js'
import Login from './Login.js'
import TreatmentPane from './treatment/TreatmentPane.js'
import TreatplanPane from './treatplan/TreatplanPane.js'
import GroupPane from './group/GroupPane.js'
import PlanGroupPane from './plangroup/PlanGroupPane.js'
import UserPane from './user/UserPane.js'
import LocalePane from './locale/LocalePane.js'
import FilterPane from './filter/FilterPane.js'
import VideoPane from './video/VideoPane.js'
import FirmwarePane from './firmware/FirmwarePane.js'
import FeaturedPane from "./featured/FeaturedPane"

import './css/bootstrap.min.css'
import './css/material-icons.min.css'
import './css/App.css'

export default class App extends Component {

    // Used to rerender the DOM after a user has logged in or out.
    rerender() {
        this.forceUpdate()
    }

    render() {
        const user = localStorage.getItem('username')
        if (user) {
            global.config.username = user
            global.config.password = localStorage.getItem('password')
            return (
                <Container fluid className="h-100 pageContainer">
                    <Router>
                        <Row>
                            <Col className="Masthead col-12" style={{ margin: 0, padding: 0 }}>
                                <Masthead/>
                            </Col>
                        </Row>
                        <Redirect exact from="/" to="/home"/>
                        <Route exact path="/home" component={Home}/>
                        <Route exact path="/treatments" component={TreatmentPane}/>
                        <Route exact path="/collections" component={GroupPane}/>
                        <Route exact path="/users" component={UserPane}/>
                        <Route exact path="/locales" component={LocalePane}/>
                        <Route exact path="/filters" component={FilterPane}/>
                        <Route exact path="/videos" component={VideoPane}/>
                        <Route exact path="/firmware" component={FirmwarePane}/>
                        <Route exact path="/treatplans" component={TreatplanPane}/>
                        <Route exact path="/plancollections" component={PlanGroupPane}/>
                        <Route exact path="/featured" component={FeaturedPane}/>
                    </Router>
                </Container>
            )
        } else {
            global.config.username = ''
            global.config.password = ''
            return (
                <Container>
                    <Row>
                        <Col>
                            <Login rerender={this.rerender.bind(this)}/>
                        </Col>
                    </Row>
                </Container>
            )
        }
    }
}
