import React, {Component} from 'react'
import { numID } from '../common'
import {SortableContainer, SortableElement, arrayMove} from 'react-sortable-hoc'


const PlanlistItem = SortableElement(({value}) =>
    <li className="planSortItem"><b>{value.name}</b> ({numID(value.treatment_id)})</li>
)

const PlanlistList = SortableContainer(({items,parent}) =>
    <ul className="groupTreatmentsList">
        { items.map((t,i) => (
            <div key={'i'+i}><PlanlistItem index={i} value={t} parent={parent} />
                <span style={{"display":"inline-block"}}>&nbsp;&nbsp;<i className="mdi mdi-cancel glyphIcon removeIcon"
                                                                        onClick={e=>parent.removeItem(i)}/></span></div>
        ))}
    </ul>
)

export default class PlanTreatmentList extends Component {

    constructor(props) {
        super(props)
        this.state = { items: [] }
        this.setItems = this.setItems.bind(this)
        this.getItems = this.getItems.bind(this)
        this.onSortEnd = this.onSortEnd.bind(this)
    }

    componentDidMount() {
        this.setItems(this.props.items)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (JSON.stringify(prevProps.items) !== JSON.stringify(this.props.items)) {
            this.setItems(this.props.items)
        }
    }

    setItems(items) {
        this.setState({ items: items })
    }

    addItem(treatment) {
        let newItems = this.state.items
        newItems.push(JSON.parse(JSON.stringify(treatment)))
        this.setState({ items: newItems })
        this.props.onChange(newItems)
    }

    removeItem(i) {
        this.state.items.splice(i, 1);
        this.setState({ items: this.state.items })
        this.props.onChange(this.state.items)
    }

    onSortEnd({oldIndex, newIndex}, event) {
        let newItems = this.state.items
        newItems = arrayMove(newItems, oldIndex, newIndex)
        this.setState({ items: newItems })
        this.props.onChange(newItems)
    }

    getItems() {
        return this.state.items;
    }

    render() {
        return (
            <PlanlistList className="groupTreatmentsList" items={this.state.items} parent={this} onSortEnd={this.onSortEnd} />
        )
    }
}
