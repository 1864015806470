import React from 'react'
import '../css/App.css'
import '../css/Firmware.css'
import {Button} from 'react-bootstrap'
import SplitEditor from '../generic/SplitEditor'
import '../css/firmware-react.css'
import {firmwareIsFlagged} from './FirmwareIsFlagged'
const moment = require('moment')

export default class FirmwareEditor extends SplitEditor {

    constructor(props) {
        super(props)
        this.refreshTask = null

        this.renderEditor = this.renderEditor.bind(this)
        this.renderUploader = this.renderUploader.bind(this)
        this.renderIfNotNew = this.renderIfNotNew.bind(this)
        this.renderIfNotMissing = this.renderIfNotMissing.bind(this)
        this.renderIfFileChosen = this.renderIfFileChosen.bind(this)
        this.renderFileWithTime = this.renderFileWithTime.bind(this)
        this.renderCreateTime = this.renderCreateTime.bind(this)
        this.reloadItemStatus = this.reloadItemStatus.bind(this)
        this.updateFirmwareFile = this.updateFirmwareFile.bind(this)
        this.handleActivate = this.handleActivate.bind(this)
        this.actuallyActivate = this.actuallyActivate.bind(this)
        this.downloadFile = this.downloadFile.bind(this)
    }

    componentDidMount() {
        super.componentDidMount()
    }

    componentWillUnmount() {
        this.refreshTask && clearTimeout(this.refreshTask)
    }

    // SplitEditor functions

    getBlankItem() {
        let newItem = JSON.parse(JSON.stringify(global.Templates.firmware))
        newItem.create_time = Math.floor(Date.now() / 1000)
        return newItem
    }

    getItemFromStore(id) {
        return global.api.fetchFirmware(id)
    }

    parseLoadedItem(firmware) {
        return firmware
    }

    deleteItemFromStore(id) {
        return global.api.deleteFirmwareId(id)
    }

    getNameFromItem(firmware) {
        return firmware.name
    }

    getIDFromItem(firmware) {
        return firmware.firmware_id
    }

    onLoadItem(firmware) {
        this.refreshTask && clearTimeout(this.refreshTask)
    }

    homeIcon() {
        return <i className="mdi mdi-device-hub homeIcon" />
    }

    homeText() {
        return "Select a firmware to modify/re-upload, or click Add Firmware to create and upload a new one."
    }

    homeSubtext() {
        return "This list shows all available firmware versions.  Click a firmware version to set its status to active or to check usage details.  Once you've activate any of firmware version here, it will be accessible by mobile client to download."
    }

    itemsAreEqual(a,b) {
        if (!a && !b) return true
        if (!a || !b) return false
        if (JSON.stringify(a) === JSON.stringify(b)) return true
        return false
    }

    noErrors(errorList) {
        return true
    }

    itemNotOKToSave(firmware) {
        if (!firmware.name) return "Enter a name for this firmware."
        if (!firmware.filename) return "Upload a firmware file to save."
        return false
    }

    itemNotOKToDelete(firmware) {
        if (firmware.active)
            return "This firmware is currently active for all users.  Set a different firmware as active to deactivate this one and make it deletable."
        return false
    }

    prepFirmwareForSave(firmware) {
        let saveFirmware = JSON.parse(JSON.stringify(firmware))
        return saveFirmware
    }

    createNewItemInStore(firmware) {
        global.api.addFirmware(this.prepFirmwareForSave(firmware)).then(response => {
            this.saveSucceeded("New firmware uploaded!")
            this.loadItem(response.body.firmware_id)
            this.reloadListPane()
        }).catch(e => {
            this.saveFailed("Error uploading firmware: " + e)
        })
    }

    updateItemInStore(firmware) {
        global.api.updateFirmware(this.prepFirmwareForSave(firmware)).then(response => {
            this.saveSucceeded("Changes saved.")
            this.reloadListPane()
        }).catch(e => {
            this.saveFailed("Error updating firmware: " + e)
        })
    }

    reloadItemStatus() {
        this.getItemFromStore(this.state.item.firmware_id).then((response) => {
            this.reloadCurrentItem()
        })
    }

    updateFirmwareFile(event) {
        const file = event.target.files[0]
        const uploadFilename = file.name
        const match = /^(\d+)_(\d+)_oad\.bin$/.exec(uploadFilename)
        if (!match) {
            this.showAlert("warning", "Invalid firmware upload filename!  Should look like: 5_10312022_oad.bin")
            return
        }
        this.showAlert("success", "Uploading firmware image...")
        const contentType = "application/octet-stream"
        const self = this
        const reader = new FileReader()
        reader.onload = function (event) {
            global.api.validateFirmwareUpload(uploadFilename).then(data => {
                const formData = data.body
                formData.formInputs['Content-Type'] = contentType
                return global.api.uploadImage(file, formData)
            }).then(result => {
                const updatedItem = {
                    ...self.state.item,
                    filename: uploadFilename,
                    upload_time: Date.now()
                }
                self.setState({ item : updatedItem })
                self.showAlert("success", "Firmware uploaded!")
            }).catch(error => {
                self.showAlert("danger", "Error uploading firmware: " + error)
            })
        }
        let thisEditor = this
        reader.onerror = function (event) {
            thisEditor.showAlert("danger", "File could not be read! Code " + event.target.error.code)
        }
        reader.readAsArrayBuffer(file)
    }

    handleActivate(e) {
        this.showConfirm("This will force a firmware update on ALL live users.  Are you sure you want to set '" + this.state.item.name + "' as the active firmware?", "Cancel", "Activate!", (actually) => {
            this.actuallyActivate(actually)
        })
    }

    actuallyActivate(actually) {
        this.setState({ confirmText: "",  confirmYes: "" })
        if (!actually) return
        global.api.activateFirmware(this.state.item).then(response => {
            this.saveSucceeded()
        }).catch(e => {
            this.saveFailed("Error activating firmware: " + e.response.text)
        })
    }

    downloadFile(e) {
        const filename = this.state.item.filename
        e.preventDefault()
        window.open(global.config.firmwareBucketUrl + filename)
    }

    // Render functions

    renderActiveIcon(active) {
        if (active) {
            return <i className="mdi mdi-check-circle statusIcon statusIconGreen" />
        } else {
            return <i className="mdi mdi-error statusIcon statusIconYellow" />
        }
    }

    renderActive(isActive, time) {
        return <div>
            <span style={{"display":"inline-block"}}>{ this.renderActiveIcon(isActive) }</span>&nbsp;&nbsp;
            <span className={isActive ? "activeStatus statusLabel" : "inactiveStatus statusLabel"}>{isActive ? "ACTIVE" : "Inactive"}</span>&nbsp;&nbsp;
            <span className="statusInterval">{(time > 0) && (isActive ? "activated " : "deactivated ") + (moment.duration(Date.now() - (time*1000)).humanize() + " ago")}</span>
        </div>
    }

    renderActivateButton(firmware) {
        if (this.hasUnsavedChanges() || this.itemNotOKToSave(firmware)) {
            return "Save this firmware record to allow activation."
        } else if (!firmware.active) {
            return <Button className="btn btn-sm" onClick={ this.handleActivate }>Activate for all
                users</Button>
        } else {
            return "To deactivate this firmware for all users, pick another firmware and Activate it."
        }
    }

    renderFileWithTime(filename, time) {
        if (!filename) {
            return "(none)"
        }
        return <div>
            <b>{filename}</b> ({(time > 0) && (moment.duration(Date.now() - time).humanize() + " ago")})
        </div>
    }

    renderCreateTime(time) {
        if (time > 0) {
            return moment.unix(time).format("MM/DD/YYYY")
        }
    }

    renderIfFileChosen(element) {
        if (this.uploader && this.uploader.hasChosenFile()) return element
    }

    renderUploader(placeholder, acceptType) {
        return (
            <tr>
                <td className="inputLabel">{placeholder}</td>
                <td><input type="file" onChange={ this.updateFirmwareFile } /></td>

            </tr>
        )
    }

    renderIfNotNew(elements) {
        if (!this.state.isNew) return elements
    }

    renderIfNotMissing(elements) {
        if (!this.itemNotOKToSave(this.state.item)) return elements
    }

    itemIsFlagged(firmware) {
        return firmwareIsFlagged(firmware)
    }

    renderDownload(firmware) {
        if (firmware.filename) {
            return [
                <tr>
                    <td></td>
                    <td><Button className="btn btn-sm" onClick={ this.downloadFile }>Download file</Button></td>
                </tr>
            ]
        }
    }

    renderEditor(firmware) {
        return (
            <table cellPadding={8} style={{"marginTop":"24px", "tableLayout":"fixed"}} className="col-12"><tbody>
            <tr><th style={{"width":"150px"}} /><th /></tr>

            { this.renderFlagged(firmware) }

            <tr>
                <td className="inputLabel">name</td>
                <td>
                    <input type="text" style={{width:"400px", display:"inline-block"}} className="userInput form-control"
                           value={ firmware.name } onChange={ e=>this.updateItem('name', e.target.value) } />
                </td>
            </tr>

            { this.renderUploader("upload firmware", "*/*") }
           
            <tr>
                <td className="inputLabel">active?</td>
                <td>{ this.renderActive(firmware.active, firmware.active_change_time) }</td>
            </tr>

            <tr>
                <td></td>
                <td>{ this.renderActivateButton(firmware) }</td>
            </tr>

            <tr>
                <td className="inputLabel">created on</td>
                <td>{ this.renderCreateTime(firmware.create_time) }</td>
            </tr>

            { this.renderDownload(firmware) }
            <tr>
                <td className="inputLabel">current file</td>
                <td>
                    { this.renderFileWithTime(firmware.filename, firmware.upload_time) }
                </td>
            </tr>

            <tr><td /><td><hr/></td></tr>

            { this.renderButtons() }

            </tbody></table>
        )
    }
}
