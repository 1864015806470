import React, { Component } from 'react'
import { Button } from 'react-bootstrap'
import { TableContainer, Table, TableBody, TableCell, TableRow, Paper } from '@material-ui/core'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import LoadingOverlay from 'react-loading-overlay';

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
}

export const getItemStyle = (isDragging, draggableStyle) => ({
    // styles we need to apply on draggables
    ...draggableStyle,

    ...(isDragging && {
        background: "rgb(235,235,235)"
    })
})

export default class DnDSplitList extends Component {

    constructor(props) {
        super(props)
        this.state = { items: [], selected: "", loading: false }

        this.fetchItems = this.fetchItems.bind(this)
        this.onItemClick = this.onItemClick.bind(this)
        this.renderItems = this.renderItems.bind(this)
        this.renderAddButton = this.renderAddButton.bind(this)
        this.classForItem = this.classForItem.bind(this)
        this.handleNewItem = this.handleNewItem.bind(this)
        this.renderFlag = this.renderFlag.bind(this)
        this.itemIsFlagged = this.itemIsFlagged.bind(this)
        this.onDragEnd = this.onDragEnd.bind(this)
    }

    onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            return
        }

        console.log(`dragEnd ${result.source.index} to  ${result.destination.index}`)

        const items = reorder(
            this.state.items,
            result.source.index,
            result.destination.index
        )
        this.updateItemsOrder(items)
        this.setState({
            items
        })
    }

    componentDidMount() {
        this.fetchItems()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.updateCount < this.props.updateCount) {
            this.fetchItems()
        }
    }

    fetchItems() {
        const getter = this.getItems()
        if (getter === null) return
        this.setState({ loading: true })
        getter.then((response) => {
            this.setState({ items: response.body, loading: false })
        }).catch((error) => {
            console.error(error)
            window.alert("Error fetching items: " + error)
            this.setState({ loading: false })
        })
    }

    onItemClick(id, e) {
        e.preventDefault()
        this.props.onItemClick(id)
        this.setState({ selected: id })
    }

    classForItem(id) {
        if (id === this.state.selected) {
            return "selected"
        }
    }

    handleNewItem(e) {
        e.preventDefault()
        this.props.onItemClick("new")
        this.setState({ selected: "" })
    }

    itemIsFlagged(item) {
        return false
    }

    renderHeader() {
     }

    renderFlag(item) {
        if (this.itemIsFlagged(item)) return <i className="mdi mdi-warning glyphIcon warnIcon" />
    }

    renderItems() {
                return (
                <TableBody component={DroppableComponent(this.onDragEnd)}>
                {this.state.items.map((item, index) => (
                    <TableRow onClick={ (e) => this.onItemClick(item.treatplan_id || item.filter_id, e)}
                     component={DraggableComponent(item.treatplan_id || item.filter_id, index)} key={item.treatplan_id || item.filter_id} >
                        <TableCell scope="row">{index + 1}</TableCell>
                        <TableCell>{item.name || item.default_name}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
                )

    }

    renderAddButton() {
        const label = this.labelAddButton()
        if (label) {
            return (
                <Button onClick={ this.handleNewItem } className="btn-sm btn-success addButton">
                    <i className="mdi mdi-add-box"/> { label }
                </Button>
            )
        }
    }

    

    render() {
            return (
                <div style={{margin:"10px", width: "95%"}}>
                    {this.renderAddButton()}
                    <TableContainer component={Paper}>
                    <LoadingOverlay
                    active={this.state.loading}
                    spinner
                    text='Applying element ordering...'
                    >
                    <Table>
                        {this.renderHeader()}
                        {this.renderItems()}
                    </Table>
                    </LoadingOverlay>
            </TableContainer>
                </div>
            )
        
    }
}

export const DraggableComponent = (id, index) => (props) => {
    return (
        <Draggable draggableId={id} index={index}>
            {(provided, snapshot) => (
                <TableRow
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                    {...props}>
                    {props.children}
                </TableRow>
            )}
        </Draggable>
    )
}

export const DroppableComponent = (onDragEnd: (result, provided) => void) => (props) =>
{
    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={'1'} direction="vertical">
                {(provided) => {
                    return (
                        <TableBody ref={provided.innerRef} {...provided.droppableProps} {...props}>
                            {props.children}
                            {provided.placeholder}
                        </TableBody>
                    )
                }}
            </Droppable>
        </DragDropContext>
    )
}
