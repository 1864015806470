import React from 'react'
import SplitPane from '../generic/SplitPane'
import UserList from "./UserList";
import UserEditor from "./UserEditor";

export default class UserPane extends SplitPane {

    // SplitPane functions

    renderList() {
        return <UserList onItemClick={ this.onItemClick } updateCount={ this.state.updateCount }/>
    }

    renderEdit() {
        return <UserEditor onEditorChange={ this.onEditorChange } item={ this.state.editing } selectCount={ this.state.selectCount } />
    }
}
