import React from 'react';
import SplitList from '../generic/SplitList'
import { numID } from '../common'
import {groupIsFlagged} from './GroupIsFlagged'

export default class GroupList extends SplitList {

    // SplitList functions

    getItems() {
        return global.api.fetchGroups()
    }

    itemId(item) {
        return item.group_id
    }

    itemSort(item) {
        if (this.state.sort === 'name') {
            return item.name
        } else {
            return parseInt(numID(item.group_id))
        }
    }

    itemIsFlagged(group) {
        return groupIsFlagged(group)
    }

    renderHeader() {
        return [
            <tr><th style={{"width":"12px"}} /><th style={{"width":"60px"}} /><th /></tr>,
            <tr className="listHeaderRow">
                <td />
                <td className="listHeader" onClick={(e) => this.setState({sort: 'group_id'})}
                    style={{'paddingLeft': '30px'}}>{this.renderIDHeader()}</td>
                <td className="listHeader" onClick={(e) => this.setState({sort: 'name'})}>{this.renderNameHeader()}</td>
            </tr>
        ]
    }

    renderIDHeader() {
        if (this.state.sort === 'name') {
            return "id"
        } else {
            return ["id", <i className="mdi mdi-keyboard-arrow-down sortArrow"/>]
        }
    }

    renderNameHeader() {
        if (this.state.sort === 'name') {
            return ["name", <i className="mdi mdi-keyboard-arrow-down sortArrow"/>]
        } else {
            return "name"
        }
    }

    renderItem(item) {
        let idNum = numID(item.group_id)
        return [
            <td key={'f'+idNum}>{ this.renderFlag(item) }</td>,
            <td key={item.group_id} className="userData" style={{'paddingLeft': '30px'}}><b>{ idNum }</b></td>,
            <td key={item.name} className="userData">{ item.name }</td>
        ]
    }

    labelAddButton() {
        return "Add collection"
    }
}
