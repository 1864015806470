import React, {Component} from 'react'
import {SortableContainer, SortableElement, arrayMove} from 'react-sortable-hoc'

const SortableItem = SortableElement(({value}) =>
    <li className="treatmentSortItem">
        <div className="input-group">
            <input type="text" className="form-control form-control-sm col-3" placeholder="label" value={ value.label }
                   onChange={ e=>value.updateLabel(value.id, e.target.value) } />
            <div className="input-group-append col-auto">
                { value.name }
            </div>
        </div>
    </li>
)

const SortableList = SortableContainer(({items}) => {
    return (
        <ul className="groupTreatmentsList">
            {items.map((item, index) => (
                <SortableItem key={`item-${index}`} index={index} value={{
                    id: item.id,
                    name: item.name,
                    label: item.label,
                    updateLabel: item.updateLabel,
                    i: index
                }}/>
            ))}
        </ul>
    )
})

export default class SortableFeaturedList extends Component {

    constructor(props) {
        super(props)
        this.state = {items: []}
        this.setItems = this.setItems.bind(this)
        this.addItem = this.addItem.bind(this)
        this.getItems = this.getItems.bind(this)
        this.onSortEnd = this.onSortEnd.bind(this)
        this.updateLabel = this.updateLabel.bind(this)
    }

    componentDidMount() {
        this.setItems(this.props.items)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (JSON.stringify(prevProps.items) !== JSON.stringify(this.props.items)) {
            this.setItems(this.props.items)
        }
    }

    setItems(items) {
        items.forEach(t => {
            t.updateLabel = this.updateLabel
        })
        this.setState({ items: items })
    }

    addItem(item) {
        let newItems = this.state.items
        item.updateLabel = this.updateLabel
        newItems.push(item)
        this.setState({ items: newItems })
        this.props.onChange(newItems)
    }

    getItems() {
        return this.state.items
    }

    onSortEnd({oldIndex, newIndex}, event) {
        let newItems = this.state.items
        if ((event.target.className.search('trashIcon') > 0) ||
            event.target.className.search('trashCell') > 0) {
            newItems.splice(oldIndex, 1)
        } else {
            newItems = arrayMove(newItems, oldIndex, newIndex)
        }
        this.setState({ items: newItems })
        this.props.onChange(newItems)
    }

    updateLabel(id, newtext) {
        let newItems = this.state.items
        newItems.find(t=>t.id === id).label = newtext
        this.setState({ items: newItems })
        this.props.onChange(newItems)
    }

    render() {
        if (this.state.items && this.state.items.length > 0) {
            return <SortableList className="groupList" items={this.state.items} onSortEnd={this.onSortEnd}/>
        } else {
            return <span className="inputLabel">There are no featured treatments.<br /><br /></span>
        }
    }
}
