import React, {Component} from 'react'
import {SortableContainer, SortableElement, arrayMove} from 'react-sortable-hoc'
import { numID } from './common'

const SortableItem = SortableElement(({value}) =>
    <li className="groupSortItem">{value.i + 1}. <b>{value.name}</b> ({value.id})</li>
)

const SortableList = SortableContainer(({items}) => {
    return (
        <ul className="groupTreatmentsList">
            {items.map((p, i) => (
                <SortableItem key={`item-${i}`} index={i}
                              value={{
                                  id: numID(p.treatplan_id),
                                  name: p.name,
                                  i: i
                              }}
                />
            ))}
        </ul>
    )
})

export default class SortablePlanList extends Component {

    constructor(props) {
        super(props)
        this.state = { items: [] }
        this.setItems = this.setItems.bind(this)
        this.getItems = this.getItems.bind(this)
        this.onSortEnd = this.onSortEnd.bind(this)
    }

    componentDidMount() {
        this.setItems(this.props.items)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (JSON.stringify(prevProps.items) !== JSON.stringify(this.props.items)) {
            this.setItems(this.props.items)
        }
    }

    setItems(items) {
        this.setState({ items: items })
    }

    addItem(treatment) {
        let newItems = this.state.items
        newItems.push(treatment)
        this.setState({ items: newItems })
        this.props.onChange(newItems)
    }

    getItems() {
        return this.state.items
    }

    onSortEnd({oldIndex, newIndex}, event) {
        let newItems = this.state.items
        if ((event.target.className.search('trashIcon') > 0) ||
            event.target.className.search('trashCell') > 0) {
            // The treatment was dragged to the trash, remove it
            newItems.splice(oldIndex, 1)
        } else {
            newItems = arrayMove(newItems, oldIndex, newIndex)
        }
        this.setState({ items: newItems })
        this.props.onChange(newItems)
    }

    render() {
        return <SortableList className="groupTreatmentsList" items={this.state.items} onSortEnd={this.onSortEnd}/>
    }
}
