import React, {Component} from 'react'
import { Row, Col } from 'react-bootstrap'

class Home extends Component {

    render() {
        return (
            <div style={{ margin: 0, padding: 0}} className="fill-window panesContainer">
                <Row className="splitRow min-vh-100">
                    <Col style={{ margin: 0, padding: 0}} className="col-3 selectionBar">
                    </Col>
                    <Col style={{ margin: 0, padding: 0}} className="col-9">
                        <div className="home">
                            <p className="homeText">
                                <i className="mdi mdi-home homeIcon" /><br/>Welcome to the ZIIP Dashboard.
                            </p>
                            <p className="homeSubtext">
                                {global.config.envMsg}
                            </p>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Home;
