import React from 'react'
import '../css/App.css'
import SplitEditor from '../generic/SplitEditor'
import Popover from 'react-bootstrap/Popover'
import Button from 'react-bootstrap/Button'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import LocaleSelector from '../LocaleSelector'
import PlanTreatmentList from "./PlanTreatmentList"
import { treatplanIsFlagged } from './TreatplanIsFlagged'
import { numID } from '../common'

export default class TreatplanEditor extends SplitEditor {

    constructor(props) {
        super(props)
        this.treatmentLists = {}
        this.state = {...this.state, treatments: [] }
        this.chooseTreatmentText = "Pick a treatment to add to this plan day."

        this.addDay = this.addDay.bind(this)
        this.dayTreatmentSelected = this.dayTreatmentSelected.bind(this)
        this.getTreatmentsForDay = this.getTreatmentsForDay.bind(this)
        this.localeChanged = this.localeChanged.bind(this)
        this.onDayTreatmentsChanged = this.onDayTreatmentsChanged.bind(this)
        this.parseLoadedItem = this.parseLoadedItem.bind(this)
        this.removeDay = this.removeDay.bind(this)
        this.toggleSupportedDevice = this.toggleSupportedDevice.bind(this)
        this.updateImage = this.updateImage.bind(this)
    }

    componentDidMount() {
        super.componentDidMount()
        this.fetchTreatments()
        global.localeManager.addListener(this)
    }

    componentWillUnmount() {
        global.localeManager.removeListener(this)
    }


    // SplitEditor functions

    getBlankItem() {
        return JSON.parse(JSON.stringify(global.Templates.treatplan))
    }

    getItemFromStore(id) {
        return global.api.fetchTreatplan(id)
    }

    itemNotOKToDelete(treatplan) {
        return false
    }

    deleteItemFromStore(id) {
        return global.api.deleteTreatplanId(id)
    }

    getNameFromItem(treatplan) {
        return treatplan.name
    }

    getIDFromItem(treatplan) {
        return treatplan.treatplan_id
    }

    onLoadItem(treatplan) {
        this.treatmentLists = {}
        this.forceUpdate()
    }

    parseLoadedItem(item) {
        // Add supported devices if they haven't been set yet
        if (!item.supported_devices) {
            item.supported_devices = []
        }
        return item
    }

    homeIcon() {
        return <i className="mdi mdi-assignment-turned-in homeIcon" />
    }

    homeText() {
        return "Pick a treatment plan to edit, or Create Plan to create a new one."
    }

    homeSubtext() {
        return "Treatment plans guide a user through a set of treatments on each day of the plan."
    }

    itemsAreEqual(a, b) {
        if (!a && !b) return true
        if (!a || !b) return false
        if (JSON.stringify(a) === JSON.stringify(b)) return true
        return false
    }

    noErrors(errorList) {
        return true
    }

    itemNotOKToSave(treatplan) {
        if (treatplan.name === "") {
            return "Enter a name for this treatment plan."
        }
        return false
    }

    createNewItemInStore(treatplan) {
        global.api.getNewTreatplanID().then(idResponse => {
            treatplan.treatplan_id = idResponse.body.unused_id
            const prepped = this.prepPlanForSave(treatplan)
            global.api.addTreatplan(prepped).then(response => {
                this.saveSucceeded("New treatment plan created.")
                this.loadItem(response.body.treatplan_id)
                this.reloadListPane()
            }).catch(e => {
                this.saveFailed("Error creating plan: " + e.response.text)
            })
        }).catch(e => {
            this.saveFailed("Error creating treatment: " + e)
        })
    }

    updateItemInStore(treatplan) {
        const prepped = this.prepPlanForSave(treatplan)
        global.api.updateTreatplan(prepped).then(response => {
            this.saveSucceeded("Changes saved.")
        }).catch(e => {
            this.saveFailed("Error saving plan: " + e.response.text)
        })
    }

    itemIsFlagged(treatplan) {
        return treatplanIsFlagged(treatplan)
    }


    // Internal functions

    prepPlanForSave(treatplan) {
        const prepped = this.processLocaleStrings(treatplan)
        return prepped
    }

    processLocaleStrings(treatplan) {
        const plan = JSON.parse(JSON.stringify(treatplan))
        if (plan.name) {
            plan.name = this.encodeStringId(plan.name, plan.treatplan_id, "name")
        }
        if (plan.summary) {
            plan.summary = this.encodeStringId(plan.summary, plan.treatplan_id, "summary")
        }
        if (plan.description) {
            plan.description = this.encodeStringId(plan.description, plan.treatplan_id, "description")
        }
        return plan
    }

    fetchTreatments() {
        this.setState({ loading: true })
        return global.api.fetchTreatments().then((response) => {
            this.setState({ treatments: response.body, loading: false })
        }).catch(e => {
            this.setState({ loading: false })
            this.showAlert("danger", "Error fetching treatments: " + e)
        })
    }

    localeChanged(locale) {
        if (this.state.item && (this.state.item.treatplan_id || this.state.isNew)) {
            this.fetchTreatments().then(() => {
                this.reloadCurrentItem()
                this.reloadListPane()
            })
        }
    }

    addDay() {
        if (global.localeManager.currentLocaleId() !== global.config.defaultLocale) {
            this.showAlert("warning", "Switch to " + global.config.defaultLocale + " locale to add plan days.")
            return
        }
        let days = this.state.item.days
        if (typeof(days) != 'object') days = []
        let nextday = 1
        days.forEach(d=>{if (d.day > nextday) nextday = d.day})
        nextday += 1
        days.push({ day: nextday, treatments: [] })
        this.updateItem('days', days)
        this.showAlert("success", "Added day " + days.length + " to the plan.")
    }

    removeDay(i) {
        if (global.localeManager.currentLocaleId() !== global.config.defaultLocale) {
            this.showAlert("warning", "Switch to " + global.config.defaultLocale + " locale to remove plan days.")
            return
        }
        let days = this.state.item.days
        if (days.length < 2) {
            this.showAlert("warning", "You can't remove the only day of a plan -- there would be no days at all!")
            return
        }
        days.splice(i, 1)
        let daynum = 1
        days.forEach(day=>{
            day.day = daynum
            daynum++
        })
        this.updateItem('days', days)
        this.showAlert("success", "Removed day " + i + " from the plan.")
    }

    onDayTreatmentsChanged(i, treatments) {
        this.clearAlert()
        let days = this.state.item.days
        days.find(d=>d.day === i).treatments = treatments.map(t=>t.treatment_id)
        this.updateItem('days', days)
    }

    getTreatmentsForDay(i) {
        let day = this.state.item.days.find(d=>d.day === i)
        let treatments = []
        day.treatments.forEach(dt => {
            treatments.push(this.state.treatments.find(t=>t.treatment_id === dt))
        })
        return treatments
    }

    dayTreatmentSelected(dayNum, e) {
        let v = e.target.value
        this.clearAlert()
        let treatment = this.state.treatments.find( t => this.nameAndIdForTreatment(t) === v)
        if (treatment) {
            this.treatmentLists[dayNum].addItem(treatment)
        }
    }

    toggleSupportedDevice(device) {
        const item = this.state.item
        const index = item.supported_devices.indexOf(device)
        if (index >= 0)
            item.supported_devices.splice(index, 1)
        else
            item.supported_devices.push(device)
        this.setState({ item })
    }

    updateImage(event) {
        this.showAlert("success", "Uploading image...")
        this.uploadImage(event, 'image_url')
    }


    // Render functions

    renderDays(treatplan) {
        let elements = []
        treatplan.days.forEach((day,i) => {
            elements.push(<tr key={"tsplit"+i}><td /><td><hr/></td></tr>)
            let content = []
            let dayNum = day.day
            content.push(
                <PlanTreatmentList onChange={ (treatments)=>this.onDayTreatmentsChanged(dayNum,treatments) } key={"tlist"+dayNum}
                                   items={ this.getTreatmentsForDay(dayNum) } ref={ r=>{ this.treatmentLists[dayNum] = r }} />
            )
            if (day.treatments.length < 1) {
                content.push(<span key={0}><b>Rest Day -- No treatments</b><p /></span>)
            }
            content.push(
                <select key={"tsel"+dayNum} className="treatmentSelector"
                        onChange={e=>this.dayTreatmentSelected(dayNum, e)} value={ this.chooseTreatmentText }>
                    <option key={0}>{ this.chooseTreatmentText }</option>
                    { this.state.treatments && this.state.treatments
                        .sort((a, b) => parseInt(numID(a.treatment_id)) > parseInt(numID(b.treatment_id)) ? 1 : -1 )
                        .map( (t,idx) => <option key={idx+1}>{ this.nameAndIdForTreatment(t) }</option>) }
                </select>
            )

            content.push(<span key={"tor"+dayNum}>&nbsp;&nbsp;or&nbsp;&nbsp;</span>)
            content.push(<Button key={"tb"+dayNum} variant="outline-danger" size="sm" onClick={e=>this.removeDay(i)}>Remove day</Button>)

            elements.push(<tr key={"trow"+dayNum}><td className="inputLabel">day &nbsp;<span style={{"fontSize":"24px"}}>{day.day}</span><br/>treatments</td><td>{content}</td></tr>)
        })
        return elements
    }

    renderDaysAdd() {
        return (
            <Button variant="outline-success" size="sm" onClick={e => this.addDay() }>Add day</Button>
        )
    }

    renderEditor(treatplan) {

        const popoverLocale = (
            <Popover id="localeHelp" className="helpPopover" >
                <Popover.Content>
                    Locale setting affects the plan's name, summary, and description, which are localized per locale.  Treatments per day will be the same for every locale.
                </Popover.Content>
            </Popover>
        )
        return (
            <table cellPadding={8} style={{"marginTop":"24px", "tableLayout":"fixed"}} className="col-12"><tbody>
                <tr><th style={{"width":"150px"}} /><th /></tr>

                { this.renderFlagged(treatplan) }

                <tr>
                    <td className="inputLabel">name</td>
                    <td>
                        <input type="text" style={{width: "300px", display:"inline-block"}} placeholder='A descriptive name.'
                               className="userInput form-control"
                               value={treatplan.name} onChange={e=>this.updateItem('name', e.target.value)} />
                        <span className="inputLabel" style={{display:"inline-block", marginLeft:"150px"}}>editing for locale</span>
                        <LocaleSelector id="localeSelector" />
                        <OverlayTrigger trigger="click" placement="bottom" overlay={popoverLocale}>
                            <i className="mdi mdi-help glyphIcon" style={{display:"inline-block"}} />
                        </OverlayTrigger>
                    </td>
                </tr>

                <tr>
                    <td className="inputLabel">devices</td>
                    <td>
                        <input type="checkbox" checked={treatplan.supported_devices.includes("ox")} onChange={() => this.toggleSupportedDevice("ox")} /> OX &nbsp;&nbsp;&nbsp;
                        <input type="checkbox" checked={treatplan.supported_devices.includes("gx")} onChange={() => this.toggleSupportedDevice("gx")} /> GX &nbsp;&nbsp;&nbsp;
                        <input type="checkbox" checked={treatplan.supported_devices.includes("halo")} onChange={() => this.toggleSupportedDevice("halo")} /> Halo
                    </td>
                </tr>

                <tr>
                    <td className="inputLabel">summary</td>
                    <td>
                        <input type="text" style={{ width: "500px" }} placeholder='A short summary of the plan.'
                               className="userInput form-control form-control-sm"
                               value={treatplan.summary} onChange={e=>this.updateItem('summary', e.target.value)} />
                    </td>
                </tr>

                <tr>
                    <td className="inputLabel">description</td>
                    <td>
                        <textarea className="form-control form-control-sm" rows="3" style={{width:"700px"}} value={ treatplan.description }
                                  placeholder='A full description of the plan.'
                                  onChange={e=>this.updateItem('description', e.target.value)} />
                    </td>
                </tr>

                <tr>
                    <td className="inputLabel">image</td>
                    <td>
                        <img width="300" height="180" src={ treatplan.image_url } alt="Treatment plan" className="editorImage" />
                        <br />
                        <input type="file" onChange={ this.updateImage } />
                    </td>
                </tr>

                { this.renderDays(treatplan) }

                <tr><td /><td>
                    { this.renderDaysAdd() }
                </td></tr>

                <tr><td /><td><hr/></td></tr>
                { this.renderButtons() }

            </tbody>
            </table>
        )
    }
}
