import React, { Component } from 'react'
import { Dropdown, ButtonGroup, Button, Modal } from 'react-bootstrap'
import {numID} from '../common'
import {Player} from 'video-react'

export default class VideoPicker extends Component {

    constructor(props) {
        super(props)
        this.state = { selected: '', previewing: false }

        this.pickVideo = this.pickVideo.bind(this)
        this.previewVideo = this.previewVideo.bind(this)
        this.renderVideoName = this.renderVideoName.bind(this)
    }

    componentDidMount() {
        this.setState({ videos: this.props.videos, selected: this.props.selected })
    }

    pickVideo(video) {
        this.setState({ selected: video.video_id })
        this.props.onChange(video)
    }

    previewVideo(e) {
        e.stopPropagation()
        e.preventDefault()
        this.setState({ previewing: true })
    }

    endPreview() {
        this.setState({ previewing: false })
    }

    renderPreview() {
        if (!this.state.previewing) return null
        const video = this.state.videos.find(v=>v.video_id === this.state.selected)
        return (
            <Modal show={true} onHide={ ()=>this.endPreview() } centered >
                <Modal.Header>
                    <Modal.Title>{video.name} ({numID(video.video_id)})</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Player autoPlay={true}>
                        <source src={ video.url_mp4 } />
                    </Player>
                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
            </Modal>
        )
    }

    renderVideoName(id) {
        const video = this.state.videos.find(v => v.video_id === id)
        if (!video) return null
        return (
            <div>{video.video_id.replace(/\D/g, "")} - { video.name }</div>
        )
    }

    render() {
        if (!this.state.videos) return null
        return [
            <Dropdown as={ButtonGroup} >
                <Dropdown.Toggle split variant="secondary" size="sm" id="dropdown-video-split" />
                <Button variant="outline-secondary" size="sm"><b>{ this.renderVideoName(this.state.selected) }</b></Button>
                <Dropdown.Menu  style={{overflow: "scroll", height: "500px"}}>
                    {this.state.videos.sort((a, b) => a.video_id.replace(/\D/g, "") - b.video_id.replace(/\D/g, ""))
                        .map(video => <Dropdown.Item key={video.video_id}
                                                     onSelect={() => this.pickVideo(video)}>{this.renderVideoName(video.video_id)}</Dropdown.Item>)
                    }
                </Dropdown.Menu>
                <Button variant="secondary" id="dropdown-video-play" onClick={ this.previewVideo }>
                    <i className="mdi mdi-videocam glyphIcon" />
                </Button>
            </Dropdown>,
            this.renderPreview()
        ]
    }
}
