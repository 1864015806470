
export function numID(stringID) {
    return /_(\d+)$/.exec(stringID)[1]
}

// Promotional subscription period values for RevenueCat
export const PromoPeriods = {
    "daily": "24 Hours",
    "weekly": "1 week",
    "monthly": "1 month",
    "two_month": "2 months",
    "three_month": "3 months",
    "six_month": "6 months",
    "yearly": "1 year",
    "lifetime": "lifetime"
}

export const TreatmentTiers = {
    FREE: 'free',
    PREMIUM: 'premium'
}

export const UserTags = {
    CHINA: 'China'
}
