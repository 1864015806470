import React from 'react';
import SplitList from '../generic/SplitList'
import { numID } from '../common'
import { planGroupIsFlagged } from './PlanGroupIsFlagged'

export default class PlanGroupList extends SplitList {

    // SplitList functions

    getItems() {
        return global.api.fetchPlanGroups()
    }

    itemId(item) {
        return item.plan_group_id
    }

    itemSort(item) {
        if (this.state.sort === 'name') {
            return item.name
        } else {
            return parseInt(numID(item.plan_group_id))
        }
    }

    itemIsFlagged(planGroup) {
        return planGroupIsFlagged(planGroup)
    }

    renderHeader() {
        return [
            <tr><th style={{"width":"12px"}} /><th style={{"width":"60px"}} /><th /></tr>,
            <tr className="listHeaderRow">
                <td />
                <td className="listHeader" onClick={(e) => this.setState({ sort: 'plan_group_id' })}
                    style={{'paddingLeft': '30px'}}>{this.renderIDHeader()}</td>
                <td className="listHeader" onClick={(e) => this.setState({ sort: 'name' })}>{ this.renderNameHeader() }</td>
            </tr>
        ]
    }

    renderIDHeader() {
        if (this.state.sort === 'name') {
            return "id"
        } else {
            return ["id", <i className="mdi mdi-keyboard-arrow-down sortArrow"/>]
        }
    }

    renderNameHeader() {
        if (this.state.sort === 'name') {
            return ["name", <i className="mdi mdi-keyboard-arrow-down sortArrow"/>]
        } else {
            return "name"
        }
    }

    renderItem(item) {
        let idNum = numID(item.plan_group_id)
        return [
            <td key={'f'+idNum}>{ this.renderFlag(item) }</td>,
            <td key={item.plan_group_id} className="userData" style={{'paddingLeft': '30px'}}><b>{ idNum }</b></td>,
            <td key={item.name} className="userData">{ item.name }</td>
        ]
    }

    labelAddButton() {
        return "Add plan collection"
    }
}
