import React from 'react'
import '../css/App.css'
import SplitEditor from '../generic/SplitEditor'
import LocaleSelector from "../LocaleSelector"
import { numID } from '../common'

export default class FilterEditor extends SplitEditor {

    constructor(props) {
        super(props)
        this.state = { ...this.state, treatments: [] }
        this.fetchTreatments = this.fetchTreatments.bind(this)
        this.renderLocaleRow = this.renderLocaleRow.bind(this)
        this.localeChanged = this.localeChanged.bind(this)
        this.renderTreatments = this.renderTreatments.bind(this)
    }

    componentDidMount() {
        super.componentDidMount()
        this.fetchTreatments()
        global.localeManager.addListener(this)
    }

    componentWillUnmount() {
        global.localeManager.removeListener(this)
    }


    // SplitEditor functions

    getBlankItem() {
        return { default_name: '', locale_name: '', ...JSON.parse(JSON.stringify(global.Templates.filter)) }
    }

    getItemFromStore(id) {
        return global.api.fetchFilter(id)
    }

    parseLoadedItem(filter) {
        return { default_name: '', locale_name: '', ...filter }
    }

    deleteItemFromStore(id) {
        return global.api.deleteFilterId(id)
    }

    getNameFromItem(filter) {
        return filter.default_name
    }

    getIDFromItem(filter) {
        return filter.filter_id
    }

    onLoadItem(item) { }

    homeIcon() {
        return <i className="mdi mdi-filter-list homeIcon" />
    }

    homeText() {
        return "Pick a filter to modify, or Add Filter to add a new one."
    }

    homeSubtext() {
        return "Filters are attached to Treatments to control their filtering in the client display.  Use this editor to add filters, or to change their locale-specific display names."
    }

    itemsAreEqual(a,b) {
        if (!a && !b) return true
        if (!a || !b) return false
        if (JSON.stringify(a) === JSON.stringify(b)) return true
        return false
    }

    noErrors(errorList) {
        return true
    }

    itemNotOKToSave(filter) {
        const locale = global.localeManager.currentLocaleId()
        if (!filter.default_name) return "Enter a " + global.config.defaultLocale + " name for this filter."
        if (locale !== global.config.defaultLocale) {
            if (!filter.locale_name) return "Enter a " + locale + " name for this filter."
        }
        return false
    }

    itemNotOKToDelete(filter) {
        const filterName = filter.default_name
        let treatments = this.state.treatments.filter(t=>t.filters.includes(filterName))
        if (treatments.length > 0) {
            return "This filter is assigned to treatments " + treatments.map(t=>numID(t.treatment_id)).join(', ')
        }
        return false
    }

    createNewItemInStore(filter) {
        global.api.addFilter(filter).then(response => {
            this.saveSucceeded("New filter created.")
            this.loadItem(response.body.filter_id)
            this.reloadListPane()
        }).catch(e => {
            this.saveFailed("Error creating filter: " + e)
        })
    }

    updateItemInStore(filter) {
        global.api.updateFilter(filter).then(response => {
            this.saveSucceeded("Changes saved.")
            this.reloadListPane()
        }).then(() => {
            return this.fetchTreatments()
        }).catch(e => {
            this.saveFailed("Error updating filter: " + e)
        })
    }


    // Internal functions

    fetchTreatments() {
        return global.api.fetchTreatments().then((response) => {
            const allTreatments = response.body
            this.setState({ treatments: allTreatments })
        }).catch(e => {
            this.showAlert("danger", "Error fetching treatments: " + e)
        })
    }

    localeChanged(locale) {
        if (this.state.item && (this.state.item.filter_id || this.state.isNew)) {
            this.fetchTreatments().then(() => {
                this.reloadCurrentItem()
            })
        }
    }

    // Render functions

    renderTreatments(filter) {
        const treatments = this.state.treatments.filter(t => {
            return t.filters && (t.filters.includes(filter.default_name) || t.filters.includes(filter.locale_name))
        })
        if (treatments.length < 1) {
            return <span className="inputLabel">This filter is not attached to any treatments.</span>
        } else {
            return <ul style={{listStyleType:"none"}}>
                { treatments.map((t,i) => {
                    return <li key={i}><b>{t.name}</b> ({numID(t.treatment_id)}) </li>
                })}
            </ul>
        }
    }

    renderLocaleRow(filter) {
        const myLocale = global.localeManager.currentLocaleId()
        if (myLocale !== global.config.defaultLocale) {
            return (
                <tr>
                    <td className="inputLabel">{myLocale} name</td>
                    <td>
                        <input type="text" style={{ width: "300px" }} className="userInput form-control"
                               value={filter.locale_name} onChange={e => this.updateItem('locale_name', e.target.value)} />
                    </td>
                </tr>
            )
        }
    }

    renderEditor(filter) {
        return (
            <table cellPadding={8} style={{"marginTop":"24px", "tableLayout":"fixed"}} className="col-12"><tbody>
            <tr><th style={{"width":"150px"}} /><th /></tr>

            <tr>
                <td className="inputLabel">{ global.config.defaultLocale } name</td>
                <td>
                    <input type="text" style={{ width:"300px", display:"inline-block"}} className="userInput form-control"
                           value={filter.default_name} onChange={ (e)=>this.updateItem('default_name', e.target.value)} />
                    <span className="inputLabel" style={{display:"inline-block", marginLeft:"40px"}}>editing for locale</span>
                    <LocaleSelector id="localeSelector" />
                </td>
            </tr>

            { this.renderLocaleRow(filter) }

            <tr><td /><td><hr/></td></tr>
            <tr>
                <td className="inputLabel">treatments</td>
                <td>
                    <div className="referenceTable" style={{width:"400px",paddingTop:"15px",paddingBottom:"15px"}}>
                    { this.renderTreatments(filter) }
                    </div>
                </td>
            </tr>

            <tr><td /><td><hr/></td></tr>
            { this.renderButtons() }
            </tbody></table>
        )
    }
}
