import React from 'react'
import SplitList from '../generic/SplitList'

export default class LocaleList extends SplitList {

    constructor(props) {
        super(props)
        this.setState({ sort: 'locale' })
        this.getItems = this.getItems.bind(this)
    }


    // SplitList functions

    getItems() {
        return global.api.fetchLocales()
    }

    itemId(item) {
        return item.locale
    }

    itemSort(item) {
        if (this.state.sort === 'name') {
            return item.name
        } else {
            return item.locale
        }
    }

    renderHeader() {
        return [
            <tr><th style={{"width":"100px"}} /><th /></tr>,
            <tr className="listHeaderRow">
                <td className="listHeader" onClick={ (e)=>this.setState({ sort: 'locale' })} style={{ 'paddingLeft': '30px' }}>{ this.renderIDHeader() }</td>
                <td className="listHeader" onClick={ (e)=>this.setState({ sort: 'name' })}>{ this.renderNameHeader() }</td>
            </tr>
        ]
    }

    renderIDHeader() {
        if (this.state.sort === 'name') {
            return "id"
        } else {
            return [ "id", <i className="mdi mdi-keyboard-arrow-down sortArrow" /> ]
        }
    }

    renderNameHeader() {
        if (this.state.sort === 'name') {
            return [ "name", <i className="mdi mdi-keyboard-arrow-down sortArrow" /> ]
        } else {
            return "name"
        }
    }

    renderItem(item) {
        return [
            <td key={item.locale} className="userData" style={{ 'paddingLeft': '30px' }}><b>{item.locale}</b></td>,
            <td key={item.name} className="userData">{item.name}</td>
        ]
    }

    labelAddButton() {
        return "Add locale"
    }
}
