import React from 'react'
import SplitPane from '../generic/SplitPane'
import FilterList from './FilterList'
import FilterEditor from './FilterEditor'

export default class FilterPane extends SplitPane {

    constructor(props) {
        super(props)
        this.renderList = this.renderList.bind(this)
        this.renderEdit = this.renderEdit.bind(this)
    }


    // SplitPane functions

    renderList() {
        return <FilterList onItemClick={ this.onItemClick } updateCount={ this.state.updateCount } />
    }

    renderEdit() {
        return <FilterEditor onEditorChange={ this.onEditorChange } item={ this.state.editing } selectCount={ this.state.selectCount } />
    }
}
