
export default class LocaleManager {

    constructor() {
        this.localeKey = "locale"
        this.listeners = []
        const storedLocale = JSON.parse(localStorage.getItem(this.localeKey))
        if (storedLocale) {
            console.log("loaded stored locale: " + JSON.stringify(storedLocale))
            this.currentLocale = storedLocale
        } else {
            this.currentLocale = { locale: "en_US", name: "US English", aliases: [] }
        }
        this.addListener = this.addListener.bind(this)
        this.removeListener = this.removeListener.bind(this)
        this.setLocale = this.setLocale.bind(this)
    }

    setLocale(locale) {
        localStorage.setItem(this.localeKey, JSON.stringify(locale))
        this.currentLocale = locale
        this.listeners.forEach(listener => {
            listener.localeChanged(locale)
        })
    }

    currentLocaleId() {
        return this.currentLocale.locale
    }

    addListener(listener) {
        this.listeners.push(listener)
    }

    removeListener(listener) {
        for (let i = this.listeners.length - 1; i >= 0; i--) {
            if (this.listeners[i] === listener) {
                this.listeners.splice(i, 1);
            }
        }
    }
}
