import React from 'react'
import DnDSplitList from '../generic/DnDSplitList'
import { TableCell, TableHead, TableRow } from '@material-ui/core'
import { numID } from '../common'

export default class FilterList extends DnDSplitList {

    // constructor(props) {
    //     super(props)
    //     this.state = { ...this.state, sort: 'id' }
    //     // this.renderIDHeader = this.renderIDHeader.bind(this)
    //     // this.renderNameHeader = this.renderNameHeader.bind(this)
    // }


    // SplitList functions

    getItems() {
        return global.api.fetchFilters()
    }

    itemId(item) {
        return item.filter_id
    }

    itemSort(item) {
        if (this.state.sort === 'name') {
            return item.default_name
        } else {
            return parseInt(/(\d+)/.exec(item.filter_id)[1])
        }
    }

    updateItem(itemFilter, index, cb) {
        itemFilter.order = index
            global.api.updateFilter(itemFilter).then(response => {
                cb()
            }).catch(e => {
            })
    }

    updateItemsOrder(filters) {
        this.setState({ loading: true })
        let requests = filters.map((item, index) => {
            return new Promise((resolve) => {
                this.updateItem(item, index, resolve)
            });
        })
        
        Promise.all(requests).then(() => this.setState({ loading: false }));
  
    }

    labelAddButton() {
        return "Add filter"
    }


    // Render functions

    renderHeader() {
        return (<TableHead>
            <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Name</TableCell>
            </TableRow>
        </TableHead>)
    }

    // renderIDHeader() {
    //     if (this.state.sort === 'name') {
    //         return "id"
    //     } else {
    //         return [ "id", <i className="mdi mdi-keyboard-arrow-down sortArrow" /> ]
    //     }
    // }

    // renderNameHeader() {
    //     if (this.state.sort === 'name') {
    //         return [ "default name", <i className="mdi mdi-keyboard-arrow-down sortArrow" /> ]
    //     } else {
    //         return "default name"
    //     }
    // }

    renderItem(item) {
        let idNum = numID(item.filter_id)
        return [
            <td key={'f'+idNum}>{ this.renderFlag(item) }</td>,
            <td key={item.filter_id} className="userData" style={{'paddingLeft':'30px'}}><b>{ item.filter_id }</b></td>,
            <td key={item.default_name} className="userData">{ item.default_name }</td>
        ]
    }

}
