import React from 'react'
import SplitList from '../generic/SplitList'
import {numID} from '../common'
import {firmwareIsFlagged} from './FirmwareIsFlagged'

export default class FirmwareList extends SplitList {

    constructor(props) {
        super(props)
        this.state = {...this.state, sort: 'active' }
        this.getItems = this.getItems.bind(this)
        this.renderIDHeader = this.renderIDHeader.bind(this)
        this.renderNameHeader = this.renderNameHeader.bind(this)
        this.renderActiveHeader = this.renderActiveHeader.bind(this)
    }


    // SplitList functions

    getItems() {
        return global.api.fetchFirmwares()
    }

    itemId(item) {
        return item.firmware_id
    }

    itemSort(item) {
        if (this.state.sort === 'name') {
            return item.name || ''
        } else if (this.state.sort === 'active') {
            if (item.active) return 0
            else return 1
        } else {
            return parseInt(item.firmware_id)
        }
    }

    itemIsFlagged(firmware) {
        return firmwareIsFlagged(firmware)
    }

    renderHeader() {
        return [
            <tr><th style={{"width":"12px"}} /><th style={{"width":"60px"}} /><th /><th style={{"width":"60px"}} /></tr>,
            <tr className="listHeaderRow">
                <td />
                <td className="listHeader" onClick={ (e)=>this.setState({ sort: 'id' })} style={{ 'paddingLeft': '30px' }}>{ this.renderIDHeader() }</td>
                <td className="listHeader" onClick={ (e)=>this.setState({ sort: 'name' })}>{ this.renderNameHeader() }</td>
                <td className="listHeader" onClick={ (e)=>this.setState({ sort: 'active' })}>{ this.renderActiveHeader() }</td>
            </tr>
        ]
    }

    renderIDHeader() {
        if (this.state.sort === 'id') {
            return [ "id", <i className="mdi mdi-keyboard-arrow-down sortArrow" /> ]
        } else {
            return "id"
        }
    }

    renderNameHeader() {
        if (this.state.sort === 'name') {
            return [ "name", <i className="mdi mdi-keyboard-arrow-down sortArrow" /> ]
        } else {
            return "name"
        }
    }

    renderActiveHeader() {
        if (this.state.sort === 'active') {
            return [ "active", <i className="mdi mdi-keyboard-arrow-down sortArrow" /> ]
        } else {
            return "active"
        }
    }

    renderActiveIcon(active) {
        if (active) {
            return <i className="mdi mdi-check-circle statusIcon statusIconGreen" />
        } else {
            return <i className="mdi mdi-error statusIcon statusIconYellow" />
        }
    }

    renderItem(item) {
        return [
            <td key={'f'+item.firmware_id}>{ this.renderFlag(item) }</td>,
            <td key={item.firmware_id} className="userData" style={{ 'paddingLeft': '30px'}}><b>{ numID(item.firmware_id) }</b></td>,
            <td key={item.name+item.firmware_id} className="userData">{item.name} </td>,
            <td key={item.active+item.firmware_id}>{ this.renderActiveIcon(item.active) }</td>
        ]
    }

    labelAddButton() {
        return "Add firmware"
    }

}
