import React from 'react'
import '../css/App.css'
import SplitEditor from '../generic/SplitEditor'
import LocaleSelector from '../LocaleSelector'
import {OverlayTrigger, Popover} from "react-bootstrap"
import SortableFeaturedList from "./SortableFeaturedList"
import { numID } from '../common'

export default class FeaturedEditor extends SplitEditor {

    constructor(props) {
        super(props)
        this.treatmentList = null
        this.state = { treatments: [], featured: [], originalState: { featured: [] } }

        this.getItemsForList = this.getItemsForList.bind(this)
        this.onListItemsChanged = this.onListItemsChanged.bind(this)
        this.treatmentSelected = this.treatmentSelected.bind(this)
        this.updateItem = this.updateItem.bind(this)
        this.loadFeatured = this.loadFeatured.bind(this)
    }

    componentDidMount() {
        this.setState({ loading: true, isBlank: false })
        global.localeManager.addListener(this)
        this.loadFeatured()
    }

    componentWillUnmount() {
        global.localeManager.removeListener(this)
    }

    loadFeatured() {
        this.setState({ loading: true })
        let allTreatments = []
        global.api.fetchTreatments().then((response) => {
            allTreatments = response.body
            return global.api.fetchFeatured()
        }).then((response) => {
            let featured = response.body.featured.map(f => {
                let name
                if (f.id === 'NEXTUP') name = 'Next Up'
                else if (f.id === 'LASTSYNC') name = 'Last Synced'
                else name = allTreatments.find(t => t.treatment_id === f.id).name
                return {
                    id: f.id,
                    label: f.label,
                    name
                }
            })
            this.setState({
                featured: featured,
                treatments: allTreatments,
                originalState: {
                    featured: JSON.parse(JSON.stringify(featured))
                },
                loading: false
            })
        }).catch(e => {
            this.showAlert("danger", "FATAL: Error fetching treatments: " + e)
        })
    }


    // SplitEditor functions

    actuallyCancel(actually) {
        this.setState({ confirmText: "", confirmYes: "" })
        if (actually) {
            this.setState({
                nextupLabel: this.state.originalState.nextupLabel,
                lastsyncLabel: this.state.originalState.lastsyncLabel,
                featured: this.state.originalState.featured
            })
            this.showAlert("success", "Temporary changes reverted.")
        }
    }

    hasUnsavedChanges() {
        const newFeatured = JSON.stringify(this.state.featured)
        const oldFeatured = JSON.stringify(this.state.originalState.featured)
        return newFeatured !== oldFeatured
    }

    getBlankItem() { return {} }

    getItemFromStore(id) { return {} }

    itemNotOKToDelete(item) { }

    deleteItemFromStore(id) { }

    getNameFromItem(item) { }

    getIDFromItem(item) { }

    onLoadItem(item) { }

    reloadCurrentItem() { }

    reloadListPane() { }

    homeIcon() { }

    homeText() { }

    homeSubtext() { }

    itemsAreEqual(a, b) { }

    noErrors(errorList) { 
        return true
    }

    itemNotOKToSave(item) {
        // we don't have an 'item' so just check the whole state
        if (this.state.nextupLabel === "") return "Enter a 'next up' label for this locale."
        if (this.state.lastsyncLabel === "") return "Enter a 'last synced' label for this locale."
        let fail = false
        this.state.featured.forEach(t => {
            if (t.label === "") fail = "Enter a featured label for " + t.name + "."
        })
        return fail
    }

    createNewItemInStore(item) { }

    updateItemInStore(item) {
        // we don't have an 'item' so just save the whole state
        global.api.updateFeatured({
            featured: this.state.featured
        }).then(response => {
            this.showAlert("success", "Changes saved.")
            this.setState({
                loading: false,
                originalState: {
                    featured: this.state.featured
                }
            })
        }).catch(e => {
            this.saveFailed("Error updating featured treatments: " + e)
        })
    }


    itemIsFlagged(item) { return false }

    localeChanged(locale) {
        this.loadFeatured()
    }

    updateItem(field, value) {
        let newState = this.state
        newState[field] = value
        this.clearAlert()
        this.setState(newState)
    }


    // Internal functions

    getItemsForList() {
        // state.featured is a list of metadata objs { id: "treatment123", label: "localized label" }
        return this.state.featured || []
    }

    onListItemsChanged(items) {
        this.setState({ featured: items })
        this.clearAlert()
    }

    treatmentSelected(e) {
        let treatment
        if (e.target.value === 'Next Up') {
            treatment = {
                treatment_id: 'NEXTUP',
                name: 'Next Up',
                label: 'Next Up'
            }
        } else if (e.target.value === 'Last Synced') {
            treatment = {
                treatment_id: 'LASTSYNC',
                name: 'Last Synced',
                label: 'Last Synced'
            }
        } else {
            treatment = this.state.treatments.find(t => this.nameAndIdForTreatment(t) === e.target.value)
        }
        if (treatment) {
            if (!this.state.featured || !this.state.featured.some(t => t.id === treatment.treatment_id )) {
                this.treatmentList.addItem({
                    id: treatment.treatment_id,
                    name: treatment.name,
                    label: treatment.label || "Featured"
                })
            } else {
                this.showAlert("warning", treatment.name + " is already a featured treatment.")
            }
        }
    }


    // Render functions

    renderDeleteButton() { }

    renderInDefaultLocale(element) {
        if (global.localeManager.currentLocaleId() === global.config.defaultLocale) return element
    }

    renderEditor(item) {

        const popoverLocale = (
            <Popover id="localeHelp" className="helpPopover" >
                <Popover.Content>
                    Locale setting affects the labels for each featured treatment.
                    To maintain consistency, you can only add or remove featured treatments while in the default (en_US) locale.
                </Popover.Content>
            </Popover>
        )

        return (
            <table cellPadding={8} style={{"marginTop":"24px", "tableLayout":"fixed"}} className="col-12"><tbody>
            <tr><th style={{"width":"150px"}} /><th /><th /></tr>
            <tr>
                <td className="inputLabel"></td>
                <td colSpan={2}>
                    <span className="inputLabel" style={{display:"inline-block", marginLeft:"150px"}}>editing for locale</span>
                    <LocaleSelector id="localeSelector" />
                    <OverlayTrigger trigger="click" placement="bottom" overlay={popoverLocale}>
                        <i className="mdi mdi-help glyphIcon" style={{display:"inline-block"}} />
                    </OverlayTrigger>
                </td>
            </tr>

            <tr>
                <td className="inputLabel">featured</td>
                <td className="col-8">
                    <SortableFeaturedList onChange={(items) => this.onListItemsChanged(items)}
                                          items={this.getItemsForList()} ref={r => { this.treatmentList = r }}/>
                    { this.renderInDefaultLocale(
                        <select className="treatmentSelector" onChange={this.treatmentSelected}
                                value={'Choose a treatment to add to the featured list.'}>
                            <option key={0}>Choose a treatment to add to the featured list.</option>
                            <option key={1}>Next Up</option>
                            <option key={2}>Last Synced</option>
                            { this.state.treatments
                                .sort( (a, b) => parseInt(numID(a.treatment_id)) > parseInt(numID(b.treatment_id)) ? 1 : -1 )
                                .map(( t, i) => <option key={i+3}>{ this.nameAndIdForTreatment(t) }</option> ) }
                        </select>
                    )}
                </td>
                <td className="col-2">
                    { this.renderInDefaultLocale(
                        <i className="mdi mdi-delete-forever trashIcon" />
                    )}
                </td>
            </tr>

            <tr><td /><td><hr/></td></tr>
            { this.renderButtons() }
            </tbody></table>
        )
    }
}
