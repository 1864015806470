import React from 'react'
import '../css/App.css'
import '../css/Video.css'
import {Button, ProgressBar, Row, Col} from 'react-bootstrap'
import SplitEditor from '../generic/SplitEditor'
import S3UploadInput from './S3UploadInput'
import LocaleSelector from '../LocaleSelector'
import sparkMD5 from 'spark-md5'
import sha256 from 'js-sha256'
import uuid from 'uuid/v4'
import {Player,ControlBar} from 'video-react'
import '../css/video-react.css'
import {videoIsFlagged} from './VideoIsFlagged'
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Popover from 'react-bootstrap/Popover'
import asmCrypto from 'asmcrypto-lite'
const moment = require('moment')

export default class VideoEditor extends SplitEditor {

    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
            uploadProgress: 0,
            upload_filename: "",
            filename: "",
            playingSegment: 0,
            strings: [],
            subtitleContent: '',
            subtitleFileKey: 0,
        }
        this.uploader = null
        this.refreshTask = null
        this.player = null

        this.renderEditor = this.renderEditor.bind(this)
        this.onUploadStart = this.onUploadStart.bind(this)
        this.onUploadError = this.onUploadError.bind(this)
        this.onUploadProgress = this.onUploadProgress.bind(this)
        this.onUploadFinish = this.onUploadFinish.bind(this)
        this.renderUploader = this.renderUploader.bind(this)
        this.cancelUpload = this.cancelUpload.bind(this)
        this.setUploadFilename = this.setUploadFilename.bind(this)
        this.copyToClipboard = this.copyToClipboard.bind(this)
        this.renderIfNotUploading = this.renderIfNotUploading.bind(this)
        this.renderIfNotNew = this.renderIfNotNew.bind(this)
        this.renderIfNotMissing = this.renderIfNotMissing.bind(this)
        this.renderIfFileChosen = this.renderIfFileChosen.bind(this)
        this.onFileSelect = this.onFileSelect.bind(this)
        this.renderSegments = this.renderSegments.bind(this)
        this.addSegment = this.addSegment.bind(this)
        this.removeSegment = this.removeSegment.bind(this)
        this.removeSubtitle = this.removeSubtitle.bind(this)
        this.jumpToSegment = this.jumpToSegment.bind(this)
        this.playSegment = this.playSegment.bind(this)
        this.onPlayerProgress = this.onPlayerProgress.bind(this)
        this.reloadItemStatus = this.reloadItemStatus.bind(this)
        this.startUpload = this.startUpload.bind(this)
        this.fetchStrings = this.fetchStrings.bind(this)
        this.localeChanged = this.localeChanged.bind(this)
        this.parseFile = this.parseFile.bind(this)
        this.updateSubtitleFile = this.updateSubtitleFile.bind(this)
        this.updateSubtitleFileContent = this.updateSubtitleFileContent.bind(this)
        this.uploadSubtitleFile = this.uploadSubtitleFile.bind(this)
    }

    componentDidMount() {
        super.componentDidMount()
        this.fetchStrings()
        global.localeManager.addListener(this)
    }

    componentWillUnmount() {
        global.localeManager.removeListener(this)
        this.uploader && this.uploader.abort()
        this.refreshTask && clearTimeout(this.refreshTask)
    }

    fetchStrings() {
        global.api.fetchStrings().then((response) => {
            this.setState({ strings: response.body })
        })
    }

    // SplitEditor functions

    getBlankItem() {
        this.uploader && this.uploader.abort()
        return JSON.parse(JSON.stringify(global.Templates.video))
    }

    getItemFromStore(id) {
        this.uploader && this.uploader.abort()
        return global.api.fetchVideo(id)
    }

    parseLoadedItem(video) {
        let segments = video.segments || []
        segments = segments.map((s,i) => {
            s.start_seconds = this.timeNumToText(s.start_seconds)
            return s
        })
        this.updateSubtitleFileContent(video)
        video.segments = segments
        return video
    }

    deleteItemFromStore(id) {
        return global.api.deleteVideoId(id)
    }

    getNameFromItem(video) {
        return video.name
    }

    getIDFromItem(video) {
        return video.video_id
    }

    onLoadItem(video) {
        this.refreshTask && clearTimeout(this.refreshTask)
        this.setState({ uploadProgress: 0, upload_filename: '', filename: '' })
    }

    homeIcon() {
        return <i className="mdi mdi-videocam homeIcon" />
    }

    homeText() {
        return "Select a video to modify/re-upload, or click Add Video to create and upload a new one."
    }

    homeSubtext() {
        return "This list shows all videos both pending and complete.  Click a video to check its status or re-upload for reprocessing and replacement.  Once you've added a video here, go to the Treatments Editor to attach it to a particular treatment."
    }

    itemsAreEqual(a,b) {
        if (!a && !b) return true
        if (!a || !b) return false
        return JSON.stringify(a) === JSON.stringify(b)
    }

    noErrors(errorList) {
        return true
    }

    itemNotOKToSave(video) {
        if (!video.name) return "Enter a title for this video."
        return false
    }

    itemNotOKToDelete(video) {
        let treatment = null
        this.state.strings.forEach(literal => {
            if (literal.value === video.video_id) {
                const match = /^treatment_(\d+)/.exec(literal.id)
                if (match) treatment = match[1]
            }
        })
        if (treatment)
            return "This video is assigned to treatment " + treatment + ".  Pick a different video for that treatment to delete this video."
        return false
    }

    prepVideoForSave(video) {
        let saveVideo = JSON.parse(JSON.stringify(video))
        saveVideo.segments = saveVideo.segments.map((s,i) => {
            s.start_seconds = this.timeTextToNum(s.start_seconds)
            s.name = this.encodeStringId(s.name, video.video_id, "segment_" + i)
            return s
        })
        saveVideo.url_srt = this.encodeStringId(video.url_srt || '', video.video_id, "url_srt")
        return saveVideo
    }

    createNewItemInStore(video) {
        global.api.addVideo(this.prepVideoForSave(video)).then(response => {
            this.saveSucceeded("New video uploaded!")
            this.loadItem(response.body.video_id)
            this.reloadListPane()
        }).catch(e => {
            this.saveFailed("Error uploading video: " + e)
        })
    }

    updateItemInStore(video) {
        global.api.updateVideo(this.prepVideoForSave(video)).then(response => {
            this.saveSucceeded("Changes saved.")
            this.reloadListPane()
        }).catch(e => {
            this.saveFailed("Error updating video: " + e)
        })
    }

    setUploadFilename(filename) {
        const match = /^(.+)\.(\w+)$/.exec(filename)
        if (!match) {
            this.showAlert("warning", "Invalid video filename!")
            return
        }
        const name = match[1]
        const ext = match[2].toLowerCase()
        if (!this.state.item.name) this.updateItem('name', name)
        const uniqueName = global.config.env + '_' + uuid() + '.' + ext
        this.setState({ upload_filename: filename, filename: uniqueName })
        this.forceUpdate()
        return uniqueName
    }

    onUploadStart(file, next) {
        const lowerfile = file.name.toLowerCase()
        if (!lowerfile.includes('.') || !(
            lowerfile.endsWith('.mpg') || lowerfile.endsWith('.mp4') || lowerfile.endsWith('.m4v') || lowerfile.endsWith('mov') || lowerfile.endsWith('m2ts')
        )) {
            this.showAlert("warning", "Invalid video file.  Please upload mpg, mp4, m4v, mov, or m2ts format.")
            return
        }
        this.showAlert("success", "Uploading...")
        next(file)
    }

    onUploadError(e) {
        this.showAlert("danger", "Error uploading video: " + e)
        this.setState({ uploadProgress: 0 })
    }

    onUploadFinish(e) {
        this.showAlert("success", this.state.item.name + " uploaded!")
        const updatedItem = {
            ...this.state.item,
            filename: this.state.filename,
            upload_filename: this.state.upload_filename,
            status: 'ingesting',
            status_detail: '',
            status_change_time: Date.now(),
            segments: [ { name: 'intro', startSeconds: 0, endSeconds: 1 } ]
        }
        this.setState({ item: updatedItem, alertType: "", alertText: "", uploadProgress: 0, filename: '', upload_filename: ''})
        this.state.isNew ? this.createNewItemInStore(updatedItem) : this.updateItemInStore(updatedItem)
        this.reloadCurrentItem()
        this.reloadListPane()
    }

    onUploadProgress(e) {
        this.setState({ uploadProgress: Math.ceil(e) })
    }

    onFileSelect() {
        this.forceUpdate()
    }

    startUpload(e) {
        this.setState({ uploadProgress: 0.1 })
        this.uploader.uploadFile()
    }

    cancelUpload(e) {
        e.stopPropagation()
        e.preventDefault()
        this.uploader.abort()
    }

    copyToClipboard(e, text) {
        e.preventDefault()
        e.stopPropagation()
        navigator.clipboard.writeText(text)
        this.showAlert("success", "URL copied to clipboard.")
    }

    updateSegment(isTime, i, value) {
        const segments = this.state.item.segments
        if (isTime) segments[i].start_seconds = value
        else segments[i].name = value
        this.updateSegments(segments)
    }

    updateSegments(segments) {
        segments = segments.sort((a,b) => {
            const timeA = this.timeTextToNum(a.start_seconds)
            const timeB = this.timeTextToNum(b.start_seconds)
            return (timeA < timeB) ? -1 : (timeA > timeB) ? 1 : 0
        })
        segments = segments.map((s,i) => {
            if (i === segments.length - 1) {
                s.end_seconds = Math.ceil(this.player.getState().player.duration)
            } else {
                s.end_seconds = this.timeTextToNum(segments[i+1].start_seconds)
            }
            return s
        })
        this.updateItem('segments', segments)
    }

    removeSegment(i) {
        if (global.localeManager.currentLocaleId() !== global.config.defaultLocale) {
            this.showAlert("warning", "Switch to the " + global.config.defaultLocale + " locale to remove video segments.")
            return
        }
        const segments = this.state.item.segments
        segments.splice(i, 1)
        this.updateSegments(segments)
    }

    removeSubtitle() {
        this.updateItem('url_srt', '')
        this.setState({ subtitleContent: '', subtitleFileKey: this.state.subtitleFileKey + 1 })
    }

    timeNumToText(timeNum) {
        const min = Math.floor(timeNum / 60)
        const sec = Math.floor(timeNum - (min * 60))
        return min.toString() + ":" + (sec > 9 ? sec.toString() : "0" + sec.toString())
    }

    timeTextToNum(timeText) {
        const match = /(\d+):(\d+)/.exec(timeText)
        if (!match) return 0
        return parseInt(match[1]) * 60 + parseInt(match[2])
    }

    addSegment() {
        if (global.localeManager.currentLocaleId() !== global.config.defaultLocale) {
            this.showAlert("warning", "Switch to the " + global.config.defaultLocale + " locale to add video segments.")
            return
        }
        let segments = this.state.item.segments
        if (typeof(segments) != 'object') segments = []
        const playbackTime = this.timeNumToText(Math.floor(this.player.getState().player.currentTime))
        if (segments.some(s => s.start_seconds === playbackTime)) {
            this.showAlert("warning", "Can't make a new segment here -- another segment starts here.  Move the playback head to a different position.")
            return
        }
        segments.push({ start_seconds: playbackTime, end_seconds: 0, name: "" })
        this.updateSegments(segments)
    }

    resetSegmentTime(index) {
        if (index === 0) return
        const playbackSec = Math.floor(this.player.getState().player.currentTime)
        const playbackTime = this.timeNumToText(playbackSec)
        let segments = this.state.item.segments
        if (segments.some(s => s.start_seconds === playbackTime)) {
            this.showAlert("warning", "Can't set '" + segments[index].name + "' to start here -- another segment already starts here.  Move the playback head to a different position.")
        } else if (segments[index].end_seconds <= playbackSec) {
            this.showAlert("warning", "Can't set '" + segments[index].name + "' to start here -- you're past its endpoint.  Move the playback head back.")
        } else if (index > 1 && segments[index-2].end_seconds >= playbackSec) {
            this.showAlert("warning", "Can't set '" + segments[index].name + "' to start here -- this is before the previous segment.  Move the playback head forward.")
        } else {
            segments[index].start_seconds = playbackTime
            this.updateSegments(segments)
            this.showAlert("success", "Segment '" + segments[index].name + "' start set to playback head.")
        }
    }

    jumpToSegment(segment, i) {
        this.player.seek(parseInt(this.timeTextToNum(segment.start_seconds)))
        this.player.pause()
        this.setState({ playingSegment: i })
    }

    playSegment(segment, i) {
        this.jumpToSegment(segment)
        this.player.play()
        this.setState({ playingSegment: i })
    }

    onPlayerProgress() {
        if (!this.player) return
        const playerSec = Math.floor(this.player.getState().player.currentTime)
        let index = 0
        this.state.item.segments.forEach((s, i) => {
            if (this.timeTextToNum(s.start_seconds) <= playerSec && s.end_seconds >= playerSec) {
                index = i
            }
        })
        this.setState({ playingSegment: index })
    }

    reloadItemStatus() {
        this.getItemFromStore(this.state.item.video_id).then((response) => {
            if (response.body.status === 'live') {
                this.reloadCurrentItem()
            } else {
                let item = this.state.item
                item.status = response.body.status
                item.status_detail = response.body.status_detail
                item.status_change_time = response.body.status_change_time
                let original = this.state.originalItem
                original.status = item.status
                original.status_detail = item.status_detail
                original.status_change_time = item.status_change_time
                this.setState({item: item, originalItem: original})
            }
        }).catch( e => console.error(e) )
    }

    localeChanged(locale) {
        if (this.state.item && (this.state.item.video_id || this.state.isNew)) {
            this.reloadCurrentItem()
        }
    }

    updateSubtitleFile(event) {
        const reader = new FileReader()
        const file = event.target.files[0]
        reader.onload = (loadEvent) => {
          this.parseFile(loadEvent.target.result)
        }
        reader.readAsText(file)
        this.showAlert("success", "Uploading subtitles...")
        this.uploadSubtitleFile(event)
    }

    uploadSubtitleFile(event) {
        const file = event.target.files[0]
        const extension = this.getSubtitleType(file.name)
        if (!extension) {
            this.showAlert("danger", "That does not appear to be a subtitle file.  Please try again.")
            return
        }
        const contentType = this.mimeTypeForExtension(extension)
        const self = this  // save a reference to this class that we can use in our callbacks
        const reader = new FileReader()
        reader.onload = function (event) {
            const contents = event.target.result
            const digest = asmCrypto.SHA256.hex(contents)
            const key = digest + extension
            global.api.validateImageUpload(key).then(data => {
                const formData = data.body
                formData.formInputs['Content-Type'] = contentType
                return global.api.uploadImage(file, formData)
            }).then(_ => {
                self.updateItem('url_srt', global.config.subtitleCdnUrl + key)
                self.showAlert("success", "Subtitle file uploaded.")
            }).catch(error => {
                self.showAlert("danger", 'Error uploading subtitle file: ' + error)
            })
        }
        let thisEditor = this
        reader.onerror = function (event) {
            thisEditor.showAlert("danger", "File could not be read! Code " + event.target.error.code)
        }
        reader.readAsArrayBuffer(file)
    }

    parseFile(content) {
        this.setState({ subtitleContent: content })
    }

    // Render functions

    renderStatusIcon(status) {
        if (status === 'ingesting') {
            return <i className="mdi mdi-forward statusIcon statusIconYellow" key="statusIcon1" />
        } else if (status === 'processing') {
            return [ <i className="mdi mdi-forward statusIcon statusIconYellow" key="statusIcon1" />, <i className="mdi mdi-forward statusIcon statusIconYellow" key="statusIcon2" /> ]
        } else if (status === 'deploying') {
            return [ <i className="mdi mdi-forward statusIcon statusIconYellow" key="statusIcon1" />, <i className="mdi mdi-forward statusIcon statusIconYellow" key="statusIcon2" />, <i className="mdi mdi-forward statusIcon statusIconYellow" key="statusIcon3" /> ]
        } else if (status === 'live') {
            return <i className="mdi mdi-check-circle statusIcon statusIconGreen" key="statusIcon1" />
        } else if (status === 'failed') {
            return <i className="mdi mdi-error statusIcon statusIconRed" key="statusIcon1" />
        } else {
            return <span key="statusIcon1">?</span>
        }
    }

    renderStatus(status, detail, time) {
        if (status !== 'live' && status !== 'failed') {
            this.refreshTask && clearTimeout(this.refreshTask)
            this.refreshTask = setTimeout(() => {
                if (this.state.item.video_id && this.state.uploadProgress <= 0) {
                    this.reloadItemStatus()
                    this.reloadListPane()
                }
            }, 5000)
        }
        return <div>
            <span style={{"display":"inline-block"}}>{ this.renderStatusIcon(status) }</span>&nbsp;&nbsp;
            <span className={status+"Status statusLabel"}>{status}</span>&nbsp;&nbsp;
            <span className="statusDetail">{detail ? '(' + detail + ')' : ''}</span>&nbsp;&nbsp;
            <span className="statusInterval">{(time > 0) && (moment.duration(Date.now() - time).humanize() + " ago")}</span>
        </div>
    }

    renderSourceFile(video) {
        const originalName = video.upload_filename || 'none'
        const dimensions = (video.src_width || '?') + ' x ' + (video.src_height || '?')
        return originalName + " (" + dimensions + ")"
    }

    updateSubtitleFileContent(video) {
        this.setState({ subtitleContent:  "" })
        const self = this
        if (video.url_srt) {
            global.api.getSubtitleFile(video.url_srt)
                .then(response => self.setState({ subtitleContent: response.text } ))
                .catch(err => self.showAlert('danger', `Subtitle file specified for this locale could not be loaded`))
        }
    }

    renderIfFileChosen(element) {
        if (this.uploader && this.uploader.hasChosenFile()) return element
    }

    renderUploader(progress, placeholder, acceptType) {
        if (progress < 1) return (
            <tr>
                <td className="inputLabel">{placeholder}</td>
                    <td><S3UploadInput
                        evaporateOptions={{
                            aws_key: global.config.awsKey,
                            sendCanonicalRequestToSignerUrl: true,
                            awsRegion: global.config.awsRegion,
                            bucket: global.config.videoBucket,
                            computeContentMd5: true,
                            cryptoMd5Method: data => btoa(sparkMD5.ArrayBuffer.hash(data, true)),
                            cryptoHexEncodedHash256: sha256,
                            timeUrl: global.config.apiUrl + "get_time"
                        }}
                        signingUrl={ global.config.apiUrl + "sign_auth" }
                        accept={ acceptType }
                        scrubFilename={ this.setUploadFilename }
                        preprocess={ this.onUploadStart }
                        onProgress={ this.onUploadProgress }
                        onError={ this.onUploadError }
                        onFinish={ this.onUploadFinish }
                        onFileSelect={ this.onFileSelect }
                        autoUpload={false}
                        contentDisposition="auto"
                        uploadRequestHeaders={{ 'x-amz-acl': 'private' }}
                        ref={r => { if (r !== null) this.uploader = r }}
                    />
                    { this.renderIfFileChosen(<Button variant="primary" className="btn-sm" onClick={ this.startUpload }>Upload</Button>) }
                </td>
            </tr>
        )

        else return (
            <tr>
                <td className="inputLabel">upload</td>
                <td>
                    <Row>
                        <Col className="col-6">
                            <ProgressBar animated now={progress} label={`${progress}%`}/>
                        </Col>
                        <Col className="col-4">
                            <Button variant="warning" className="btn-sm" onClick={ this.cancelUpload }>Cancel upload</Button>
                        </Col>
                    </Row>
                    <Row>
                        <span className="helpText">Do not click away from this page, or your upload will be aborted!</span>
                    </Row>
                </td>
            </tr>
        )
    }

    renderCopyButton(text) {
        return (
            <i className="mdi mdi-content-copy copyIcon" onClick={(e)=>this.copyToClipboard(e,text)}/>
        )
    }

    renderIfNotUploading(elements, alt) {
        if (this.state.uploadProgress <= 0) return elements
        else return alt
    }

    renderIfNotNew(elements) {
        if (!this.state.isNew) return elements
    }

    renderIfNotMissing(elements) {
        if (this.state.item && this.state.item.url_mp4) return elements
    }

    resetSegmentIcon(i) {
        if (i === 0) {
            return "mdi mdi-restore copyIcon input-group-append hiddenIcon"
        } else {
            return "mdi mdi-restore copyIcon input-group-append"
        }
    }

    renderSegments(segments) {
        if (segments && segments.length > 0) return segments.map((segment, i) =>
            <tr key={i}><td>
                <div className="input-group">
                    <i className={ this.resetSegmentIcon(i) } onClick={ e=>this.resetSegmentTime(i) }/>
                    <i className="mdi mdi-fast-forward copyIcon input-group-append" onClick={ e=>this.jumpToSegment(segment, i) }/>
                    <i className="mdi mdi-play-arrow copyIcon input-group-append" onClick={ e=>this.playSegment(segment, i) }/>
                    <div className="input-group-append" style={{"background": this.getSegmentColor(i)}}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </div>
                    <input disabled={ i === 0 } type="text" style={ this.styleSegmentInput(i) } className="form-control form-control-sm col-1" value={ segment.start_seconds }
                           onChange={ e=>this.updateSegment(true, i, e.target.value) } />
                    <input type="text" style={ this.styleSegmentInput(i) } className="form-control form-control-sm col-5" value={ segment.name }
                           onChange={ e=>this.updateSegment(false, i, e.target.value) } />
                    <i className="input-group-append mdi mdi-cancel glyphIcon removeIcon" onClick={ e=>this.removeSegment(i) } />
                </div>
            </td></tr>
        )
    }

    styleSegmentInput(i) {
        if (i === this.state.playingSegment) {
            return {"backgroundColor": "#FFFFDD"}
        } else return {}
    }

    renderSegmentDisplay(segments) {
        if (segments && segments.length > 0) return (
            <table style={{"tableLayout":"fixed","width":"100%"}}><tbody>
                <tr><th style={{"width":"161px"}} /><th /><th style={{"width":"43px"}} /></tr>
                <tr><td />
                <td>
                    <table style={{"tableLayout":"fixed", "width":"100%"}}><tbody>
                    <tr>{ this.getFirstSegmentOffset(segments) }{ segments.map((s,i)=>this.getSegmentWidthCell(s,segments,i)) }</tr>
                    <tr><td />{ segments.map((s,i)=>this.getSegmentColorCell(s,i)) }</tr>
                    </tbody></table>
                </td><td />
                </tr>
            </tbody></table>
        )
    }

    getFirstSegmentOffset(segments) {
        const sec = this.timeTextToNum(segments[0].start_seconds)
        const maxSec = segments[segments.length - 1].end_seconds
        const width = Math.ceil((sec * 100) / maxSec) + "%"
        return <th style={{"width": width}} key={`segmentHead`}/>
    }

    getSegmentWidthCell(segment, segments, i) {
        if (!this.player) return
        const sec = segment.end_seconds - this.timeTextToNum(segment.start_seconds)
        const maxSec = segments[segments.length-1].end_seconds
        const width = Math.ceil((sec * 100) / maxSec) + "%"
        return <th style={{"width": width }} key={`segmentWidth${i}`} />
    }

    getSegmentColorCell(segment, i) {
        return <td style={{"background": this.getSegmentColor(i), "height": "15px"}} key={`segmentColor${i}`} />
    }

    getSegmentColor(i) {
        i = i % 5
        if (i === 0) return "#17a2b8"
        if (i === 1) return "#8f62D1"
        if (i === 2) return "#ba8b00"
        if (i === 3) return "#ed969e"
        if (i === 4) return "chartreuse"
        else return "#007bff"
    }

    itemIsFlagged(video) {
        return videoIsFlagged(video)
    }

    renderSubtitle(status) {
        if (status === 'live') { 
            return ( <tr>
            <td className="inputLabel">subtitle file</td>
            <td>
            <textarea disabled="disable" rows="10" cols="60"
                style={{ resize: "none" }}
                value={this.state.subtitleContent} />
            <div>
                <input type="file" key={this.state.subtitleFileKey} accept=".srt" onChange={this.updateSubtitleFile} />
                <Button variant="danger" className="btn btn-sm" onClick={ this.removeSubtitle }>Remove</Button>
            </div>
            </td>
            </tr>)
        }
        
    }

    renderEditor(video) {

        const popoverSegments = (
            <Popover id="segmentsHelp" className="helpPopover" >
                <Popover.Content>
                    To add a new segment, move playback to the desired start point, then click the green + to create a new segment starting there.
                    Adjust an existing segment's start point by moving playback to the new start point, then clicking the stopwatch next to that segment.
                </Popover.Content>
            </Popover>
        )

        const popoverLocale = (
            <Popover id="localeHelp" className="helpPopover" >
                <Popover.Content>
                    Locale setting only affects the video segment labels, which can be set separately for each locale.
                    Video title is not shown to the client, and so is not locale-specific.
                </Popover.Content>
            </Popover>
        )

        return (
            <table cellPadding={8} style={{"marginTop":"24px", "tableLayout":"fixed"}} className="col-12"><tbody>
            <tr><th style={{"width":"150px"}} /><th /></tr>

            { this.renderFlagged(video) }

            <tr>
                <td className="inputLabel">title</td>
                <td>{ this.renderIfNotUploading([
                    <input type="text" style={{ width:"400px", display:"inline-block" }} className="userInput form-control"
                           placeholder='Ex.: treatment 73 intro domestic' key="fileInput"
                           value={ video.name } onChange={ e=>this.updateItem('name', e.target.value) } />,
                    <span className="inputLabel" style={{display:"inline-block", marginLeft:"40px"}} key="localeLabel" >editing for locale</span>,
                    <LocaleSelector id="localeSelector" key="localeSelector" />,
                    <OverlayTrigger trigger="click" placement="bottom" overlay={popoverLocale} key="overlayTrigger">
                        <i className="mdi mdi-help glyphIcon" style={{display:"inline-block"}}/>
                    </OverlayTrigger>
                ])}
                </td>
            </tr>

            { this.renderUploader(this.state.uploadProgress, "upload video", "video/*") }
           
            <tr>
                <td className="inputLabel">status</td>
                <td>{ this.renderIfNotUploading(this.renderStatus(video.status, video.status_detail, video.status_change_time)) }</td>
            </tr>

            <tr>
                <td className="inputLabel">source file</td>
                <td>
                    { this.renderIfNotUploading(this.renderSourceFile(video)) }
                </td>
            </tr>

            <tr>
                <td className="inputLabel">from CDN</td>
                <td>
                    <div style={{"width":"60%"}}>
                        { this.renderIfNotUploading(this.renderIfNotNew(
                            <Player ref={r=>this.player = r} onSeeked={this.onPlayerProgress} onTimeUpdate={this.onPlayerProgress}>
                                <source src={ video.url_mp4 } />
                                <ControlBar autoHideTime={999999} />
                            </Player>
                        ))}
                        { this.renderIfNotUploading(this.renderIfNotMissing(this.renderSegmentDisplay(video.segments))) }
                    </div>
                </td>
            </tr>

            <tr>
                <td className="inputLabel">
                    <OverlayTrigger trigger="click" placement="bottom" overlay={popoverSegments}>
                        <i className="mdi mdi-help glyphIcon" />
                    </OverlayTrigger>
                    &nbsp;&nbsp;segments</td>
                <td>
                    <table width="100%"><tbody>
                    { this.renderIfNotUploading(this.renderIfNotMissing(this.renderSegments(video.segments))) }
                    <tr><td>
                        { this.renderIfNotUploading(this.renderIfNotNew(this.renderIfNotMissing(
                            <button className="btn btn-sm btn-link" onClick={e => this.addSegment()}>
                                <i className="mdi mdi-add-box addIcon" />
                            </button>
                        )))}
                    </td></tr>
                    </tbody></table>
                </td>
            </tr>
            
            { this.renderSubtitle(video.status) }

            <tr>
                <td className="inputLabel">URLs</td>
                <td>
                    { this.renderIfNotUploading(this.renderIfNotNew(
                        <div className="overflow-auto p-2 bg-light" style={{"maxWidth": "800px"}}>
                            <table><tbody>
                                <tr>
                                    <td>DASH:</td>
                                    <td>{ this.renderCopyButton(video.url_dash) }</td>
                                    <td className="videoUrl">{ video.url_dash }</td>
                                </tr>
                                <tr>
                                    <td>HLS:</td>
                                    <td>{ this.renderCopyButton(video.url_hls) }</td>
                                    <td className="videoUrl">{ video.url_hls }</td>
                                </tr>
                                <tr>
                                    <td>MP4:</td>
                                    <td>{ this.renderCopyButton(video.url_mp4) }</td>
                                    <td className="videoUrl">{ video.url_mp4 }</td>
                                </tr>
                            </tbody></table>
                        </div>
                    ))}
                </td>
            </tr>

            <tr><td /><td><hr/></td></tr>

            { this.renderIfNotUploading( this.renderButtons() ) }

            </tbody></table>
        )
    }
}
