import React, {Component} from 'react'
import ziipLogo from './images/ziip-logo-black.svg'
import {NavLink} from 'react-router-dom'
import { Row, Col, Button, Modal } from 'react-bootstrap'
import './css/Masthead.css'
import { logUserOut } from './LogUserOut'

class Masthead extends Component {

    constructor(props) {
        super(props)
        this.state = { confirmLogout: false }
        this.onLogout = this.onLogout.bind(this)
        this.doLogout = this.doLogout.bind(this)
    }

    onLogout(e) {
        e.preventDefault()
        e.stopPropagation()
        this.setState({ confirmLogout: true })
    }

    doLogout(actually) {
        if (!actually) {
            this.setState({ confirmLogout: false })
            return
        }
        logUserOut()
    }

    renderConfirm() {
        if (this.state.confirmLogout) {
            return (
                <Modal show={true} onHide={ ()=>this.doLogout(false) } >
                    <Modal.Header>
                        <Modal.Title>Log out?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to log out of the ZIIP CMS?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={ () => this.doLogout(false) }>Cancel</Button>
                        <Button variant="primary" onClick={ () => this.doLogout(true) }>Log out</Button>
                    </Modal.Footer>
                </Modal>
            )
        }
    }
    render() {
        return (
            <div className="col-12">
                { this.renderConfirm() }
                <Row>
                    <Col style={{ margin: 0, paddingLeft: "15px", paddingTop: "10px", paddingBottom: "5px" }} className="col-3">
                        <table><tbody>
                        <tr>
                            <td style={{ paddingRight: "25px" }}>
                                <NavLink to="/home">
                                <img src={ ziipLogo } alt="logo" />
                                </NavLink>
                                <br /><span className="mastheadLabel">{ global.config.envName } Dashboard</span>
                            </td>
                            <td>
                                <span className="mastheadLoggedInUser">{ global.config.username}</span><br />
                                <Button variant="outline-primary" size="sm" onClick={ this.onLogout }>logout</Button>
                            </td>
                        </tr>
                        </tbody></table>
                    </Col>
                    <Col style={{ margin: 0, padding: 0 }} className="col-9 align-bottom">
                        <div className="col-12 h-100 d-table">
                            <div className="d-table-cell align-bottom">
                                <ul className="nav nav-tabs">
                                    <li className="nav-item"><NavLink to="/treatments" className="nav-item nav-link">
                                        <i className="mdi mdi-highlight navIcon"/>&nbsp;
                                        Treatments
                                    </NavLink></li>
                                    <li className="nav-item"><NavLink to="/collections" className="nav-item nav-link">
                                        <i className="mdi mdi-format-list-bulleted navIcon"/>&nbsp;
                                        Collections
                                    </NavLink></li>
                                    <li className="nav-item"><NavLink to="/users" className="nav-item nav-link">
                                        <i className="mdi mdi-wc navIcon"/>&nbsp;
                                        Users
                                    </NavLink></li>
                                    <li className="nav-item"><NavLink to="/locales" className="nav-item nav-link">
                                        <i className="mdi mdi-local-airport navIcon"/>&nbsp;
                                        Locales
                                    </NavLink></li>
                                    <li className="nav-item"><NavLink to="/filters" className="nav-item nav-link">
                                        <i className="mdi mdi-filter-list navIcon"/>&nbsp;
                                        Filters
                                    </NavLink></li>
                                    <li className="nav-item"><NavLink to="/videos" className="nav-item nav-link">
                                        <i className="mdi mdi-videocam navIcon"/>&nbsp;
                                        Videos
                                    </NavLink></li>
                                    <li className="nav-item"><NavLink to="/firmware" className="nav-item nav-link">
                                        <i className="mdi mdi-device-hub navIcon"/>&nbsp;
                                        Firmware
                                    </NavLink></li>
                                    <li className="nav-item"><NavLink to="/treatplans" className="nav-item nav-link">
                                        <i className="mdi mdi-assignment-turned-in navIcon" />&nbsp;
                                        Plans
                                    </NavLink></li>
                                    <li className="nav-item"><NavLink to="/plancollections" className="nav-item nav-link">
                                        <i className="mdi mdi-format-list-bulleted navIcon"/>&nbsp;
                                        Plan Collections
                                    </NavLink></li>
                                    <li className="nav-item"><NavLink to="/featured" className="nav-item nav-link">
                                        <i className="mdi mdi-announcement navIcon" />&nbsp;
                                        Featured
                                    </NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default Masthead
