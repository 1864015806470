import Superagent from 'superagent';
import util from 'util';
import { logUserOut } from './LogUserOut'

export default class Api {

  baseURL = global.config.apiUrl;

  constructor() {
    this.authorizationHeader = this.authorizationHeader.bind(this)
    this.checkStatus = this.checkStatus.bind(this)
    this.deleteGroup = this.deleteGroup.bind(this)
    this.deleteLocale = this.deleteLocale.bind(this)
    this.deleteTreatment = this.deleteTreatment.bind(this)
    this.getError = this.getError.bind(this)
    this.headers = this.headers.bind(this)
    this.fetchLocale = this.fetchLocale.bind(this)
    this.fetchLocales = this.fetchLocales.bind(this)
    this.fetchTreatments = this.fetchTreatments.bind(this)
    this.fetchVideo = this.fetchVideo.bind(this)
    this.fetchVideos = this.fetchVideos.bind(this)
    this.getNewTreatmentID = this.getNewTreatmentID.bind(this)
    this.validateGroupIdChange = this.validateGroupIdChange.bind(this)
    this.validateImageUpload = this.validateImageUpload.bind(this)
    this.validateTreatmentIdChange = this.validateTreatmentIdChange.bind(this)
  }

  headers() {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': this.authorizationHeader()
    }
    headers[global.config.localeHeader] = global.localeManager.currentLocaleId()
    return headers
  }

  authorizationHeader() {
    var tok = global.config.username + ':' + global.config.password
    var hash = btoa(tok)
    return "Basic " + hash
  }

  checkStatus(response) {
    if (response.status >= 200 && response.status < 300) {
      return response
    } else if (response.status === 401) {
      // Log user out if unauthorized
      logUserOut()
      return response
    } else {
      let error = new Error(response.statusText)
      error.response = response
      throw error
    }
  }

  getError(error) {
    if (error && error.response && error.response.text) {
      return error.response.text
    } else {
      return util.inspect(error)
    }
  }

  fetchTreatments() {
    return Superagent.get(this.baseURL + "treatments")
      .set(this.headers())
      .then( this.checkStatus )
  }

  fetchTreatmentsDefaultLocale() {
    let headers = this.headers()
    headers[global.config.localeHeader] = global.config.defaultLocale
    return Superagent.get(this.baseURL + "treatments")
        .set(headers)
        .then( this.checkStatus)
  }

  fetchTreatment(treatmentId) {
    return Superagent.get(this.baseURL + "treatments/" + treatmentId)
      .set(this.headers())
      .then( this.checkStatus )
  }

  fetchUserTreatments(userId) {
    return Superagent.get(this.baseURL + "userTreatments/" + userId)
        .set(this.headers())
        .then( this.checkStatus )
  }

  fetchUserPlans(userId) {
    return Superagent.get(this.baseURL + "userPlans/" + userId)
        .set(this.headers())
        .then( this.checkStatus )
  }

  getNewTreatmentID() {
    return Superagent.get(this.baseURL + "treatmentsGetID")
        .set(this.headers())
        .then(this.checkStatus);
  }

  updateTreatment(treatment) {
    return Superagent.put(this.baseURL + "treatments/" + treatment.treatment_id)
      .set(this.headers())
      .send(treatment)
      .then( this.checkStatus );
  }

  addTreatment(treatment) {
    return Superagent.post(this.baseURL + "treatments")
      .set(this.headers())
      .send(treatment)
      .then( this.checkStatus );
  }

  deleteTreatment(treatment) {
    return this.deleteTreatmentId(treatment.treatment_id);
  }

  deleteTreatmentId(treatmentId) {
    return Superagent.delete(this.baseURL + "treatments/" + treatmentId)
      .set(this.headers())
      .then( this.checkStatus );
  }

  validateTreatmentIdChange(oldTreatmentId, newTreatmentId) {
    return Superagent.post(this.baseURL + "treatments/validateIdChange")
      .set(this.headers())
      .send({ oldTreatmentId: oldTreatmentId, newTreatmentId: newTreatmentId })
      .then( this.checkStatus );
  }

  fetchTreatplans() {
    return Superagent.get(this.baseURL + "treatplans")
        .set(this.headers())
        .then(this.checkStatus)
  }

  fetchTreatPlansDefaultLocale() {
    let headers = this.headers()
    headers[global.config.localeHeader] = global.config.defaultLocale
    return Superagent.get(this.baseURL + "treatplans")
        .set(headers)
        .then( this.checkStatus)
  }

  fetchTreatplan(treatplanId) {
    return Superagent.get(this.baseURL + "treatplans/" + treatplanId)
        .set(this.headers())
        .then(this.checkStatus)
  }

  getNewTreatplanID() {
    return Superagent.get(this.baseURL + "treatplansGetID")
        .set(this.headers())
        .then(this.checkStatus);
  }

  updateTreatplan(treatplan) {
    return Superagent.put(this.baseURL + "treatplans/" + treatplan.treatplan_id)
        .set(this.headers())
        .send(treatplan)
        .then(this.checkStatus)
  }

  addTreatplan(treatplan) {
    return Superagent.post(this.baseURL + "treatplans")
        .set(this.headers())
        .send(treatplan)
        .then(this.checkStatus)
  }

  deleteTreatplan(treatplan) {
    return this.deleteTreatplanId(treatplan.treatplan_id)
  }

  deleteTreatplanId(treatplanId) {
    return Superagent.delete(this.baseURL + "treatplans/" + treatplanId)
        .set(this.headers())
        .then(this.checkStatus)
  }

  fetchLocales() {
    return Superagent.get(this.baseURL + "locales")
        .set(this.headers())
        .then(this.checkStatus );
  }

  fetchLocale(localeId) {
    return Superagent.get(this.baseURL + "locales/" + localeId)
        .set(this.headers())
        .then(this.checkStatus)
  }

  updateLocale(locale) {
    return Superagent.put(this.baseURL + "locales/" + locale.locale)
        .set(this.headers())
        .send(locale)
        .then(this.checkStatus)
  }

  addLocale(locale) {
    return Superagent.post(this.baseURL + "locales")
        .set(this.headers())
        .send(locale)
        .then(this.checkStatus)
  }

  deleteLocale(localeId) {
    return Superagent.delete(this.baseURL + "locales/" + localeId)
        .set(this.headers())
        .then(this.checkStatus)
  }

  fetchGroups() {
    return Superagent.get(this.baseURL + "groups")
        .set(this.headers())
        .then( this.checkStatus );
  }

  fetchPlanGroups() {
    return Superagent.get(this.baseURL + "plangroups")
        .set(this.headers())
        .then( this.checkStatus );
  }

  fetchGroup(groupId) {
    return Superagent.get(this.baseURL + "groups/" + groupId)
      .set(this.headers())
      .then( this.checkStatus );
  }

  fetchPlanGroup(planGroupId) {
    return Superagent.get(this.baseURL + "plangroups/" + planGroupId)
        .set(this.headers())
        .then( this.checkStatus );
  }

  validateGroupIdChange(oldGroupId, newGroupId) {
    return Superagent.post(this.baseURL + "groups/validateIdChange")
      .set(this.headers())
      .send({ oldGroupId: oldGroupId, newGroupId: newGroupId })
      .then( this.checkStatus );
  }

  updateGroup(group) {
    return Superagent.put(this.baseURL + "groups/" + group.group_id)
      .set(this.headers())
      .send(group)
      .then( this.checkStatus );
  }

  updatePlanGroup(planGroup) {
    return Superagent.put(this.baseURL + "plangroups/" + planGroup.plan_group_id)
        .set(this.headers())
        .send(planGroup)
        .then( this.checkStatus );
  }

  addGroup(group) {
    return Superagent.post(this.baseURL + "groups")
      .set(this.headers())
      .send(group)
      .then( this.checkStatus );
  }

  addPlanGroup(planGroup) {
    return Superagent.post(this.baseURL + "plangroups")
        .set(this.headers())
        .send(planGroup)
        .then( this.checkStatus );
  }

  deleteGroup(group) {
    return this.deleteGroupId(group.group_id);
  }

  deleteGroupId(groupId) {
    return Superagent.delete(this.baseURL + "groups/" + groupId)
      .set(this.headers())
      .then( this.checkStatus );
  }

  deletePlanGroupId(planGroupId) {
    return Superagent.delete(this.baseURL + "plangroups/" + planGroupId)
        .set(this.headers())
        .then( this.checkStatus );
  }

  searchUsers(query) {
    return Superagent.post(this.baseURL + "users/search")
      .set(this.headers())
      .send(query)
      .then( this.checkStatus );
  }

  fetchUser(userId) {
    return Superagent.get(this.baseURL + "users/" + userId)
      .set(this.headers())
      .then( this.checkStatus );
  }

  updateUser(user) {
    return Superagent.put(this.baseURL + "users/" + user.user_id)
      .set(this.headers())
      .send(user)
      .then( this.checkStatus );
  }

  addUser(user) {
    return Superagent.post(this.baseURL + "users")
      .set(this.headers())
      .send(user)
      .then( this.checkStatus );
  }

  deleteUser(user) {
    return Superagent.delete(this.baseURL + "users/" + user.user_id)
      .set(this.headers())
      .then( this.checkStatus );
  }

  deleteUserId(userId) {
    return Superagent.delete(this.baseURL + "users/" + userId)
        .set(this.headers())
        .then( this.checkStatus )
  }

  grantPromoSub(userId, period, entitlement) {
    return Superagent.post(this.baseURL + "users/grantpromo/" + userId)
        .set(this.headers())
        .send({ period: period, entitlementId: entitlement })
        .then( this.checkStatus )
  }

  revokePromoSub(userId, entitlement) {
    return Superagent.post(this.baseURL + "users/revokepromo/" + userId)
        .set(this.headers())
        .send({ entitlementId: entitlement })
        .then( this.checkStatus )
  }

  fetchFilters() {
    return Superagent.get(this.baseURL + "filters")
        .set(this.headers())
        .then( this.checkStatus )
  }

  fetchFilter(filterId) {
    return Superagent.get(this.baseURL + "filter/" + filterId)
        .set(this.headers())
        .then( this.checkStatus )
  }

  addFilter(filter) {
    return Superagent.post(this.baseURL + "filters")
        .set(this.headers())
        .send(filter)
        .then( this.checkStatus )
  }

  deleteFilterId(filterId) {
    return Superagent.delete(this.baseURL + "filter/" + filterId)
        .set(this.headers())
        .then( this.checkStatus )
  }

  updateFilter(filter) {
    return Superagent.put(this.baseURL + "filter/" + filter.filter_id)
        .set(this.headers())
        .send(filter)
        .then( this.checkStatus )
  }

  fetchVideos() {
    return Superagent.get(this.baseURL + "videos")
        .set(this.headers())
        .then( this.checkStatus )
  }

  fetchVideo(videoId) {
    return Superagent.get(this.baseURL + "video/" + videoId)
        .set(this.headers())
        .then( this.checkStatus )
  }

  addVideo(video) {
    return Superagent.post(this.baseURL + "videos")
        .set(this.headers())
        .send(video)
        .then( this.checkStatus )
  }

  deleteVideoId(videoId) {
    return Superagent.delete(this.baseURL + "video/" + videoId)
        .set(this.headers())
        .then( this.checkStatus )
  }

  updateVideo(video) {
    return Superagent.put(this.baseURL + "video/" + video.video_id)
        .set(this.headers())
        .send(video)
        .then( this.checkStatus )
  }

  fetchFirmwares() {
    return Superagent.get(this.baseURL + "firmwares")
        .set(this.headers())
        .then( this.checkStatus )
  }

  fetchFirmware(firmwareId) {
    return Superagent.get(this.baseURL + "firmware/" + firmwareId)
        .set(this.headers())
        .then( this.checkStatus )
  }

  addFirmware(firmware) {
    return Superagent.post(this.baseURL + "firmwares")
        .set(this.headers())
        .send(firmware)
        .then( this.checkStatus )
  }

  deleteFirmwareId(firmwareId) {
    return Superagent.delete(this.baseURL + "firmware/" + firmwareId)
        .set(this.headers())
        .then( this.checkStatus )
  }

  updateFirmware(firmware) {
    return Superagent.put(this.baseURL + "firmware/" + firmware.firmware_id)
        .set(this.headers())
        .send(firmware)
        .then( this.checkStatus )
  }

  activateFirmware(firmware) {
    return Superagent.post(this.baseURL + "firmware/activate/" + firmware.firmware_id)
        .set(this.headers())
        .then( this.checkStatus )
  }

  fetchFeatured() {
    return Superagent.get(this.baseURL + "featured")
        .set(this.headers())
        .then( this.checkStatus )
  }

  updateFeatured(params) {
    return Superagent.put(this.baseURL + "featured")
        .set(this.headers())
        .send(params)
        .then( this.checkStatus )
  }

  fetchStrings() {
      return Superagent.get(this.baseURL + "strings")
          .set(this.headers())
          .then(this.checkStatus)
  }

  validateImageUpload(filename) {
    return Superagent.get(this.baseURL + "upload/image/" + global.config.imageBucket + "/" + filename)
      .set(this.headers());
  }

  validateFirmwareUpload(filename) {
    return Superagent.get(this.baseURL + "upload/image/" + global.config.firmwareBucket + "/" + filename)
        .set(this.headers());
  }

  getSubtitleFile(subtitlePath) {
    return Superagent.get(subtitlePath);
   
  }

  uploadImage(file, s3Data) {
      var formData = new FormData()
      for (var input in s3Data.formInputs) {
        formData.append(input, s3Data.formInputs[input])
      }
      formData.append('file', file)
      return Superagent.post(s3Data.formAttributes.action).send(formData)
  }

  startMultipartUpload(bucket, key) {
      return Superagent.get(this.baseURL + "startMultipartUpload")
          .set(this.headers())
          .query({ bucket, key })
  }

  listMultipartUploadParts(bucket, key, uploadId) {
      return Superagent.get(this.baseURL + "listMultipartUploadParts")
          .set(this.headers())
          .query({ bucket, key, uploadId })
  }

  signAwsUrl(url) {
      return Superagent.get(this.baseURL + "signAwsUrl")
          .set(this.headers())
          .query({ url })
  }

  abortMultipartUpload(bucket, key, uploadId) {
      return Superagent.get(this.baseURL + "abortMultipartUpload")
          .set(this.headers())
          .query({ bucket, key, uploadId })
  }

  completeMultipartUpload(bucket, key, uploadId) {
      return Superagent.get(this.baseURL + "completeMultipartUpload")
          .set(this.headers())
          .query({ bucket, key, uploadId })
  }
}
