import SplitList from '../generic/SplitList'

export default class FeaturedList extends SplitList {


    // SplitList functions
    getItems() {
        return []
    }

    itemId(item) {
        return ""
    }

    itemSort(item) {
        return ""
    }

    labelAddButton() {
        return ""
    }

    renderItem(item) {
        return ""
    }

    fetchItems() { }
    
}
