import React from 'react'
import SplitPane from '../generic/SplitPane'
import FeaturedList from "./FeaturedList"
import FeaturedEditor from "./FeaturedEditor"


export default class FeaturedPane extends SplitPane {

    constructor(props) {
        super(props)
        this.renderList = this.renderList.bind(this)
        this.renderEdit = this.renderEdit.bind(this)
    }


    // SplitPane functions

    renderList() {
        return <FeaturedList onItemClick={ this.onItemClick } updateCount={ this.state.updateCount } />
    }

    renderEdit() {
        return <FeaturedEditor onEditorChange={ this.onEditorChange } item={ this.state.editing } selectCount={ this.state.selectCount } />
    }
}
