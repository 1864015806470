import React, { Component } from 'react'
import S3Uploader from "./S3Uploader"

export default class S3UploadInput extends Component {

    constructor(props) {
        super(props)

        this.uploader = null // Will be populated with our uploader class when we start the upload
        this.inputRef = React.createRef(); // Will be populated by our <input> React component, not the DOM element

        this.abort = this.abort.bind(this)
        this.clear = this.clear.bind(this)
        this.uploadFile = this.uploadFile.bind(this)
        this.getInputProps = this.getInputProps.bind(this)
        this.onFileSelected = this.onFileSelected.bind(this)
    }

    hasChosenFile() {
        return (this.inputRef.current && this.inputRef.current.files.length > 0)
    }

    uploadFile() {
        this.uploader = new S3Uploader({
            evaporateOptions: this.props.evaporateOptions,
            fileElement: this.inputRef.current,
            signingUrl: this.props.signingUrl,
            getSignedUrl: this.props.getSignedUrl,
            preprocess: this.props.preprocess,
            onProgress: this.props.onProgress,
            onFinishS3Put: this.props.onFinish,
            onError: this.props.onError,
            signingUrlMethod: this.props.signingUrlMethod,
            signingUrlHeaders: this.props.signingUrlHeaders,
            signingUrlQueryParams: this.props.signingUrlQueryParams,
            signingUrlWithCredentials: this.props.signingUrlWithCredentials,
            uploadRequestHeaders: this.props.uploadRequestHeaders,
            contentDisposition: this.props.contentDisposition,
            server: this.props.server,
            scrubFilename: this.props.scrubFilename,
            s3Path: this.props.s3path
        })
    }

    abort() {
        this.uploader && this.uploader.abortUpload()
    }

    clear() {
        this.inputRef.value = ''
    }

    onFileSelected() {
        this.props.onFileSelect && this.props.onFileSelect()
    }

    getInputProps() {
        const additional = {
            type: 'file',
            ref: this.inputRef
        }

        if ( this.props.autoUpload ) {
            additional.onChange = this.uploadFile
        } else {
            additional.onChange = this.onFileSelected
        }

        const temporaryProps = { ...this.props, ...additional }
        const inputProps = {}

        const invalidProps = ["signingUrl", "getSignedUrl", "preprocess", "onProgress", "onFinish", "onError",
            "signingUrlMethod", "signingUrlHeaders", "signingUrlQueryParams", "signingUrlWithCredentials",
            "uploadRequestHeaders", "contentDisposition", "server", "scrubFilename", "s3path", "inputRef",
            "autoUpload", "evaporateOptions", "onFileSelect" ]

        for (let key in temporaryProps) {
            if (temporaryProps.hasOwnProperty(key) && invalidProps.indexOf(key) === -1) {
                inputProps[key] = temporaryProps[key]
            }
        }

        return inputProps
    }

    render() {
        return ( <input { ...this.getInputProps() } /> )
    }
}
