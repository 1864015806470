import React from 'react'
import SplitPane from '../generic/SplitPane'
import TreatmentList from './TreatmentList'
import TreatmentEditor from './TreatmentEditor'

export default class TreatmentPane extends SplitPane {

    constructor(props) {
        super(props)
        this.renderList = this.renderList.bind(this)
        this.renderEdit = this.renderEdit.bind(this)
    }


    // SplitPane functions

    renderList() {
        return <TreatmentList onItemClick={ this.onItemClick } updateCount={ this.state.updateCount }/>
    }

    renderEdit() {
        return <TreatmentEditor onEditorChange={ this.onEditorChange } item={ this.state.editing } selectCount={ this.state.selectCount } />
    }
}
