import Uppy from '@uppy/core'
import AwsS3Multipart from '@uppy/aws-s3-multipart'

export default class S3Uploader {

    constructor(options) {

        this.abortUpload = this.abortUpload.bind(this)
        this.handleFileSelect = this.handleFileSelect.bind(this)
        this.scrubFilename = this.scrubFilename.bind(this)
        this.uploadToS3 = this.uploadToS3.bind(this)

        this.server = ''
        this.signingUrl = global.config.apiUrl + "signAwsUrl"
        this.signingUrlMethod = 'GET'
        this.fileElement = null
        this.files = null

        if (!options || typeof options !== 'object') {
            // We should always get callbacks via options
            console.error('S3Uploader: No callbacks given via options, check how the uploader is being called.')
            options = {}
        }
        for (let option in options) {
            if (options.hasOwnProperty(option)) {
                this[option] = options[option]
            }
        }
        const files = this.fileElement ? this.fileElement.files : this.files || []
        this.handleFileSelect(files);
    }

    onFinishS3Put(signResult, file) {
        console.error('S3Uploader: calling base.onFinishS3Put()', file)
    }

    preprocess(file, next) {
        console.error('S3Uploader: calling base.preprocess()', file)
        return next(file)
    }

    onProgress(percent, status, file) {
        console.error('S3Uploader: calling base.onProgress()', percent, status)
    }

    onError(status, file) {
        console.error('S3Uploader: calling base.onError()', status)
    }

    scrubFilename(filename) {
        return filename.replace(/[^\w\d_\-.]+/ig, '')
    }

    handleFileSelect(files) {
        const result = []
        for (let i=0; i < files.length; i++) {
            const file = files[i]
            this.preprocess(file, processedFile => {
                this.onProgress(0, 'Waiting', processedFile)
                result.push(this.uploadToS3(processedFile))
                return result
            })
        }
    }

    abortUpload(filename) {
        if (this.uppy) {
            this.uppy.cancelAll()
        }
    }

    uploadToS3(file) {
        const createMultipartUpload = (file) => {
            return global.api.startMultipartUpload(global.config.videoBucket, file.name)
                .then( res => res.body )
        }
        const listParts = (file, fileData) => {
            return global.api.listMultipartUploadParts(global.config.videoBucket, file.name, fileData.uploadId)
                .then( res => res.body )
        }
        const signPart = (file, partData) => {
            console.log(`#### signPart called with file ${file.name} -- partData ${JSON.stringify(partData)}`)
            return global.api.signAwsUrl(`https://${global.config.videoBucket}.s3.us-west-2.amazonaws.com/${partData.key}?partNumber=${partData.partNumber}&uploadId=${partData.uploadId}`)
                .then( res => res.body )
        }
        const abortMultipartUpload = (file, fileData) => {
            return global.api.abortMultipartUpload(global.config.videoBucket, fileData.key, fileData.uploadId)
                .then( res => res.body )
        }
        const completeMultipartUpload = (file, fileData) => {
            return global.api.completeMultipartUpload(global.config.videoBucket, fileData.key, fileData.uploadId)
                .then( res => res.body )
        }

        const uppy = new Uppy({ logger: console }).use(AwsS3Multipart, {
            createMultipartUpload, listParts, signPart, abortMultipartUpload, completeMultipartUpload
        })
        uppy.on('error', (error) => {
            console.error(error.stack)
        })
        uppy.on('progress', (progress) => {
            this.onProgress(progress, progress === 100 ? 'Finalizing' : 'Uploading', file)
        })
        uppy.addFile({
            name: this.scrubFilename(file.name),
            type: file.type,
            data: file,
        })
        this.uppy = uppy
        return uppy.upload().then( result => {
            if (result.failed && result.failed.length > 0) {
                this.onError(`Upload error: ${result.failed[0].error}`, file)
            } else {
                this.onProgress(100, 'Upload completed', file)
                this.onFinishS3Put(result.successful[0], file)
            }
        }).catch( e => this.onError(`Upload error: ${e}`, file))
    }
}
