import React from 'react'
import SplitPane from '../generic/SplitPane'
import FirmwareList from './FirmwareList'
import FirmwareEditor from './FirmwareEditor'

export default class FirmwarePane extends SplitPane {

    constructor(props) {
        super(props)
        this.renderList = this.renderList.bind(this)
        this.renderEdit = this.renderEdit.bind(this)
    }


    // SplitPane functions

    renderList() {
        return <FirmwareList onItemClick={ this.onItemClick } updateCount = { this.state.updateCount }/>
    }

    renderEdit() {
        return <FirmwareEditor onEditorChange={ this.onEditorChange } item={ this.state.editing } selectCount={ this.state.selectCount } />
    }
}
