import React from 'react'
import '../css/App.css'
import SplitEditor from '../generic/SplitEditor'
import ProgramPicker from "../ProgramPicker"
import LocaleSelector from "../LocaleSelector"
import Popover from 'react-bootstrap/Popover'
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Dropdown, DropdownButton, Row, Col, Button } from 'react-bootstrap'
import { numID, TreatmentTiers } from '../common'
import { treatmentIsFlagged } from './TreatmentIsFlagged'
import VideoPicker from "./VideoPicker";

export default class TreatmentEditor extends SplitEditor {

    programEditor = null

    constructor(props) {
        super(props)
        this.state = { ...this.state, filters: [], videos: [], groups: [] }
        this.getItemFromStore = this.getItemFromStore.bind(this)
        this.deleteItemFromStore = this.deleteItemFromStore.bind(this)
        this.onLoadItem = this.onLoadItem.bind(this)
        this.renderEditor = this.renderEditor.bind(this)
        this.itemNotOKToSave = this.itemNotOKToSave.bind(this)
        this.createNewItemInStore = this.createNewItemInStore.bind(this)
        this.updateItemInStore = this.updateItemInStore.bind(this)
        this.updateImage = this.updateImage.bind(this)
        this.updateThumbnailImage = this.updateThumbnailImage.bind(this)
        this.updateLandingImage = this.updateLandingImage.bind(this)
        this.calculateTreatmentLength = this.calculateTreatmentLength.bind(this)
        this.onProgramLoaded = this.onProgramLoaded.bind(this)
        this.updateOverview = this.updateOverview.bind(this)
        this.updateInstructions = this.updateInstructions.bind(this)
        this.updateRecommendations = this.updateRecommendations.bind(this)
        this.addRecommendationStep = this.addRecommendationStep.bind(this)
        this.removeRecommendationStep = this.removeRecommendationStep.bind(this)
        this.renderRecommendations = this.renderRecommendations.bind(this)
        this.renderFilters = this.renderFilters.bind(this)
        this.processLocaleStrings = this.processLocaleStrings.bind(this)
        this.localeChanged = this.localeChanged.bind(this)
        this.fetchFilters = this.fetchFilters.bind(this)
        this.filterSelected = this.filterSelected.bind(this)
        this.removeFilter = this.removeFilter.bind(this)
        this.toggleSupportedDevice = this.toggleSupportedDevice.bind(this)
        this.validateInput = this.validateInput.bind(this)
    }

    componentDidMount() {
        super.componentDidMount()
        global.localeManager.addListener(this)
        this.fetchFilters()
        this.fetchVideos()
        this.fetchGroups()
    }

    componentWillUnmount() {
        global.localeManager.removeListener(this)
    }

    itemIsFlagged(treatment) {
        return treatmentIsFlagged(treatment)
    }

    // SplitEditor functions

    getBlankItem() {
        return JSON.parse(JSON.stringify(global.Templates.treatment))
    }

    getItemFromStore(id) { return global.api.fetchTreatment(id) }

    deleteItemFromStore(id) { return global.api.deleteTreatmentId(id) }

    getNameFromItem(item) { return item.name }

    getIDFromItem(item) { return item.treatment_id }

    onLoadItem(item) { }

    homeIcon() {
        return <i className="mdi mdi-highlight homeIcon" />
    }
    homeText() {
        return "Pick a treatment to edit, or Add Treatment to create a new one."
    }

    homeSubtext() {
        return " "
    }

    itemsAreEqual(a, b) {
        return JSON.stringify(a) === JSON.stringify(b)
    }

    noErrors(errorList) {
        return errorList.length === 0 
    }

    itemNotOKToSave(treatment) {
        return false
    }

    itemNotOKToDelete(treatment) {
        let groups = this.state.groups.filter(g=>g.treatments.includes(treatment.treatment_id))
        if (groups.length === 1)
            return "This treatment is in the '" + groups[0].name + "' group.  Remove it from the group to delete this treatment."
        if (groups.length > 1)
            return "This treatment is in groups " + groups.map(g=>g.name).join(', ') + ".  Remove it from those groups to delete this treatment."
        return false
    }

    prepTreatmentForSave(treatment) {
        const videoSubbed = this.prepVideosForSave(treatment)
        const prepped = this.processLocaleStrings(videoSubbed)
        const introTime = parseInt(treatment.instructions.intro_length, 10)
        if (typeof(introTime) !== "number" || isNaN(introTime)) {
            prepped.instructions.intro_length = 0
            treatment.instructions.intro_length = 0
            this.setState({ treatment })
        } else {
            prepped.instructions.intro_length = introTime
        }
        if (prepped.program_data.number === 0) {
            let rawTreatmentId = parseInt(numID(prepped.treatment_id))
            prepped.program_data.number = rawTreatmentId
        }
        if (prepped.program_data.number > 99) {
            prepped.program_data.number = 99
        }
        return prepped
    }

    prepVideosForSave(incomingTreatment) {
        if (incomingTreatment.videos && incomingTreatment.videos.instructions) {
            const treatment = JSON.parse(JSON.stringify(incomingTreatment))
            treatment.videos.instructions = treatment.videos.instructions.video_id
            return treatment
        } else {
            return incomingTreatment
        }
    }

    createNewItemInStore(treatment) {
        global.api.getNewTreatmentID().then(idResponse => {
            treatment.treatment_id = idResponse.body.unused_id
            const t = this.prepTreatmentForSave(treatment)
            global.api.addTreatment(t).then(response => {
                this.saveSucceeded("New treatment created.")
                this.loadItem(t.treatment_id)
                this.reloadListPane()
            }).catch(e => {
                this.saveFailed("Error adding treatment: " + e)
            })
        }).catch(e => {
            this.saveFailed("Error creating treatment: " + e)
        })
    }

    updateItemInStore(treatment) {
        const t = this.prepTreatmentForSave(treatment)
        global.api.updateTreatment(t).then(response => {
            this.saveSucceeded("Changes saved.")
            this.reloadListPane()
        }).catch(e => {
            this.saveFailed("Error updating treatment: " + e.response.text)
        })
    }


    // Internal functions

    fetchFilters() {
        return global.api.fetchFilters().then( response => {
            this.setState({ filters: response.body })
        })
    }

    fetchVideos() {
        return global.api.fetchVideos().then( response => {
            const liveVideos = response.body.filter( filter => filter.status === 'live')
            this.setState({ videos: liveVideos })
        })
    }

    fetchGroups() {
        return global.api.fetchGroups().then( response => {
            this.setState({ groups: response.body })
        })
    }

    updateImage(event) {
        this.showAlert("success", "Uploading image...")
        this.uploadImage(event, 'image_url')
    }

    updateThumbnailImage(event) {
        this.showAlert("success", "Uploading thumbnail image...")
        this.uploadImage(event, 'thumbnail_image_url')
    }

    updateLandingImage(event) {
        this.showAlert("success", "Uploading landing image...")
        this.uploadImage(event, 'landing_image_url')
    }

    onProgramLoaded() {
        this.calculateTreatmentLength()
    }

    calculateTreatmentLength() {
        // We will have to look at the waveform data to determine how long the treatment is.
        // We'll need to loop through each waveform and compute the amount of time
        // spent in each one.  It's also possible for waveforms to repeat and loop,
        // so we need to make sure to take that into account as well.
        let waveformIndex = 0
        let totalSeconds = 0
        let loopingFromIndex = 0
        let loopsRemaining = 0
        let programData = this.programEditor.getProgram()
        let finished = programData.waveforms.length === 0
        while (!finished) {
            // Read the waveform into the struct
            let waveForm = this.readWaveform(programData.waveforms[waveformIndex])
            // Figure out how many times this waveform will repeat
            let repeatFactor = 1
            switch (waveForm.ucRepeat_Factor) {
                case  1:
                    repeatFactor = 2
                    break
                case  2:
                    repeatFactor = 3
                    break
                case  3:
                    repeatFactor = 4
                    break
                case  4:
                    repeatFactor = 5
                    break
                case  5:
                    repeatFactor = 6
                    break
                case  6:
                    repeatFactor = 7
                    break
                case  7:
                    repeatFactor = 8
                    break
                case  8:
                    repeatFactor = 9
                    break
                case  9:
                    repeatFactor = 10
                    break
                case 10:
                    repeatFactor = 11
                    break
                case 11:
                    repeatFactor = 16
                    break
                case 12:
                    repeatFactor = 21
                    break
                case 13:
                    repeatFactor = 26
                    break
                case 14:
                    repeatFactor = 31
                    break
                case 15:
                    repeatFactor = 36
                    break
                case 16:
                    repeatFactor = 41
                    break
                case 17:
                    repeatFactor = 46
                    break
                case 18:
                    repeatFactor = 51
                    break
                default:
                    repeatFactor = 1
                    break
            }
            // Figure out how long each repetition will be
            let duration = 0
            switch (waveForm.ucTime) {
                case  1:
                    duration = 1
                    break
                case  2:
                    duration = 2
                    break
                case  3:
                    duration = 5
                    break
                case  4:
                    duration = 10
                    break
                case  5:
                    duration = 20
                    break
                case  6:
                    duration = 30
                    break
                case  7:
                    duration = 40
                    break
                case  8:
                    duration = 50
                    break
                case  9:
                    duration = 60
                    break
                case 10:
                    duration = 80
                    break
                case 11:
                    duration = 100
                    break
                case 12:
                    duration = 120
                    break
                case 13:
                    duration = 150
                    break
                case 14:
                    duration = 180
                    break
                case 15:
                    duration = 200
                    break
                case 16:
                    duration = 300
                    break
                case 17:
                    duration = 400
                    break
                default:
                    duration = 0
                    break
            }
            totalSeconds += duration * repeatFactor
            // Figure out if we need to start a loop
            if (loopsRemaining > 0 && loopingFromIndex === waveformIndex) {
                // We're currently in a loop, and we've hit the starting point again
                loopsRemaining--
                if (loopsRemaining < 1) {
                    waveformIndex++
                } else {
                    waveformIndex = loopingFromIndex
                }
            } else if (loopsRemaining < 1 && waveForm.ucLoop_Factor > 0) {
                loopingFromIndex = waveformIndex
                waveformIndex -= waveForm.ucLoop_Target
                // We need to start a loop.  Figure out how many times we need to loop
                switch (waveForm.ucLoop_Factor) {
                    case  1:
                        loopsRemaining = 1
                        break
                    case  2:
                        loopsRemaining = 2
                        break
                    case  3:
                        loopsRemaining = 3
                        break
                    case  4:
                        loopsRemaining = 4
                        break
                    case  5:
                        loopsRemaining = 5
                        break
                    case  6:
                        loopsRemaining = 6
                        break
                    case  7:
                        loopsRemaining = 7
                        break
                    case  8:
                        loopsRemaining = 8
                        break
                    case  9:
                        loopsRemaining = 9
                        break
                    case 10:
                        loopsRemaining = 10
                        break
                    case 11:
                        loopsRemaining = 15
                        break
                    case 12:
                        loopsRemaining = 20
                        break
                    case 13:
                        loopsRemaining = 25
                        break
                    case 14:
                        loopsRemaining = 30
                        break
                    case 15:
                        loopsRemaining = 35
                        break
                    case 16:
                        loopsRemaining = 40
                        break
                    case 17:
                        loopsRemaining = 45
                        break
                    case 18:
                        loopsRemaining = 50
                        break
                    default:
                        loopsRemaining = 0
                        break
                }
            } else {
                waveformIndex++
            }
            if (waveformIndex >= programData.waveforms.length) {
                finished = true
            }
        }

        const minutes = Math.round(totalSeconds / 60.0)
        const treatment = this.state.item
        treatment.duration = minutes > 0 ? ("" + minutes + " Min") : ("" + totalSeconds + " Sec")
        this.updateItem("duration", treatment.duration)
    }

    readWaveform(data) {
        //  Waveform data originates from this struct.  We create an object that mimics it here.
        //struct stWaveform {
        //    unsigned char ucType;
        //    unsigned char ucFrequency;
        //    unsigned char ucTime;
        //    unsigned char ucAC_Current;
        //    unsigned char ucDC_Current;
        //    unsigned char ucRepeat_Factor;
        //    unsigned char ucHaptic_Period;
        //    unsigned char ucHaptic_Amount;
        //    unsigned char ucAudio_Waveform_Number;
        //    unsigned char ucLoop_Factor;
        //    unsigned char ucLoop_Target;
        //    unsigned char ucAux2;
        //    unsigned char ucAux3;
        //    unsigned char ucAux4;
        //    unsigned char ucAux5;
        //    unsigned char ucAux6; // byte #16
        //};
        var values = data.split(/\s+/)
        // We should get 16 values.  They look like they might be hex, since they're padded, but really they're just base 10 with padding.
        return {
            ucType: parseInt(values[0], 10),
            ucFrequency: parseInt(values[1], 10),
            ucTime: parseInt(values[2], 10),
            ucAC_Current: parseInt(values[3], 10),
            ucDC_Current: parseInt(values[4], 10),
            ucRepeat_Factor: parseInt(values[5], 10),
            ucHaptic_Period: parseInt(values[6], 10),
            ucHaptic_Amount: parseInt(values[7], 10),
            ucAudio_Waveform_Number: parseInt(values[8], 10),
            ucLoop_Factor: parseInt(values[9], 10),
            ucLoop_Target: parseInt(values[10], 10),
            ucAux2: parseInt(values[11], 10),
            ucAux3: parseInt(values[12], 10),
            ucAux4: parseInt(values[13], 10),
            ucAux5: parseInt(values[14], 10),
            ucAux6: parseInt(values[15], 10),
        }
    }

    processLocaleStrings(incomingTreatment) {
        const treatment = JSON.parse(JSON.stringify(incomingTreatment))
        if (treatment.caption) {
            treatment.caption = this.encodeStringId(treatment.caption, treatment.treatment_id, "caption")
        }
        if (treatment.duration) {
            treatment.duration = this.encodeStringId(treatment.duration, treatment.treatment_id, "duration")
        }
        if (treatment.instructions) {
            if (treatment.instructions.caption) {
                treatment.instructions.caption = this.encodeStringId(treatment.instructions.caption, treatment.treatment_id, "instructions_caption")
            }
            if (treatment.instructions.skip_label) {
                treatment.instructions.skip_label = this.encodeStringId(treatment.instructions.skip_label, treatment.treatment_id, "instructions_skip_label")
            }
        }
        if (treatment.name) {
            treatment.name = this.encodeStringId(treatment.name, treatment.treatment_id, "name")
        }
        if (treatment.overview) {
            if (treatment.overview.caption) {
                treatment.overview.caption = this.encodeStringId(treatment.overview.caption, treatment.treatment_id, "overview_caption")
            }
        }
        if (treatment.recommendations) {
            for (let i=0; i<treatment.recommendations.length; i++) {
                treatment.recommendations[i] = {
                    span: this.encodeStringId(treatment.recommendations[i].span, treatment.treatment_id, "recommendation_" + i + "_span"),
                    text: this.encodeStringId(treatment.recommendations[i].text, treatment.treatment_id, "recommendation_" + i + "_text")
                }
            }
        }
        if (treatment.videos && treatment.videos.instructions) {
            treatment.videos.instructions = this.encodeStringId(treatment.videos.instructions, treatment.treatment_id, "videos_instructions")
        }
        // Incoming filter names need to be matched back to filter_id
        treatment.filters = treatment.filters ? treatment.filters.map(filter => {
            return this.state.filters.find(f =>
                (f.default_name === filter) || (f.locale_name === filter)).filter_id
        }) : []

        return treatment
    }

    localeChanged(locale) {
        this.fetchFilters().then(() => {
            this.reloadCurrentItem()
            this.reloadListPane()
        })
    }

    updateOverview(prop, val) {
        let o = this.state.item.overview
        o[prop] = val
        this.updateItem('overview', o)
    }

    updateInstructions(prop, val) {
        let i = this.state.item.instructions
        i[prop] = val
        this.updateItem('instructions', i)
    }

    updateRecommendations(isSpan, i, value) {
        let r = this.state.item.recommendations
        if (isSpan) {
            r[i].span = value
            this.validateInput(i, value, "step.span")
        } else {
            r[i].text = value
            this.validateInput(i, value, "step.text")
        }
        this.updateItem('recommendations', r)
    }

    addRecommendationStep() {
        let r = this.state.item.recommendations
        if (typeof(r) != 'object') r = []
        r.push({span: "", text: ""})
        this.validateInput(r.length-1, "", "step.span")
        this.validateInput(r.length-1, "", "step.text")
        this.updateItem('recommendations', r)
    }

    removeRecommendationStep(i) {
        let r = this.state.item.recommendations
        r.splice(i, 1)
        this.updateItem('recommendations', r)
    }

    filterSelected(filterName) {
        this.clearAlert()
        let filter = this.state.filters.find(f => (f.default_name === filterName) || (f.locale_name === filterName))
        if (filter) {
            const useDefaultName = ((global.localeManager.currentLocaleId() === global.config.defaultLocale) || (!filter.locale_name))
            let idString = useDefaultName ? filter.default_name : filter.locale_name
            if (!this.state.item.filters || !this.state.item.filters.includes(idString)) {
                const newFilters = this.state.item.filters || []
                newFilters.push(idString)
                this.updateItem('filters', newFilters)
            } else {
                this.showAlert("warning", "That filter is already applied.")
            }
        }
    }

    removeFilter(name) {
        const newFilters = this.state.item.filters.filter(f => f !== name)
        this.updateItem('filters', newFilters)
    }


    validateInput(index, inputValue, name) {
        if(inputValue === ''){
            this.updateError(name, index, 'Empty Value is not allowed')
        }else{
            this.resetError(name, index)
        }
    }

    updateVideo(videoType, video) {
        const treatment = this.state.item || {}
        if (!treatment.videos) treatment.videos = {}
        treatment.videos[videoType] = video
        this.updateItem('videos', treatment.videos)
    }

    parseLoadedItem(item) {
        // Sub in the video object the ID refers to so that we have its data to work with
        if (item.videos && item.videos.instructions) {
            const videoId = item.videos.instructions
            const video = this.state.videos.find( vid => vid.video_id === videoId )
            if (video) {
                item.videos.instructions = video
            }
        }
        // Add supported devices if they haven't been set yet
        if (!item.supported_devices) {
            item.supported_devices = []
        }
        return item
    }

    toggleSupportedDevice(device) {
        const item = this.state.item
        const index = item.supported_devices.indexOf(device)
        if (index >= 0)
            item.supported_devices.splice(index, 1)
        else
            item.supported_devices.push(device)
        this.setState({ item })
    }

    // Render functions

    renderRecommendations(steps) {
        if (steps) {
            return steps.map((step, i) =>
                <tr key={i}><td>
                    <div className="input-group">
                        <input type="text" className={`form-control form-control-sm col-3 ${this.state.validationErrors.includes('step.span'+i)? 'is-invalid': ''} `} placeholder="'Week X'" value={ step.span }
                               onChange={ e=>this.updateRecommendations(true, i, e.target.value)} 
                              />
                                           
                        <input type="text" className={`form-control form-control-sm col-9 ${this.state.validationErrors.includes('step.text'+i)? 'is-invalid': ''} `} placeholder="instructions" value={ step.text }
                               onChange={ e=>this.updateRecommendations(false, i, e.target.value)} 
                      
                                    />

                        <div className="input-group-append">
                            { this.renderRecommendationsDeleteButton(i) }
                        </div>
                    </div>
                </td></tr>
            )
        }
    }

    renderRecommendationsDeleteButton(i) {
        if (global.localeManager.currentLocaleId() === global.config.defaultLocale) {
            return <i className="input-group-append mdi mdi-cancel glyphIcon removeIcon" onClick={ e=>this.removeRecommendationStep(i)} />
        } else {
            return <i className="input-group-append mdi mdi-cancel glyphIcon removeIcon" onClick={ e=>this.showAlert("warning",
                    "Switch to locale " + global.config.defaultLocale + " to remove recommendation steps." )} />
        }
    }

    renderRecommendationsAdd() {
        if (global.localeManager.currentLocaleId() === global.config.defaultLocale) {
            return <button className="btn btn-sm btn-link" onClick={e => this.addRecommendationStep()}>
                <i className="mdi mdi-add-box addIcon"/>
            </button>
        } else {
            return <button className="btn btn-sm btn-link" onClick={e => this.showAlert("warning",
                "Switch to locale " + global.config.defaultLocale + " to add recommendation steps."
            )}><i className="mdi mdi-add-box addIcon"/>
            </button>
        }
    }

    renderFilters(t) {
        let elements = []

        if (t.filters && t.filters.length > 0) {
            t.filters.forEach((f,i) => {
                elements.push(<td key={i} className="filterItem" >
                    &nbsp;&nbsp;{ f } <i className="mdi mdi-cancel glyphIcon removeIcon" onClick={ ()=>this.removeFilter(f) } />
                </td>)
            })
        }
        elements.push(<td style={{"width": "70px"}} key="filters_dropdown">
            <DropdownButton id="filter-selector" variant="success-outline" size="sm" title={ <i className="mdi mdi-add-box addIcon"/> }>
                { this.state.filters && this.state.filters.map((f, i) => {
                    return <Dropdown.Item key={i+1} onSelect={ ()=>this.filterSelected(f.default_name) }>
                        <b>{ f.locale_name || f.default_name }</b>
                    </Dropdown.Item>
                })}
            </DropdownButton>
        </td>)
        return elements
    }

    renderIntensity(intensity) {
        if (intensity === 1) {
            return "1 (Low)"
        } else if (intensity === 2) {
            return "2 (Medium)"
        } else if (intensity === 3) {
            return "3 (High)"
        }
    }

    renderTierSelector(t) {
        let tiers = []
        for (let key in TreatmentTiers) {
            if (TreatmentTiers.hasOwnProperty(key)) tiers.push(key)
        }
        return (
            <DropdownButton variant="warning" size="sm" id="dropdown-tier" title={ this.renderTier(t.tier) }>
                { tiers.map(key => {
                    return (
                        <Dropdown.Item onSelect={ ()=>this.updateItem('tier', TreatmentTiers[key]) }>
                            <b>{key}</b>
                        </Dropdown.Item>
                    )})
                }
            </DropdownButton>
        )
    }

    renderTier(value) {
        for (let p in TreatmentTiers) {
            if (TreatmentTiers.hasOwnProperty(p) && (TreatmentTiers[p] === value)) return <b>{p}</b>
        }
    }


    renderEditor(t) {

        const popoverLocale = (
            <Popover id="localeHelp" className="helpPopover" >
                <Popover.Content>
                    Select a locale to change which locale you're editing for.  Text fields will be shown for that locale, if they exist.  When saving, the strings you've
                    entered will be saved for your currently selected locale.
                </Popover.Content>
            </Popover>
        )

        const popoverDuration = (
            <Popover id="durationHelp" className="helpPopover" >
                <Popover.Content>
                    Click 'Calculate' to auto-fill the duration by calculating it from the loaded program data.
                </Popover.Content>
            </Popover>
        )

        return (
            <table cellPadding={8} style={{"marginTop":"24px", "tableLayout":"fixed"}} className="col-12"><tbody>
                <tr><th style={{"width":"150px"}} /><th /></tr>

                { this.renderFlagged(t) }

                <tr>
                    <td className="inputLabel">name</td>
                    <td><table width="100%"><tbody><tr>
                        <td className="userInput">
                            <input type="text" className="form-control" placeholder="Enter a name for this treatment."
                                   style={{ width:"400px",display:"inline-block" }} value={ t.name } onChange={ e=>this.updateItem('name', e.target.value) } />
                            <span className="inputLabel" style={{display:"inline-block", marginLeft:"40px"}}>editing for locale</span>
                            <LocaleSelector id="localeSelector" />
                            <OverlayTrigger trigger="click" placement="bottom" overlay={popoverLocale}>
                                <i className="mdi mdi-help glyphIcon" style={{display:"inline-block"}}/>
                            </OverlayTrigger>
                        </td>
                    </tr></tbody></table>
                    </td>
                </tr>

                <tr>
                    <td className="inputLabel">devices</td>
                    <td>
                        <input type="checkbox" checked={t.supported_devices.includes("ox")} onChange={() => this.toggleSupportedDevice("ox")} /> OX &nbsp;&nbsp;&nbsp;
                        <input type="checkbox" checked={t.supported_devices.includes("gx")} onChange={() => this.toggleSupportedDevice("gx")} /> GX &nbsp;&nbsp;&nbsp;
                        <input type="checkbox" checked={t.supported_devices.includes("halo")} onChange={() => this.toggleSupportedDevice("halo")} /> Halo
                    </td>
                </tr>

                <tr>
                    <td className="inputLabel">filters</td>
                    <td><table className="filterListTable"><tbody><tr>
                        { this.renderFilters(t) }
                    </tr></tbody></table></td>
                </tr>

                <tr>
                    <td className="inputLabel">tier</td>
                    <td>
                        { this.renderTierSelector(t) }
                    </td>
                </tr>

                <tr>
                    <td className="inputLabel">caption</td>
                    <td><textarea className="form-control form-control-sm" rows="3" style={{ width: "700px" }} value={ t.caption }
                                  onChange={ e=>this.updateItem('caption', e.target.value)} />
                    </td>
                </tr>

                <tr>
                    <td className="inputLabel">images</td>
                    <td><table><tbody><tr>
                        <td><img width="300" height="180" src={ t.image_url } alt="Treatment" className="editorImage" /><br />
                            <span className="inputLabel">treatment</span>
                            <input type="file" onChange={ this.updateImage } /></td>

                        <td><img width="300" height="180" src={ t.thumbnail_image_url } alt="Thumbnail" className="editorImage" /><br />
                            <span className="inputLabel">thumbnail</span>
                            <input type="file" onChange={ this.updateThumbnailImage } /></td>

                        <td><img width="300" height="180" src={ t.landing_image_url } alt="Landing" className="editorImage" /><br />
                            <span className="inputLabel">landing</span>
                            <input type="file" onChange={ this.updateLandingImage } /></td>

                        </tr></tbody></table>
                    </td>
                </tr>

                <tr>
                    <td className="inputLabel"><b>program</b></td>
                    <td>
                        <ProgramPicker ref={ editor => { this.programEditor = editor } }
                                       program={ t.program_data } host={ this } />
                    </td>
                </tr>

                <tr>
                    <td className="inputLabel">duration</td>
                    <td><Row>
                        <Col className="input-group col-5" style={{ margin: 0, padding: 0 }}>
                            <input type="text" className="form-control form-control-sm" value={ t.duration }
                                   onChange={ e=>this.updateItem('duration', e.target.value)} />
                               <div className="input-group-append">
                                   <Button variant="secondary" type="button" size="sm" onClick={ this.calculateTreatmentLength }>Calculate</Button>

                                   <OverlayTrigger trigger="click" placement="bottom" overlay={popoverDuration}>
                                       <i className="mdi mdi-help glyphIcon" />
                                   </OverlayTrigger>
                               </div>
                        </Col>
                        <Col className="col-2 inputLabel">
                            intensity
                        </Col>
                        <Col className="col-4">
                            <DropdownButton variant="warning" size="sm" id="dropdown-intensity" title={ this.renderIntensity(t.intensity) }>
                                <Dropdown.Item onSelect={ ()=>this.updateItem('intensity', 1) }>{ this.renderIntensity(1) }</Dropdown.Item>
                                <Dropdown.Item onSelect={ ()=>this.updateItem('intensity', 2) }>{ this.renderIntensity(2) }</Dropdown.Item>
                                <Dropdown.Item onSelect={ ()=>this.updateItem('intensity', 3) }>{ this.renderIntensity(3) }</Dropdown.Item>
                            </DropdownButton>
                        </Col>
                    </Row></td>
                </tr>

                <tr>
                    <td className="inputLabel">instruction video</td>
                    <td>
                        <VideoPicker videos={this.state.videos} selected={t.videos && t.videos.instructions && t.videos.instructions.video_id}
                                     onChange={(v)=>{this.updateVideo('instructions', v)}} />
                    </td>
                </tr>

                <tr>
                    <td className="inputLabel">share URL</td>
                    <td><input type="text" spellCheck="false" className="form-control form-control-sm" style={{ width: "500px" }} value={ t.share_url }
                               onChange={ e=>this.updateItem('share_url', e.target.value)}
                    /></td>
                </tr>

                <tr>
                    <td className="inputLabel">YouTube URL</td>
                    <td><input type="text" spellCheck="false" className="form-control form-control-sm" style={{ width: "500px" }} value={ t.video_url }
                               onChange={ e=>this.updateItem('video_url', e.target.value)}
                    /></td>
                </tr>


                <tr>
                    <td className="inputLabel"><b>recommendations</b></td>
                    <td><table width="100%"><tbody>
                        { this.renderRecommendations(t.recommendations) }
                        <tr><td>
                            { this.renderRecommendationsAdd() }
                        </td></tr>
                    </tbody></table></td>
                </tr>

                <tr>
                    <td className="inputLabel"><b>instructions</b></td>
                    <td>
                        <table><tbody>
                        <tr><td className="inputLabel">caption</td>
                            <td>
                                <textarea className="form-control form-control-sm" rows="3" style={{ width: "600px" }}
                                          value={ t.instructions ? t.instructions.caption : ""}
                                          onChange={e => this.updateInstructions('caption', e.target.value)} />
                            </td></tr>
                        <tr><td className="inputLabel">YouTube URL</td>
                            <td>
                                <input type="text" spellCheck="false" className="form-control form-control-sm" style={{ width: "500px" }}
                                       value={ t.instructions ? t.instructions.video_url : ""}
                                       onChange={e => this.updateInstructions('video_url', e.target.value)}
                                />
                            </td></tr>
                        <tr><td><span className="inputLabel">skip button</span></td><td>
                            <table width="100%">
                                <tbody><tr>
                                    <td><input type="text" className="form-control form-control-sm"
                                               style={{width: "200px"}} value={ t.instructions ? t.instructions.skip_label : "" }
                                               onChange={e => this.updateInstructions('skip_label', e.target.value)}
                                    /></td>
                                    <td style={{paddingLeft: "15px"}}><span className="inputLabel">intro length (sec)</span></td>
                                    <td><input type="text" className="form-control form-control-sm"
                                               style={{width: "150px"}} value={ t.instructions ? t.instructions.intro_length : "" }
                                               onChange={e => this.updateInstructions('intro_length', e.target.value)}
                                    /></td>
                                </tr></tbody>
                            </table>
                        </td></tr>
                        </tbody></table>
                    </td>
                </tr>

                <tr>
                    <td className="inputLabel"><b>overview</b></td>
                    <td>
                        <table>
                            <tbody>
                            <tr>
                                <td className="inputLabel">caption</td>
                                <td>
                                    <textarea className="form-control form-control-sm" rows="3" style={{ width: "600px" }}
                                              value={ t.overview ? t.overview.caption : ""}
                                              onChange={e => this.updateOverview('caption', e.target.value)} />
                                </td>
                            </tr>
                            <tr>
                                <td className="inputLabel">YouTube URL</td>
                                <td>
                                    <input type="text" spellCheck="false" className="form-control form-control-sm" style={{width: "500px"}}
                                           value={t.overview ? t.overview.video_url : ""}
                                           onChange={e => this.updateOverview('video_url', e.target.value)}
                                    />
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>

                <tr><td /><td><hr/></td></tr>
                { this.renderButtons() }
                </tbody>
            </table>
        )
    }
}
