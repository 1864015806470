import React, { Component } from 'react'
import loadingGif from '../images/loading_spinner.gif'
import '../css/App.css'
import { Alert, Modal, Button } from 'react-bootstrap'
import asmCrypto from "asmcrypto-lite";
import {numID} from '../common'

export default class SplitEditor extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            isNew: false,
            validationErrors: [],
            isBlank: true,
            alertType: "",
            item: this.getBlankItem(),
            originalItem: this.getBlankItem()
        }
        this.alertTimer = null

        this.loadItem = this.loadItem.bind(this)
        this.setUpForNewItem = this.setUpForNewItem.bind(this)
        this.parseLoadedItem = this.parseLoadedItem.bind(this)
        this.renderAlert = this.renderAlert.bind(this)
        this.hasUnsavedChanges = this.hasUnsavedChanges.bind(this)
        this.updateItem = this.updateItem.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
        this.handleCancel = this.handleCancel.bind(this)
        this.handleSave = this.handleSave.bind(this)
        this.nameAndIdForGroup = this.nameAndIdForGroup.bind(this)
        this.nameAndIdForTreatment = this.nameAndIdForTreatment.bind(this)
        this.saveSucceeded = this.saveSucceeded.bind(this)
        this.saveFailed = this.saveFailed.bind(this)
        this.showAlert = this.showAlert.bind(this)
        this.clearAlert = this.clearAlert.bind(this)
        this.showConfirm = this.showConfirm.bind(this)
        this.reloadCurrentItem = this.reloadCurrentItem.bind(this)
        this.reloadListPane = this.reloadListPane.bind(this)
        this.renderButtons = this.renderButtons.bind(this)
        this.uploadImage = this.uploadImage.bind(this)
        this.getImageType = this.getImageType.bind(this)
        this.mimeTypeForExtension = this.mimeTypeForExtension.bind(this)
        this.itemNotOKToDelete = this.itemNotOKToDelete.bind(this)
        this.itemIsFlagged = this.itemIsFlagged.bind(this)
        this.renderFlagged = this.renderFlagged.bind(this)
    }

    componentDidMount() {
        if (!this.props.item) return
        if (this.state.isNew) {
            this.setState({ loading: false })
        } else {
            let id = this.props.match.params.itemId
            this.loadItem(id)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.selectCount < this.props.selectCount) {
            if (this.props.item === "new") {
                this.setUpForNewItem()
            } else {
                this.loadItem(this.props.item)
            }
        }
    }

    loadItem(id) {
        this.setState({ loading: true, isBlank: false })
        this.getItemFromStore(id).then((response) => {
            let item = this.parseLoadedItem(response.body)
            let originalItem = JSON.parse(JSON.stringify(item))
            this.setState({
                item: item,
                originalItem: originalItem,
                loading: false,
                isNew: false,
                validationErrors: [],
                isBlank: false,
                alertType: "",
                alertText: ""
            })
            this.onLoadItem(originalItem)
        }).catch(error => {
            alert('Error fetching data: ' + error)
        })
    }

    parseLoadedItem(item) { return item }

    reloadCurrentItem() {
        if (!this.state.isNew) {
            this.loadItem(this.getIDFromItem(this.state.item))
        }
    }

    setUpForNewItem() {
        const blank = this.getBlankItem()
        const blankOriginal = this.getBlankItem()
        this.setState({
            item: blank,
            originalItem: blankOriginal,
            loading: false,
            isNew: true,
            isBlank: false,
            validationErrors: [],
            alertType: "",
            alertText: "",
            newAlias: ''
        })
        this.onLoadItem(blank)
    }

    hasUnsavedChanges() {
        return !this.itemsAreEqual(this.state.item, this.state.originalItem) && this.noErrors(this.state.validationErrors)
    }

    updateItem(prop, value) {
        let item = this.state.item
        item[prop] = value
        this.setState({ item: item, alertType: "", alertText: "" })
    }

    resetError(prop, index) {
        let errorItems = this.state.validationErrors
        errorItems = errorItems.filter(item => item !== prop+index)
        this.setState({ validationErrors: errorItems })
    }

    updateError(prop, index, error) {
        let errorItems = this.state.validationErrors
        errorItems.push(prop+index)
        this.setState({ validationErrors: errorItems })
    }

    reloadListPane() {
        this.props.onEditorChange()
    }

    handleDelete(e) {
        this.showConfirm("After deletion, item cannot be restored. Are you sure you want to delete '" + this.getNameFromItem(this.state.item) + "'?", "Cancel", "Delete", (actually) => {
            this.actuallyDelete(actually)
        })
    }

    itemNotOKToDelete(item) {
        return false
    }

    actuallyDelete(actually) {
        this.setState({ confirmText: "", confirmYes: "" })
        if (actually) {
            this.deleteItemFromStore(this.getIDFromItem(this.state.item)).then(response => {
                this.setState({ isBlank: true })
                this.showAlert("danger", this.getNameFromItem(this.state.item) + " deleted.")
                this.reloadListPane()
            }).catch((e) => {
                this.showAlert("warning", e.response.text)
            })
        }
    }

    handleCancel(e) {
        this.showConfirm("Are you sure you want to discard your saved changes?", "No", "Yes", (actually) => {
            this.actuallyCancel(actually)
        })
    }

    actuallyCancel(actually) {
        this.setState({ confirmText: "", confirmYes: ""})
        if (actually) {
            let reverted = JSON.parse(JSON.stringify(this.state.originalItem))
            this.setState({
                alertType: "",
                alertText: "",
                item: reverted
            })
            this.onLoadItem(reverted)
            this.showAlert("success", "Temporary changes reverted.")
        }
    }

    handleSave(e) {
        let item = this.state.item
        let error = this.itemNotOKToSave(item)
        if (error) {
            this.showAlert("warning", error)
            return
        }
        this.showAlert("warning", "Saving...")
        if (this.state.isNew) {
            this.createNewItemInStore(item)
        } else {
            this.updateItemInStore(item)
        }
    }

    saveSucceeded(message) {
        this.reloadCurrentItem()
        this.reloadListPane()
    }

    saveFailed(message) {
        this.showAlert("warning", message)
    }

    showAlert(type, text) {
        this.setState({ alertType: type, alertText: text })
        if (this.alertTimer) clearTimeout(this.alertTimer)
        if (type !== 'danger') {
            this.alertTimer = setTimeout(() => { this.clearAlert() }, global.config.alertTimeout)
        }
    }

    clearAlert() {
        this.setState({ alertType: "", alertText: "" })
    }

    showConfirm(text, noText, yesText, callback) {
        this.setState({ confirmText: text, confirmNo: noText, confirmYes: yesText, confirmCallback: callback })
    }

    getImageType(filename) {
        const matches = filename.match(/(\.png|\.jpg|\.jpeg|\.gif)$/i)
        if (matches && matches.length > 0) {
            return matches[0]
        } else {
            return null
        }
    }

    getSubtitleType(filename) {
        const matches = filename.match(/(\.srt)$/i)
        if (matches && matches.length > 0) {
            return matches[0]
        } else {
            return null
        }
    }

    mimeTypeForExtension(extension) {
        switch (extension.toLowerCase()) {
            case ".jpg":
            case ".jpeg":
                return "image/jpeg"
            case ".png":
                return "image/png"
            case ".gif":
                return "image/gif"
            case ".srt":
                return "text/plain"
            default:
                return "application/octet-stream"
        }
    }

    nameAndIdForGroup(group) {
        return `${group.name} (${ numID(group.group_id) })`
    }

    nameAndIdForPlanGroup(planGroup) {
        return `${planGroup.name} (${ numID(planGroup.plan_group_id) })`
    }

    nameAndIdForTreatment(treatment) {
        return `${treatment.name} (${ numID(treatment.treatment_id) })`
    }

    nameAndIdForPlan(plan) {
        return `${plan.name} (${ numID(plan.treatplan_id) })`
    }

    encodeStringId(text, id, field) {
        const prefix = global.config.stringIdPrefix
        return `${prefix}${id}_${field}${prefix}${text}`
    }

    uploadImage(event, field) {
        const file = event.target.files[0]
        const extension = this.getImageType(file.name)
        if (!extension) {
            this.showAlert("danger", "That does not appear to be an image file.  Please try again.")
            return
        }
        const contentType = this.mimeTypeForExtension(extension)
        const self = this  // save a reference to this class that we can use in our callbacks
        const reader = new FileReader()
        reader.onload = function (event) {
            const contents = event.target.result
            const digest = asmCrypto.SHA256.hex(contents)
            const key = digest + extension
            global.api.validateImageUpload(key).then(data => {
                const formData = data.body
                formData.formInputs['Content-Type'] = contentType
                return global.api.uploadImage(file, formData)
            }).then(result => {
                self.updateItem(field, global.config.imageCdnUrl + key)
                self.showAlert("success", "Image uploaded.")
            }).catch(error => {
                self.showAlert("danger", 'Error uploading image: ' + error)
            })
        }
        let thisEditor = this
        reader.onerror = function (event) {
            thisEditor.showAlert("danger", "File could not be read! Code " + event.target.error.code)
        }
        reader.readAsArrayBuffer(file)
    }

    itemIsFlagged(item) {
        return false
    }

    // Render functions

    renderFlagged(item) {
        if (this.state.isNew) return
        const flag = this.itemIsFlagged(item)
        if (flag) return (
            <tr>
                <td className="inputLabel"><i className="mdi mdi-warning glyphIcon warnIcon" /></td><td className="warnText">{flag}</td>
            </tr>
        )
    }

    renderAlert() {
        if (this.state.alertType) {
            return (
                <div className="row panesRow">
                    <Alert variant={ this.state.alertType } className="w-100 fixed-top"
                           onClose={ () => this.setState({ alertType: "", alertText: "" }) }
                           dismissible >
                        <Alert.Heading>{ this.state.alertText }</Alert.Heading>
                    </Alert></div>
            )
        }
    }

    renderConfirm() {
        if (this.state.confirmText) {
            return (
                <Modal show={true} onHide={ () => this.state.confirmCallback(false) }>
                    <Modal.Header>
                        <Modal.Title>Confirm { this.state.confirmYes }</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{ this.state.confirmText }</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={ () => this.state.confirmCallback(false) }>{ this.state.confirmNo }</Button>
                        <Button variant="primary" onClick={ () => this.state.confirmCallback(true) }>{ this.state.confirmYes }</Button>
                    </Modal.Footer>
                </Modal>
            )
        }
    }

    renderButtons() {
        const hasChanges = this.hasUnsavedChanges()
        return <tr className="buttonRow"><td /><td className="buttonColumn">
            { this.renderDeleteButton() }
            { this.renderCancelButton(hasChanges) }
            { this.renderSaveButton(hasChanges) }
            { this.renderChangesNotice(hasChanges) }
            <div style={{height: "100px"}} />
        </td></tr>
    }

    renderDeleteButton() {
        const notOk = this.itemNotOKToDelete(this.state.item)
        if (!this.state.isNew) {
            if (!notOk) {
                return <Button className="itemAction" variant="danger" onClick={this.handleDelete}>Delete</Button>
            } else {
                return <Button className="itemAction" variant="outline-danger" onClick={()=>{
                    this.showAlert('warning', "Can't delete item: " + notOk)}}>Delete</Button>
            }
        }
    }

    renderCancelButton(show) {
        if (show) {
            return <Button className="itemAction btn-warning" onClick={ this.handleCancel }>Cancel</Button>
        }
    }

    renderSaveButton(show) {
        if (show) {
            return <Button className="itemAction" onClick={ this.handleSave }>Save</Button>
        }
    }

    renderChangesNotice(show) {
        if (show) {
            return <div className="helpText">
                <i className="mdi mdi-report glyphIcon alertIcon" />You have unsaved changes.</div>
        }
    }

    render() {
        if (this.state.isBlank) {
            return (
                <div className="home">
                    { this.renderAlert() }
                    <p className="homeText">
                        { this.homeIcon() }
                        <br/>
                        { this.homeText() }
                    </p>
                    <p className="homeSubtext">{ this.homeSubtext() }</p>
                </div>
            )
        } else if (this.state.loading) {
            return (
                <div className="col-12 d-flex justify-content-center" >
                    <img src={loadingGif} alt="loading..." style={{"marginTop":"192px","height":"80px","width":"80px"}}/>
                </div>
            )
        } else {
            return (
                <div className="col-12">
                    { this.renderAlert() }
                    { this.renderEditor(this.state.item) }
                    { this.renderConfirm() }
                </div>
            )
        }
    }
}
