import React, { Component } from 'react'
import { Button } from 'react-bootstrap'
import loadingGif from "../images/loading_spinner.gif";

export default class SplitList extends Component {

    constructor(props) {
        super(props)
        this.state = { items: [], selected: "", loading: false }

        this.fetchItems = this.fetchItems.bind(this)
        this.onItemClick = this.onItemClick.bind(this)
        this.renderItems = this.renderItems.bind(this)
        this.renderAddButton = this.renderAddButton.bind(this)
        this.classForItem = this.classForItem.bind(this)
        this.handleNewItem = this.handleNewItem.bind(this)
        this.renderFlag = this.renderFlag.bind(this)
        this.itemIsFlagged = this.itemIsFlagged.bind(this)
    }

    componentDidMount() {
        this.fetchItems()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.updateCount < this.props.updateCount) {
            this.fetchItems()
        }
    }

    fetchItems() {
        const getter = this.getItems()
        if (getter === null) return
        this.setState({ loading: true })
        getter.then((response) => {
            this.setState({ items: response.body, loading: false })
        }).catch((error) => {
            console.error(error)
            window.alert("Error fetching items: " + error)
            this.setState({ loading: false })
        })
    }

    onItemClick(id, e) {
        e.preventDefault()
        this.props.onItemClick(id)
        this.setState({ selected: id })
    }

    classForItem(id) {
        if (id === this.state.selected) {
            return "selected"
        }
    }

    handleNewItem(e) {
        e.preventDefault()
        this.props.onItemClick("new")
        this.setState({ selected: "" })
    }

    itemIsFlagged(item) {
        return false
    }

    renderHeader() { }

    renderFlag(item) {
        if (this.itemIsFlagged(item)) return <i className="mdi mdi-warning glyphIcon warnIcon" />
    }

    renderItems() {
        return this.state.items
            .sort((a,b) => { return (this.itemSort(a) < this.itemSort(b)) ? -1 : (this.itemSort(a) > this.itemSort(b) ? 1 : 0 )})
            .map((item) => {
                const itemId = this.itemId(item)
                return (
                    <tr className={ this.classForItem(itemId) } key={ itemId }
                        style={{ cursor: 'pointer' }}
                        onClick={ (e) => this.onItemClick(itemId, e) }>
                        { this.renderItem(item) }
                    </tr>
                )
            })
    }

    renderAddButton() {
        const label = this.labelAddButton()
        if (label) {
            return (
                <Button onClick={ this.handleNewItem } className="btn-sm btn-success addButton">
                    <i className="mdi mdi-add-box"/> { label }
                </Button>
            )
        }
    }

    render() {
        if (this.state.loading) {
            return (
                <div className="col-12 d-flex justify-content-center" >
                    <img src={loadingGif} alt="loading..." style={{"marginTop":"96px","height":"80px","width":"80px"}}/>
                </div>
            )
        } else {
            return (
                <div>
                    {this.renderAddButton()}
                    <table cellPadding={4} width="100%" style={{"tableLayout": "fixed"}}>
                        <tbody>
                        {this.renderHeader()}
                        {this.renderItems()}
                        <tr style={{"height": "90px"}}/>
                        </tbody>
                    </table>
                </div>
            )
        }
    }
}
