import React from 'react'
import SplitPane from '../generic/SplitPane'
import LocaleList from './LocaleList'
import LocaleEditor from './LocaleEditor'

export default class LocalePane extends SplitPane {

    constructor(props) {
        super(props)
        this.renderList = this.renderList.bind(this)
        this.renderEdit = this.renderEdit.bind(this)
    }


    // SplitPane functions

    renderList() {
        return <LocaleList onItemClick={ this.onItemClick } updateCount={ this.state.updateCount }/>
    }

    renderEdit() {
        return <LocaleEditor onEditorChange={ this.onEditorChange } item={ this.state.editing } selectCount={ this.state.selectCount } />
    }
}
