import React, { Component } from 'react'
import { Dropdown, DropdownButton } from 'react-bootstrap'

export default class LocaleSelector extends Component {

    constructor(props) {
        super(props)
        this.state = {
            locales: []
        }
    }

    componentDidMount() {
        global.api.fetchLocales()
            .then( results => {
                this.setState({ locales: this.sortLocales(results.body) })
            })
            .catch((error) => console.error(error))
    }

    sortLocales(locales) {
        return locales.sort((a,b) => {
            return (a.locale < b.locale) ? -1 : (a.locale > b.locale) ? 1 : 0
        })
    }

    localeSelected(locale) {
        global.localeManager.setLocale(locale)
        this.setState(this.state)
    }

    renderLocales() {
        return this.state.locales.map( (locale, index) =>
            <Dropdown.Item key={index} onSelect={ () => this.localeSelected(locale) }>
                <span className="localeSelectorMenuID">{ locale.locale }</span>
                <span className="localeSelectorMenuName ">{ locale.name }</span>
            </Dropdown.Item> )
    }

    render() {
        let locale = global.localeManager.currentLocaleId()
        return (
            <DropdownButton id="locale-selector"
                            style={{display:"inline-block"}}
                            variant={ locale === global.config.defaultLocale ? "primary" : "danger" }
                            title={ locale + " " }>
                { this.renderLocales() }
            </DropdownButton>
        )
    }
}
