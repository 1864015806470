import React from 'react'
import '../css/App.css'
import SplitEditor from '../generic/SplitEditor'
import SortableTreatmentList from "../SortableTreatmentList";
import Popover from 'react-bootstrap/Popover'
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import {groupIsFlagged} from './GroupIsFlagged'
import { numID } from '../common'

export default class GroupEditor extends SplitEditor {

    constructor(props) {
        super(props)
        this.treatmentList = null
        this.setState({ treatments: [] })

        this.onTreatmentsChanged = this.onTreatmentsChanged.bind(this)
        this.getTreatmentsForList = this.getTreatmentsForList.bind(this)
        this.treatmentForId = this.treatmentForId.bind(this)
        this.treatmentSelected = this.treatmentSelected.bind(this)
    }

    componentDidMount() {
        super.componentDidMount()
        this.setState({ loading: true })
        global.api.fetchTreatmentsDefaultLocale().then((response) => {
            const treatments = response.body
            this.setState({ treatments: treatments, loading: false })
        }).catch((e) => {
            this.showAlert("danger", "FATAL: error fetching treatments: " + e)
        })
    }

    // SplitEditor functions

    getBlankItem() {
        return JSON.parse(JSON.stringify(global.Templates.group))
    }

    getItemFromStore(id) {
        return global.api.fetchGroup(id)
    }

    itemNotOKToDelete(group) {
        if (group.group_id === global.config.defaultCollection) return "This is the default collection."
        return false
    }

    deleteItemFromStore(id) {
        return global.api.deleteGroupId(id)
    }

    getNameFromItem(item) {
        return item.name
    }

    getIDFromItem(item) {
        return item.group_id
    }

    onLoadItem(item) {
        let treatments = item.treatments.map((ti, i) => this.treatmentForId(ti)) || []
        this.treatmentList && this.treatmentList.setItems(treatments)
    }

    homeIcon() {
        return <i className="mdi mdi-format-list-bulleted homeIcon" />
    }

    homeText() {
        return "Pick a collection to edit, or Add Collection to create a new one."
    }

    homeSubtext() {
        return "Collections control how users receive treatments.  Add treatments to a collection, then add that collection to users to give those users all the treatments in the collection."
    }

    itemsAreEqual(a, b) {
        if (!a && !b) return true
        if (!a || !b) return false
        if (a.group_id === b.group_id) {
            if (a.name === b.name) {
                if (JSON.stringify(a.treatments) === JSON.stringify(b.treatments)) {
                    return true
                }
            }
        }
        return false
    }

    noErrors(errorList) {
        return true
    }

    itemNotOKToSave(group) {
        return false
    }

    createNewItemInStore(group) {
        global.api.addGroup(group).then(response => {
            this.saveSucceeded("New collection created.")
            this.loadItem(response.body.group_id)
            this.reloadListPane()
        }).catch(e => {
            this.saveFailed("Error creating collection: " + e.response.text)
        })
    }

    updateItemInStore(group) {
        global.api.updateGroup(group).then(response => {
            this.saveSucceeded("Changes saved.")
        }).catch(e => {
            this.saveFailed("Error saving collection: " + e.response.text)
        })
    }


    // Internal functions

    treatmentForId(id) {
        let treatments = this.state.treatments
        for (let x=0; x<treatments.length; x++) {
            if (treatments[x].treatment_id === id) {
                return treatments[x]
            }
        }
        return null
    }

    getTreatmentsForList(group) {
        const treatments = group.treatments || []
        return treatments.map((tid, i) => {
            let t = this.state.treatments.find((x) => x.treatment_id === tid)
            if (t) return t
            else {
                return {treatment_id: tid, name: "[ deleted treatment -- remove me ]"}
            }
        })
    }

    onTreatmentsChanged(treatments) {
        this.clearAlert()
        this.updateItem('treatments', treatments.map((t) => t.treatment_id ))
    }

    treatmentSelected(e) {
        this.clearAlert()
        let treatment = this.state.treatments.find((t) => this.nameAndIdForTreatment(t) === e.target.value)
        if (treatment) {
            if (!this.state.item.treatments || !this.state.item.treatments.includes(treatment.treatment_id)) {
                this.treatmentList.addItem(treatment)
            } else {
                this.showAlert("warning", "That treatment is already in the collection.")
            }
        }
    }

    itemIsFlagged(group) {
        return groupIsFlagged(group)
    }

    // Render functions

    renderEditor(group) {

        const popoverTreatments = (
            <Popover id="treatmentHelp" className="helpPopover" >
                <Popover.Content>
                    Drag treatments within the collection to change their order.  Pick a treatment from the dropdown to add it to the end of the collection.
                    Drag a treatment to the trash to remove it from the collection.
                </Popover.Content>
            </Popover>
        )

        return (
            <table cellPadding={8} style={{"marginTop":"24px", "tableLayout":"fixed"}} className="col-12"><tbody>
                <tr><th style={{"width":"150px"}} /><th /></tr>

                { this.renderFlagged(group) }

                <tr>
                    <td className="inputLabel">name</td>
                    <td>
                        <input type="text" autoFocus style={{ width: "400px" }} placeholder='A descriptive name.'
                               className="userInput form-control form-control-sm"
                               value={group.name} onChange={ (e)=>this.updateItem('name', e.target.value) } />
                    </td>
                </tr>

                <tr>
                    <td className="inputLabel">treatments</td>
                    <td>
                        <SortableTreatmentList onChange={ (treatments)=>this.onTreatmentsChanged(treatments) }
                                               items={ this.getTreatmentsForList(group) } ref={ r => { this.treatmentList = r } } />
                        <select className="treatmentSelector" onChange={ this.treatmentSelected } value={ this.chooseTreatmentText }>
                            <option key={0}>Pick a treatment to add it to the collection.</option>
                            { this.state.treatments
                                .sort( (a, b) => parseInt(numID(a.treatment_id)) > parseInt(numID(b.treatment_id)) ? 1 : -1 )
                                .map( (treatment, index) => <option key={index+1}>{ this.nameAndIdForTreatment(treatment) }</option> ) }
                        </select>
                    </td>
                    <td className="trashCell">
                        <OverlayTrigger trigger="click" placement="bottom" overlay={popoverTreatments}>
                            <i className="mdi mdi-help glyphIcon" />
                        </OverlayTrigger>
                        <br />
                        <i className="mdi mdi-delete-forever trashIcon" />
                    </td>
                </tr>

                <tr><td /><td><hr/></td></tr>
                { this.renderButtons() }
                </tbody>
            </table>
        )
    }
}
